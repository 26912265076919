import { computed } from "vue"
import { useCurrentUser } from "./user_data"
import { useI18n } from 'vue-i18n'
import { allowPirate } from "@/config"


export const useSelfNavigation = function () {
  const { authenticated, currentUser } = useCurrentUser()
  const { t } = useI18n()

  const navs = computed(() => {
    const items = []

    if (!authenticated.value) {
      return []
    }

    items.push({
      title: t('mypage'),
      path: '/i/profile',
    })

    items.push({
      title: t('updateProfile'),
      path: '/i/edit',
    })

    items.push({
      title: t('orders'),
      path: '/i/orders',
    })

    items.push({
      title: t('downloads'),
      path: '/i/downloads',
    })

    items.push({
      title: t('notifications'),
      path: '/i/notifications',
    })

    items.push({
      title: t('settings'),
      path: '/i/settings',
    })

    if (currentUser.value.authed || allowPirate) {
      items.push({
        title: t('mywallet'),
        path: '/i/wallet',
      }) 
    }

    items.push({
      title: t('creator'),
      path: '/i/publisher',
    })

    return items
  })


  return {
    navs,
  }
}