<template>
  <div
    v-if="issue"
    class="issue-page-container">
    <issue-cell :issue="issue"></issue-cell>

    <div class="records">
      <div
        v-for="(record, $index) in displayRecords"
        :key="record._id"
        class="record">
        <div
          v-if="record.statusChanged"
          class="record-status">
          <span>{{ record.content }} {{ record.createdAt }}</span>
        </div>
        <div
          v-else-if="record.content || record.artwork"
          class="record-comment">
          <user-avatar
            :user="record.user"
            class="avatar"
            @click="$router.push(`/users/${record.user._id}`)">
          </user-avatar>
          <div class="record-comment-content">
            <p v-if="record.content">
              {{ record.content }}
            </p>
            <router-link
              v-if="record.artwork"
              class="outline"
              :to="record.artwork.type === 'comics' ? `/comics/${record.artwork._id}` : `/posts/${record.artwork._id}`">
              {{ record.artwork.title }}
            </router-link>
            <div class="time">{{ record.createdAt }}</div>
          </div>
          <absolute-cover v-if="!involved">
            <p>You are not involved in this commission</p>
          </absolute-cover>
        </div>
        <div
          v-if="displayRecords[$index + 1]"
          class="vertical-bar">
          &nbsp;
        </div>
      </div>
    </div>

    <div
      v-if="currentUserId === issue.assigner._id"
      class="actions">
      <pr-button
        v-if="issue.status === 'reviewing'"
        v-show="resolvePriceText"
        type="primary"
        :loading="actionLoading"
        @click="intentToResolve">
        Accept and Pay {{ resolvePriceText }}
      </pr-button>
      <pr-button
        v-if="issue.status === 'reviewing'"
        :loading="actionLoading"
        :disabled="issue.assignerRejectedCount >= maxRejectionTimes"
        @click="changeStatus('assigner-rejected')">
        <span v-if="issue.assignerRejectedCount < maxRejectionTimes">
          Reject (You can reject {{ maxRejectionTimes - issue.assignerRejectedCount }} times)
        </span>
        <span v-else>
          You can reject at most {{ maxRejectionTimes }} times
        </span>
      </pr-button>
      <pr-button
        v-if="issue.status === 'waiting-for-payment'"
        type="primary"
        @click="$router.push('/i/orders')">
        Pay
      </pr-button>
      <pr-button
        v-if="issue.status === 'waiting-for-resolve'"
        v-show="resolvePriceText"
        type="primary"
        @click="$router.push('/i/orders')">
        Pay {{ resolvePriceText }}
      </pr-button>
    </div>
    <div
      v-else-if="currentUserId === issue.assignee._id"
      class="actions">
      <pr-button
        v-if="issue.status === 'open'"
        type="primary"
        :loading="actionLoading"
        @click="changeStatus('working')">
        {{ $t('acceptCommission') }}
      </pr-button>
      <pr-button
        v-if="issue.status === 'open'"
        :loading="actionLoading"
        @click="changeStatus('assignee-rejected')">
        {{ $t('rejectCommission') }}
      </pr-button>
      <pr-button
        v-if="issue.status === 'assigner-rejected'"
        type="primary"
        :loading="actionLoading"
        @click="changeStatus('working')">
        {{ $t('continueCommission') }}
      </pr-button>
      <pr-button
        v-if="issue.status === 'assigner-rejected'"
        :loading="actionLoading"
        @click="changeStatus('closed')">
        {{ $t('giveUpCommission') }}
      </pr-button>
      <pr-button
        v-if="issue.status === 'closed'"
        :loading="actionLoading"
        @click="update({ publicIssue: true })">
        {{ $t('makePublicSale') }}
      </pr-button>
    </div>

    <div v-if="issue.status === 'working' && currentUserId === issue.assignee._id">
      <pr-cell
        :label="$t('deliverCommission').toString()">
        <div style="display: flex; gap: 0.5rem; align-items: center;">
          {{ selectedArtwork ? selectedArtwork.title : '' }}
          <pr-button
            v-show="artworkId"
            @click="artworkId = null">
            {{ $t('delete') }}
          </pr-button>
          <pr-button
            @click="showArtworkSelector = true">
            {{ $t('pleaseChoose') }}
          </pr-button>
        </div>
      </pr-cell>

      <pr-button
        v-if="issue.status === 'working'"
        style="margin-top: 1rem; width: 100%;"
        :loading="actionLoading"
        @click="changeStatus('reviewing')">
        {{ $t('deliverCommission') }}
      </pr-button>
    </div>

    <div
      v-if="(issue.status !== 'closed' || issue.status !== 'resolved') && involved"
      class="comment-sender">
      <Textarea
        v-model="comment"
        :placeholder="$t('issueCommentPlaceholder', { nickname: currentUserId === issue.assignee._id ? issue.assigner.nickname : issue.assignee.nickname })">
      </Textarea>

      <pr-button
        :loading="commenting"
        @click="sendComment">
        {{ $t('comment') }}
      </pr-button>
    </div>

    <artwork-picker
      v-model="showArtworkSelector"
      :max="1"
      @confirm="artworkIds => {
        artworkId = artworkIds[0]
        showArtworkSelector = false
      }">
    </artwork-picker>
  </div>
</template>


<script>
//@ts-check
import { useIssueAction } from '@/composables/issue_action'
import { useIssuePresent } from '@/composables/issue_present'
import IssueCell from '@/components/IssueCell.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { useCurrentUser } from '@/composables/user_data'
import PrButton from '@/components/PRButton.vue'
import Textarea from 'primevue/textarea'
import ArtworkPicker from '@/components/ArtworkPicker.vue'
import AbsoluteCover from '@/components/AbsoluteCover.vue'
import { maxRejectionTimes } from '@/config'
import PrCell from '@/components/PRCell.vue'


export default {
  setup () {
    const presentComposition = useIssuePresent()

    return {
      ...presentComposition,
      ...useIssueAction(presentComposition.issue, presentComposition.getIssue),
      ...useCurrentUser(),
      maxRejectionTimes,
    }
  },
  components: {
    IssueCell,
    UserAvatar,
    PrButton,
    Textarea,
    ArtworkPicker,
    AbsoluteCover,
    PrCell,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.issue-page-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50rem;
  margin-left: auto;
  margin-right: auto;

  @media @phone {
    width: 100%;
  }
}

.records {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.record {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
}

.record-status {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem;
  color: @minor;
  font-size: 0.9rem;
}

.record-comment {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid @border;
  border-radius: 0.5rem;
  margin: 1rem;
  margin-bottom: 0;
  position: relative;
}

.record-comment-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;

  .time {
    margin-left: auto;
  }

  a {
    margin: 0;
  }
}

.avatar {
  width: 3rem;
  height: 3rem;
}

.vertical-bar {
  width: 1px;
  background-color: @border;
  height: 100%;
  margin: 0;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.comment-sender {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  button {
    width: 100%;
  }
}

.time {
  font-size: 0.7rem;
}
</style>