<template>
  <div style="padding: 1rem;">
    <user-tron
      v-model="showTron"
      @completed="onBindCompleted"></user-tron>

    <user-paypal
      v-model="showPaypal"
      @completed="onBindCompleted"></user-paypal>

    <div
      v-if="!currentUser.payoutEnabled"
      class="container">
      <p>{{ $t('payoutBindTip') }}</p>

      <div>
        <vue-country-code
          :dropdown-options="{ disabledDialCode: true }"
          :preferred-countries="regionCodes.map(code => code.toUpperCase())"
          @onSelect="onSelect"></vue-country-code>
        <span style="margin-left: 1em;">{{ countryName }}</span>
      </div>

      <div>
        <div
          v-for="aPayoutMethod in availablePayoutMethods"
          :key="aPayoutMethod"
          class="payout-method"
          :class="{
            selected: aPayoutMethod === payoutMethod
          }"
          @click="payoutMethod = aPayoutMethod">
          <i
            v-if="aPayoutMethod === 'stripe'"
            class="pi pi-money-bill"></i>
          <i
            v-if="aPayoutMethod === 'paypal'"
            class="pi pi-paypal"></i>
          <i
            v-if="aPayoutMethod === 'tron'"
            class="pi pi-bitcoin"></i>
          <span v-if="aPayoutMethod === 'tron'">trc20 USDT</span>
          <span v-if="aPayoutMethod === 'paypal'">PayPal</span>
          <span v-if="aPayoutMethod === 'stripe'">{{ $t('bankTransfer') }}</span>
        </div>
      </div>

      <pr-button
        type="primary"
        @click="showRegionConfirm = true">
        {{ $t('bindPayout') }}
      </pr-button>
    </div>
    <div
      v-else
      class="container">
      <p>Payout Method Setting Completed</p>
    </div>

    <Dialog v-model:visible="showRegionConfirm">
      <template #header>
      </template>

      <div v-if="payoutMethod === 'tron'">
        <p>To be able to receive payouts in trc20 USDT, you need</p>
        <ul>
          <li>- Cryptocurrency trc20 address</li>
        </ul>
        <p>Once the payout method is set, you cannot reset</p>
      </div>
      <div v-else-if="payoutMethod === 'stripe'">
        <p>To be able to receive payouts in {{ countryName }}, you need</p>
        <ul>
          <li>- Government issued ID in {{ countryName }}</li>
          <li>- Bank account in {{ countryName }}</li>
        </ul>
        <p>Once the payout method is set, you cannot reset.</p>
      </div>
      <div v-else-if="payoutMethod === 'paypal'">
        <p>To be able to receive payouts through PayPal, you need</p>
        <ul>
          <li>- A valid PayPal account</li>
        </ul>
        <p>Once the payout method is set, you cannot reset.</p>
      </div>

      <template #footer>
        <pr-button
          type="primary"
          :loading="loading"
          @click="confirm">{{ $t('bindPayout') }}</pr-button>
      </template>
    </Dialog>
  </div>
</template>

<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import { computed, ref } from 'vue'
import { bindBankAccount, update } from '@/api/user'
import { useRouter } from 'vue-router'
import { regionCodes } from '@/config'
import UserTron from '@/components/UserTron.vue'
import { useCurrentUser } from '@/composables/user_data'
import Dialog from 'primevue/dialog'
import { useToast } from '@/composables/toast'
import UserPaypal from '@/components/UserPaypal.vue'
import { useAuthenticator } from '@/pinia/authenticator'
import { useHead } from '@unhead/vue'
import VueCountryCode from '@/components/VueCountryCode.vue'


export default {
  setup () {
    const router = useRouter()
    const { currentUser } = useCurrentUser()
    const countryCode = ref('JP')
    const countryName = ref('')
    const bankAccount = ref(currentUser.value?.bank?.account || '')
    const socialId = ref(currentUser.value?.bank?.cnId || '')
    const bankHolder = ref(currentUser.value?.bank?.holder || '')
    const showTron = ref(false)
    const showRegionConfirm = ref(false)
    const showPaypal = ref(false)
    const loading = ref(false)
    const { Toast } = useToast()
    const authenticator = useAuthenticator()

    const payoutMethod = ref('tron')

    useHead({
      title: 'Payout Method | Perohub',
    })

    const availablePayoutMethods = computed(() => {
      if (countryCode.value === 'CN') {
        return ['tron']
      } else {
        return ['tron', 'paypal']
      }
    })

    const onSelect = function ({ name, iso2 }) {
      countryCode.value = iso2
      countryName.value = name
    }

    const confirm = async function () {
      try {
        loading.value = true

        await update(currentUser.value._id, { info: { countryCode: countryCode.value, payoutMethod: payoutMethod.value } })

        if (payoutMethod.value === 'tron') {
          showRegionConfirm.value = false
          showTron.value = true
        } else if (payoutMethod.value === 'paypal') {
          showRegionConfirm.value = false
          showPaypal.value = true
        } else if (payoutMethod.value === 'stripe') {
          const url = await bindBankAccount(countryCode.value)
          showRegionConfirm.value = false
          location.href = url
        }
      } catch (err) {
        Toast(err.message)
      } finally {
        loading.value = false
      }
    }

    const onBindCompleted = async function () {
      try {
        await authenticator.refreshUser()
        router.push('/i/wallet/')
      } catch (err) {
        Toast(err.message)
      }
    }

    return {
      countryCode,
      countryName,
      onSelect,
      bankAccount,
      bankHolder,
      socialId,
      confirm,
      currentUser,
      regionCodes,
      showTron,
      onBindCompleted,
      showRegionConfirm,
      showPaypal,
      availablePayoutMethods,
      payoutMethod,
      loading,
    }
  },
  components: {
    PrButton,
    UserTron,
    Dialog,
    UserPaypal,
    VueCountryCode,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  > * {
    display: block;
    margin-bottom: 1em;
  }
}

.payout-method {
  border-radius: 0.5rem;
  border: 0.1rem solid @light-grey;
  height: 4rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 1rem;
  font-size: 1.2rem;

  span {
    width: 8rem;
    text-align: center;
  }

  i {
    font-size: 1.6rem;
  }
}

.payout-method:hover {
  cursor: pointer;
}

.payout-method.selected {
  border-color: @blue;
  border-width: 0.2rem;
}
</style>