<template>
  <div class="tag-container">
    <header>
      <breadcrumb
        v-if="!isOnMobile"
        :home="{
          label: $t('artworkCategories'),
          to: '/i/publisher/tags'
        }"
        :model="breadcrumbs"></breadcrumb>

      <pr-button
        style="margin-left: auto;"
        size="small"
        @click="showEditing = true">
        <i class="pi pi-file-edit"></i>
        {{ $t('edit') }}
      </pr-button>

      <pr-button
        size="small"
        :loading="tagRemoving"
        @click="removeTag">
        <i class="pi pi-trash"></i>
        {{ $t('delete') }}
      </pr-button>
    </header>

    <div
      ref="artworksRef"
      class="artworks">
      <div
        v-for="artwork in artworks.filter(artwork => artwork)"
        :key="artwork._id"
        class="artwork touchable hoverable">
        <post-simple-item :post="artwork">
          {{ artwork.title }}
        </post-simple-item>
        <div class="operation">
          <pr-button
            size="small"
            :loading="removing"
            @click="removeArtworkId(artwork._id)">
            {{ $t('delete') }}
          </pr-button>
        </div>
      </div>
    </div>

    <pr-button
      v-if="artworks.length > 0"
      type="primary"
      @click="showAdder = true">{{ $t('add') }}</pr-button>

    <p v-else-if="getting">
      {{ $t('loading') }}
    </p>
    <pr-empty
      v-else
      :button-text="$t('add')"
      @trigger="showAdder = true">
    </pr-empty>

    <artwork-picker
      v-model="showAdder"
      :loading="updating"
      @confirm="updateTag"></artwork-picker>
    
    <drawer
      v-model:visible="showEditing"
      position="bottom">
      <div class="form-container">
        <input-text
          v-model="title"
          type="text"></input-text>

        <pr-button
          type="primary"
          :loading="updating"
          @click="updateTitle">{{ $t('save') }}</pr-button>
      </div>
    </drawer>
  </div>
</template>


<script>
import { useSelfTagPresent, useSelfTagUpdate } from '@/composables/selftag'
import PostSimpleItem from '@/components/PostSimpleItem.vue'
import PrEmpty from '@/components/PREmpty.vue'
import ArtworkPicker from '@/components/ArtworkPicker.vue'
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import Breadcrumb from 'primevue/breadcrumb'
import InputText from 'primevue/inputtext'
import Drawer from 'primevue/drawer'


export default {
  setup () {
    const tagComposition = useSelfTagPresent()
    return {
      ...tagComposition,
      ...useSelfTagUpdate(tagComposition.tag, tagComposition.getTag),
      ...useResponsive(),
    }
  },
  components: {
    PostSimpleItem,
    PrEmpty,
    ArtworkPicker,
    PrButton,
    Breadcrumb,
    Drawer,
    InputText,
  }
}
</script>


<style scoped>
header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

header .p-breadcrumb {
  flex: 1;
  border: none;
}


.tag-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.artworks {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.artwork {
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.artwork .post-container {
  flex: 1;
}

.artwork .operation {
  margin-left: auto;
}

.tag-container > button {
  width: 100%;
}

.form-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>