import { api } from './base'


export const getExploredUsers = async (page, limit) => {
  try {
    const res = await api.get('/users/promoted/list', {
      params: {
        page,
        limit,
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getGirls = async (page, limit) => {
  try {
    const res = await api.get('/girls', {
      params: { page, limit }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getTopics = async (page, limit) => {
  try {
    const res = await api.get('/topics', {
      params: { page, limit }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getTopicPosts = async (topicId, page, limit) => {
  try {
    const res = await api.get(`/topics/${topicId}/posts`, {
      params: { page, limit }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getTopic = async (topicId) => {
  try {
    const res = await api.get(`/topics/${topicId}/single`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}
