import { computed, onMounted, ref, watch } from 'vue'
import { getUserPostsTill } from '@/api/post'
import { useArtworkStore } from '@/pinia/artwork'
import { useCurrentUser } from './user_data'

import { useI18n } from 'vue-i18n'
import { contributeArtwork } from '@/api/channel'
import { useRoute } from 'vue-router'
import { useAuthenticator } from '@/pinia/authenticator'
import { useToast } from './toast'


export const useArtworkSubmit = function () {
  const selectedList = ref([])
  const artworkStore = useArtworkStore()
  const loading = ref(false)
  const hasMore = ref(true)
  const { currentUser } = useCurrentUser()
  const authenticator = useAuthenticator()
  const artworkIds = ref([])
  const { t } = useI18n()
  const channelId = useRoute().params.channelId
  let timestamp = new Date().getTime()
  const { Toast } = useToast()


  const buttonText = computed(() => {
    const count = selectedList.value.filter(selected => selected).length
    if (count === 0) {
      return t('contribute')
    }

    return `${t('contribute')} (${count})`
  })


  const selectedArtworkIds = computed(() => {
    const indices = []
    selectedList.value.forEach((selected, index) => {
      if (selected) {
        indices.push(index)
      }
    })

    return indices.map(index => artworkIds.value[index])
  })


  const artworks = computed(() => {
    return artworkIds.value.map(artworkId => artworkStore.artworks[artworkId])
  })


  watch(artworks, () => {
    const arr = []
    arr.length = artworks.value.length
    selectedList.value = arr.fill(false)
  })


  const get = async function () {
    try {
      loading.value = true
      await authenticator.checkAuth()
      const posts = await getUserPostsTill(currentUser.value._id, timestamp, 10)
      if (posts.length === 0) {
        hasMore.value = false
      } else {
        timestamp = posts[posts.length - 1].createdAt
        posts.forEach(post => {
          artworkStore.cacheArtwork(post)
          artworkStore.pushUserLatestArtwork(currentUser.value._id, post)
        })
      }
      artworkIds.value.push(...posts.map(post => post._id))
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }


  const submit = async function () {
    try {
      
      await Promise.all(selectedArtworkIds.value.map(artworkId => contributeArtwork(channelId, artworkId)))
      Toast(t('success'), 'ok')
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      
    }
  }


  onMounted(get)


  return {
    artworks,
    selectedList,
    loading,
    hasMore,
    submit,
    buttonText,
    get,
  }
}