// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import VueSocialSharing from 'vue-social-sharing'
import { createPinia } from 'pinia'
import { GesturePlugin } from '@vueuse/gesture'
import { APP_URL, mixpanelToken, onesignal } from './config'
import PrimeVue from 'primevue/config'

import App from './App.vue'
import routes from './routes'
import { i18n } from './translations'
import VuePlyr from 'vue-plyr'
import './registerServiceWorker'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import mixpanel from 'mixpanel-browser'
import { useAuthenticator } from './pinia/authenticator'
import { createHead, setHeadInjectionHandler } from '@unhead/vue'
import { createRouter, createWebHistory } from 'vue-router'
import { PerohubPreset } from './utils/preset'
import VueLinkify from "vue-3-linkify"
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

const app = createApp(App)

const pinia = createPinia()
const head = createHead()
const router = createRouter({
  history: createWebHistory(),
  routes
})

app.use(i18n)
app.use(pinia)
app.use(router)
setHeadInjectionHandler(() => head)
app.use(GesturePlugin)
app.use(VueSocialSharing)
app.use(VuePlyr)
app.use(ToastService)
app.use(ConfirmationService)
app.use(PrimeVue, {
  theme: {
    preset: PerohubPreset,
    options: {
      darkModeSelector: false,
    }
  }
})
app.use(VueLinkify)
if (onesignal.domains[location.hostname]) {
  app.use(OneSignalVuePlugin, {
    appId: onesignal.domains[location.hostname].appId,
    autoResubscribe: true,
    safari_web_id: onesignal.domains[location.hostname].safariWebId,
  })
}

router.beforeEach(async (to, from) => {
  if(to.query.token) {
    const authenticator = useAuthenticator()
    await authenticator.setAccessToken(to.query.token)
    let query = to.query
    delete query.token

    return { path: to.path, query: query, replace: true }
  }
})

router.afterEach(function (transition) {
  let path = transition.path
  if (path.includes('/posts/') || path.includes('/users/') || path.includes('/comics/')) {
    if (!document.referrer.includes(APP_URL) && history.length <= 2) {
      history.replaceState({}, '', '/channels')
      const keys = Object.keys(transition.query)
      for (const [index, key] of keys.entries()) {
        if (path[path.length - 1] === '?') {
          path += '?'
        }
        path += `${key}=${transition.query[key]}`
        if (keys[index + 1]) {
          path += '&'
        }
      }
      history.pushState({}, '', path) 
    }
  }
})

router.afterEach((to, from) => {
  if (!to.meta.keepAlive && from.meta.keepAlive) {
    window.scrollTo(0, 0)
  }
})

mixpanel.init(
  mixpanelToken,
  {
    debug: process.env.NODE_ENV !== 'production',
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
  }
)

app.mount('#app')