<template>
  <div class="container">
    <div
      v-for="channel in channels"
      :key="channel._id"
      class="channel">
      <div class="content">
        <h3>{{ channel.title }}</h3>
        <p>{{ channel.description }}</p>
      </div>

      <pr-button
        size="small"
        @click="$router.push(`/i/publisher/channels/${channel._id}`)">{{ $t('edit') }}</pr-button>
    </div>

    <pr-button
      type="primary"
      @click="$router.push('/i/publisher/channels/create')">{{ $t('createChannel') }}</pr-button>
  </div>
</template>


<script>
import { computed } from 'vue'
import PrButton from '@/components/PRButton.vue'
import { useChannelStore } from '@/pinia/channel'


export default {
  setup () {
    const channelStore = useChannelStore()

    const channels = computed(() => {
      return channelStore.manageableChannels
    })


    return {
      channels,
    }
  },
  components: {
    PrButton,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  > button {
    margin-top: 1em;
    width: 100%;
  }

  .channel {
    display: flex;
    width: 100%;
    align-items: center;
    height: 5em;
    margin-bottom: 0.5em;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        color: @black;
        font-weight: bold;
        margin: 0;
        font-size: 17px;
      }

      p {
        color: @grey;
        font-weight: bolder;
      }
    }

    button {
      margin-left: auto;
    }
  }
}
</style>