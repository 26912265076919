<template>
  <div style="width: 100%; height: 100%;">
    <div
      class="touchable hoverable"
      style="display: flex; align-items: center; gap: 1rem;"
      @click.prevent.stop="visible = true">
      <div
        v-for="cocreator in displayingCocreators"
        v-show="displayingCocreators.length > 0"
        :key="cocreator.user._id"
        style="display: flex; align-items: center; gap: 0.5rem;">
        <user-avatar
          style="width: 2rem; height: 2rem;"
          :user="cocreator.user" />
        <span>
          {{ cocreator.user.nickname }}
        </span>
        <span>
          {{ cocreator.sharedRate * 100 }}%
        </span>
      </div>
      <span
        v-show="cocreators.length === 0"
        style="font-size: 0.9rem;">
        {{ $t('setCocreators') }}
      </span>
    </div>

    <pr-popup
      v-model="visible"
      :title="'cocreators'"
      class="cocreator-setter"
      @hide="resetAndClose">
      <template #header>
        {{ $t('pickCocreators') }}
      </template>

      <div class="pick-cocreators-container">
        <auto-complete
          v-model="selectedUser"
          style="width: 100%;"
          :suggestions="users"
          :placeholder="$t('searchByNickname')"
          field="nickname"
          @complete="search($event)">
        </auto-complete>

        <h3>
          {{ $t('revenueShares') }}
        </h3>

        <div style="display: flex; flex-direction: column; gap: 1rem;">
          <div
            v-for="draft in draftCocreators"
            :key="draft.user._id"
            style="display: flex; flex-direction: column; gap: 0.5rem;">
            <div style="display: flex; align-items: center; gap: 0.5rem;">
              <user-avatar
                style="width: 2rem; height: 2rem;"
                :user="draft.user" />
              <span>
                {{ draft.user.nickname }}
              </span>
            </div>
            <div>
              <input-number
                v-model="draft.sharedRate"
                suffix="%"
                :min="0"
                :max="100"
                :step="1"
                style="width: 100%;" />
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <pr-button
          type="primary"
          style="width: 100%;"
          @click="checkAndClose">
          {{ $t('confirm') }}
        </pr-button>
      </template>
    </pr-popup>
  </div>
</template>

<script>
//@ts-check
import { searchUsers } from '@/api/user'
import { useToast } from '@/composables/toast'
import { computed, ref, watch } from 'vue'
import PrPopup from './PRPopup.vue'
import PrButton from './PRButton.vue'
import AutoComplete from 'primevue/autocomplete'
import UserAvatar from './UserAvatar.vue'
import { whenever } from '@vueuse/core'
import InputNumber from 'primevue/inputnumber'
import { useCurrentUser } from '@/composables/user_data'

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { currentUser } = useCurrentUser()
    const cocreators = ref([])
    const { showToast } = useToast()
    const visible = ref(false)
    const users = ref([])
    const draftCocreators = ref([
      {
        user: currentUser.value,
        sharedRate: 100,
      }
    ])
    const selectedUser = ref(null)

    const displayingCocreators = computed(() => {
      return cocreators.value.map(cocreator => {
        return {
          ...cocreator,
          user: draftCocreators.value.find(draft => draft.user._id === cocreator.userId)?.user,
        }
      })
    })

    watch(props, () => {
      cocreators.value = props.modelValue
    }, { deep: true })

    whenever(selectedUser, () => {
      if (typeof selectedUser.value !== 'object') {
        return
      }
      draftCocreators.value.push({
        user: selectedUser.value,
        sharedRate: 0,
      })
      selectedUser.value = null
    })

    const search = async (event) => {
      try {
        users.value = await searchUsers(event.query)
      } catch (err) {
        showToast(err)
      }
    }

    const validate = () => {
      let totalSharedRate = 0
      for (const draft of draftCocreators.value) {
        if (draft.sharedRate === 0) {
          throw new Error('Shared rate cannot be 0')
        }
        if (draft.sharedRate > 100) {
          throw new Error('Shared rate cannot be greater than 100%')
        }
        totalSharedRate += draft.sharedRate
      }
      if (totalSharedRate !== 100) {
        throw new Error('Total shared rate must be 100%')
      }
      if (draftCocreators.value.length <= 1) {
        throw new Error('At least two co-creators are required')
      }
    }

    const checkAndClose = () => {
      try {
        validate()
        emit('update:modelValue', draftCocreators.value.map(draft => {
          return {
            userId: draft.user._id,
            sharedRate: Number((draft.sharedRate / 100).toFixed(2)),
          }
        }))
        visible.value = false
      } catch (err) {
        showToast(err)
      }
    }

    const resetAndClose = () => {
      try {
        validate()
      } catch (err) {
        emit('update:modelValue', [])
      } finally {
        visible.value = false
      }
    }

    return {
      visible,
      users,
      cocreators,
      search,
      draftCocreators,
      selectedUser,
      checkAndClose,
      resetAndClose,
      displayingCocreators,
    }
  },
  components: {
    PrPopup,
    PrButton,
    AutoComplete,
    UserAvatar,
    InputNumber,
  }
}
</script>

<style>
.p-autocomplete-input {
  width: 100%;
}

.cocreator-setter.p-drawer {
  height: 40rem !important;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.pick-cocreators-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;

  width: 20rem;

  @media @phone {
    width: 100%;
  }
}
</style>
