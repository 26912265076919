//@ts-check
import { updatePost, getPostTranslation } from "@/api/post"
import { useArtworkStore } from "@/pinia/artwork"
import { computed, onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useSortable } from '@vueuse/integrations/useSortable'
import { getDataURL, getFileType, sliceThrehold, useUploadMedia } from '@/composables/upload_media'
import { useToast } from "@/composables/toast"
// @ts-ignore

import { useArtworkWrite } from "./artwork_write"
import { MediaType } from "@/utils/defines"



export const useArtworkEdit = function () {
  const artworkStore = useArtworkStore()
  const artworkId = useRoute().params.postId
  const updating = ref(false)
  const title = ref('')
  const content = ref('')
  const medias = ref([])
  const mediasContainerRef = ref(null)
  const mediaInputRef = ref(null)
  const router = useRouter()
  const {
    uploads, uploadOne, uploadSlicedOne,
    progress, uploading, total
  } = useUploadMedia()
  const selectedIndex = ref(-1)
  const { Toast } = useToast()
  const useWatermark = ref(null)
  const showWatermarkPositionsPicker = ref(false)
  const watermarkPositions = ref([])
  const isR18 = ref(false)
  const collectible = ref(false)
  const isFree = ref(true)
  const titleLocales = ref({})
  const contentLocales = ref({})
  const localeEnabled = ref(false)
  const defaultLocale = ref(null)
  const hideStars = ref(false)
  const { textMedias, addTextMedia, concludeTextMedias } = useArtworkWrite({ mediasRef: medias })
  const priceInfo = ref({
    defaultCurrency: 'JPY',
    prices: {
      CNY: 0,
      JPY: 0,
      USD: 0,
      EUR: 0,
    },
    price: 0,
  })

  const immutableArtwork = computed(() => artworkStore.artworks[artworkId])

  watch(immutableArtwork, async () => {
    if (immutableArtwork.value) {
      if (!immutableArtwork.value.contentLocales) {
        try {
          const translations = await getPostTranslation(artworkId)
          artworkStore.attachTranslations(artworkId, translations)
        } catch (err) {
          Toast({
            message: err.message,
          })
        }
      }

      init()
    }
  })

  watch(useWatermark, (_, oldUseWatermark) => {
    if (oldUseWatermark !== null) {
      showWatermarkPositionsPicker.value = useWatermark.value      
    }
  })

  const init = async function () {
    try {
      if (!immutableArtwork.value.contentLocales) {
        const translations = await getPostTranslation(artworkId)
        artworkStore.attachTranslations(artworkId, translations)
      }
  
      title.value = immutableArtwork.value.title
      content.value = immutableArtwork.value.content
      medias.value = immutableArtwork.value.pictures.filter(media => {
        if (media.type === MediaType.IMAGE) {
          return true
        }

        if (media.type === MediaType.VIDEO) {
          return true
        }

        if (media.type === MediaType.FILE) {
          return true
        }

        return false
      }).map(media => {
        return {
          ...media,
          // '/6452266be6072d756ae2918b/fc7925d0c757c07e889facc05fea0997/pjpeg'  =>
          // '/6452266be6072d756ae2918b/fc7925d0c757c07e889facc05fea0997'
          path: new URL(media.url).searchParams.get('path').split('/').slice(0, 3).join('/')
        }
      })
  
      if (immutableArtwork.value.watermark) {
        useWatermark.value = immutableArtwork.value.watermark.enabled
        watermarkPositions.value = immutableArtwork.value.watermark.positions
      }
  
      isR18.value = immutableArtwork.value.isR18
      collectible.value = immutableArtwork.value.collectible
      hideStars.value = immutableArtwork.value.hideStars
      isFree.value = immutableArtwork.value.price === 0
      if (immutableArtwork.value.titleLocales) {
        titleLocales.value = immutableArtwork.value.titleLocales
      }
      if (immutableArtwork.value.contentLocales) {
        contentLocales.value = immutableArtwork.value.contentLocales      
      }
  
      for (const lang of Object.keys(titleLocales.value)) {
        if (titleLocales.value[lang] === title.value) {
          defaultLocale.value = lang
          break
        }
      }
  
      if (immutableArtwork.value.price > 0) {
        priceInfo.value.price = immutableArtwork.value.price
        priceInfo.value.defaultCurrency = immutableArtwork.value.defaultCurrency
  
        for (const currency of Object.keys(immutableArtwork.value.prices)) {
          if (currency === 'JPY') {
            priceInfo.value.prices[currency] = immutableArtwork.value.prices[currency]
          } else {
            priceInfo.value.prices[currency] = Number((immutableArtwork.value.prices[currency] / 100).toFixed(2))
          }
        }
      }

      isR18.value = immutableArtwork.value.isR18

      textMedias.value = immutableArtwork.value.pictures.filter(media => media.type === MediaType.TEXT)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const replaceLocalMedia = async function (index, file) {
    try {
      
      const type = getFileType(file)

      let newMedia = null

      const preview = {
        file,
        type,
        canBrowse: medias.value[index].canBrowse,
        filename: file.name,
        mime: file.type,
        thum: (await getDataURL(file))[0],
      }
  
      if (file.size > sliceThrehold) {
        uploads.value[index] = []
        newMedia = await uploadSlicedOne(preview, 0)
      } else {
        uploads.value[index] = 0
        newMedia = await uploadOne(preview, 0)
      }

      preview.path = newMedia.path

      medias.value[index] = preview
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    } finally {
      
    }
  }


  const addLocalMedias = async function (files) {
    try {
      
      for (const file of files.values()) {
        const type = getFileType(file)
        const index = medias.value.length
  
        medias.value.push({
          type,
          canBrowse: immutableArtwork.value.price === 0,
          thum: (await getDataURL(file))[0],
          filename: file.name,
          mime: file.type
        })

        const preview = {
          file,
          type,
          canBrowse: medias.value[index].canBrowse,
          filename: file.name,
          mime: file.type,
        }
  
        let newMedia = null
    
        if (file.size > sliceThrehold) {
          uploads.value[index] = []
          newMedia = await uploadSlicedOne(preview, 0)
        } else {
          uploads.value[index] = 0
          newMedia = await uploadOne(preview, 0)
        }

        medias.value[index] = {
          ...preview,
          _id: undefined,
          path: newMedia.path,
          thum: medias.value[index].thum,
        }
      }
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    } finally {
      
    }
  }

  const submit = async function () {
    try {
      updating.value = true
      const concludedTextMedias = concludeTextMedias()

      if (immutableArtwork.value.type === 'article' && concludedTextMedias.length === 0) {
        throw new Error('Please add at lease one text block')
      }

      const updater = {
        title: title.value,
        content: content.value,
        medias: [...concludedTextMedias, ...medias.value].map(media => {
          return {
            _id: media._id,
            path: media.path,
            type: media.type,
            canBrowse: media.canBrowse,
            filename: media.filename,
            mime: media.mime,
            textContent: media.textContent ?? '',
          }
        }),
        useWatermark: useWatermark.value,
        watermarkPositions: watermarkPositions.value,
        isR18: isR18.value,
        type: immutableArtwork.value.type,
        collectible: collectible.value,
        hideStars: hideStars.value,
      }
      if (localeEnabled.value) {
        if (defaultLocale.value) {
          titleLocales.value[defaultLocale.value] = title.value
          if (content.value) {
            contentLocales.value[defaultLocale.value] = content.value
          }
        }

        updater.titleLocales = titleLocales.value
        updater.contentLocales = contentLocales.value
      }

      if (immutableArtwork.value.price > 0) {
        const prices = {}

        for (const currency of Object.keys(priceInfo.value.prices)) {
          if (currency === 'JPY') {
            prices[currency] = priceInfo.value.prices[currency]
          } else {
            prices[currency] = priceInfo.value.prices[currency] * 100
          }
        }

        updater.price = priceInfo.value.price
        updater.defaultCurrency = priceInfo.value.defaultCurrency
        updater.prices = prices
        updater.type = immutableArtwork.value.type
      }

      await updatePost(artworkId, updater)
      await artworkStore.getArtworkById(artworkId, true)
      if (immutableArtwork.value.type === 'album') {
        router.push(`/posts/${artworkId}`)
      } else if (immutableArtwork.value.type === 'comics') {
        router.push(`/comics/${artworkId}`)
      } else if (immutableArtwork.value.type === 'article') {
        router.push(`/posts/${artworkId}`)
      }
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    } finally {
      updating.value = false
    }
  }

  const fireFileInput = async function (index) {
    selectedIndex.value = index
    if (mediaInputRef.value) {
      mediaInputRef.value.click()      
    }
  }

  const triggerFileInput = async function (event) {
    if (uploading.value) {
      return
    }
    const files = Array.from(event.target.files)

    if (files.length <= 0) {
      return
    }

    uploading.value = true
    uploads.value = []

    if (selectedIndex.value !== -1) {
      const file = files[0]
      total.value = file.size
      await replaceLocalMedia(selectedIndex.value, files[0])
    } else {
      total.value = files.reduce((prev, cur) => prev + cur.size, 0)
      await addLocalMedias(files)
    }

    total.value = 0
    uploads.value = []
    uploading.value = false

    selectedIndex.value = -1

    event.target.value = ''
  }

  onMounted(() => {
    if (immutableArtwork.value) {
      init()
    } else {
      artworkStore.getArtworkById(artworkId)
    }
  })

  useSortable(mediasContainerRef, medias)

  return {
    title,
    content,
    medias,
    submit,
    updating,
    mediasContainerRef,
    mediaInputRef,
    progress,
    uploading,
    triggerFileInput,
    fireFileInput,
    useWatermark,
    watermarkPositions,
    showWatermarkPositionsPicker,
    isR18,
    collectible,
    isFree,
    titleLocales,
    contentLocales,
    localeEnabled,
    defaultLocale,
    immutableArtwork,
    priceInfo,
    textMedias,
    addTextMedia,
    hideStars,
  }
}