<template>
  <div class="container">
    <pr-footer></pr-footer>
  </div>
</template>

<script>
import PrFooter from '@/components/PRFooter.vue'
import { useHead } from '@unhead/vue';

export default {
  data () {
    useHead({
      title: 'About Us | Perohub',
    })

    return {
      selected: 0,
      showUserInfo: true
    }
  },
  components: {
    PrFooter,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  padding: 0.1rem;
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media @desktop {
    display: none;
  }  
}
</style>