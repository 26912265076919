import { api } from './base'

export default {
  async send(postId, reason, contentIds) {
    try {
      const res = await api.post(`/posts/${postId}/report`, {
        reason,
        contentIds
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getAll(page, limit) {
    try {
      const res = await api.get('/reports/all', {
        params: {
          page,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getMine(page, limit) {
    try {
      const res = await api.get('/reports/my', {
        params: {
          page,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getAccusation(page, limit) {
    try {
      const res = await api.get('/reports/target/my', {
        params: {
          page,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getOne(reportId) {
    try {
      const res = await api.get(`/reports/${reportId}/single`)
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getComments(page, limit, reportId) {
    try {
      const res = await api.get('/comments/report/comments', {
        params: {
          page,
          limit,
          reportId
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async postComment(reportId, content) {
    try {
      const res = await api.post('/comments/reportComment', {
        reportId,
        content
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
