<template>
  <div class="absolute-cover">
    <slot></slot>
  </div>
</template>

<style scoped>
.absolute-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
</style>