<template>
  <div class="issues-container">
    <issue-cell
      v-for="issue in issues"
      :key="issue._id"
      :issue="issue"
      class="touchabe hoverable"
      @click="$router.push(`/commissions/${issue._id}`)">
    </issue-cell>
  </div>
</template>


<script>
import IssueCell from './IssueCell.vue'

export default {
  props: {
    issues: {
      type: Array,
      required: true
    }
  },
  setup () {
    return {
    }
  },
  components: {
    IssueCell
  }
}
</script>

<style scoped>
.issues-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
</style>