<template>
  <div class="empty-container">
    <img src="@/assets/Page_empty_guider.png">
    <p>{{ displayText }}</p>
    <pr-button
      v-if="buttonText"
      type="primary"
      @click="clicked"
    >
      {{ buttonText }}
    </pr-button>
  </div>
</template>

<script>
import PrButton from '@/components/PRButton.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  components: {
    PrButton
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { t } = useI18n()

    const displayText = computed(() => {
      return props.text || t('nothingHere')
    })

    return {
      displayText
    }
  },
  methods: {
    clicked () {
      this.$emit('trigger')
    }
  }
}
</script>

<style lang="less" scoped>
.empty-container {
  text-align: center;
  padding: 40px 0;

  > * {
    display: block;
  }

  img {
    width: 80px;
    margin: auto;
  }

  p {
    margin-top: 10px;
    font-size: 17px;
    color: #7b7b7b;
    font-weight: 500;
  }

  button {
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    margin: auto;
    margin-top: 18px;
  }
}
</style>
