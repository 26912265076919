<template>
  <div
    style="display: flex; margin-left: 1rem; margin-right: 1rem; min-height: 3rem; align-items: center; border-top: 1px solid #f0f0f0; padding-top: 0.5rem; padding-bottom: 0.5rem;"
    @click="jump">
    <div style="display: flex; flex-direction: column;">
      <div
        :style="{
          fontSize: label ? '0.8rem' : '0.9rem'
        }">
        {{ title }}
      </div>
      <div
        v-if="label"
        style="font-size: 0.77rem;"
        class="minor">
        {{ label }}
      </div>
    </div>

    <div
      v-if="modelValue"
      class="minor">{{ modelValue }}</div>
    <div
      v-else
      style="flex: 1; display: flex; justify-content: flex-end;">
      <slot></slot>
    </div>

    <div
      v-if="isLink"
      style="display: flex; justify-content: center; align-items: center;">
      <i class="pi pi-chevron-right minor"></i>
    </div>
  </div>
</template>


<script>
//@ts-check
import { useRouter } from 'vue-router'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    isLink: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const router = useRouter()

    const jump = () => {
      if (!props.isLink) {
        return
      }

      if (props.to.includes('http://') || props.to.includes('https://')) {
        const newWindow = window.open(props.to, '_blank')
        if (!newWindow) {
          location.href = props.to
        }
      } else {
        router.push(props.to)
      }
    }

    return {
      jump
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.minor {
  color: @minor;
}
</style>
