<template>
  <login-cover v-if="!authenticated"></login-cover>

  <div
    v-else>
    <div class="albums">
      <router-link
        v-for="album in albums"
        :key="album.user._id"
        :to="`/stars/${album.user._id}`"
        class="album touchable">
        <img
          loading="lazy"
          :src="album.user.avatarURL">
        <h3>{{ album.user.nickname }}</h3>
        <span>({{ album.pictureCount }})</span>
      </router-link>
    </div>

    <load-more-button
      :has-more="starHasmore"
      :busy="starLoading"
      :trigger="getStarCategories"></load-more-button>
  </div>
</template>


<script>
import LoginCover from '@/components/LoginCover.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import LoadMoreButton from './LoadMoreButton.vue'
import { useStars } from '@/composables/stars'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'My Stars | Perohub',
    })

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      ...useStars(),
    }
  },
  components: {
    LoginCover,
    LoadMoreButton,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";


.albums {
  padding: 1em;

  .album {
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: @border;
    height: 4em;

    span {
      color: @grey;
      font-size: 15px;
      font-weight: bolder;
    }

    h3 {
      font-weight: bold;
      color: @black;
      font-size: 16px;
      margin-right: 18px;
    }

    img {
      width: 2.5em;
      height: 2.5em;
      border-radius: 1.25em;
      margin-right: 1em;
    }
  }
}
</style>