<template>
  <div v-if="isOnMobile">
    <div
      v-for="post in posts.filter(post => post !== undefined)"
      :key="post._id"
      class="item">
      <post-list-item :post="post">
      </post-list-item>
    </div>
  </div>
  <masonry
    v-else
    :cols="3"
    :gutter="10">
    <div
      v-for="post in posts.filter(post => post)"
      :key="post._id"
      class="common">
      <post-list-item :post="post"></post-list-item>
    </div>
  </masonry>
</template>

<script>
import PostListItem from '@/components/PostListItem.vue'
import { useResponsive } from '@/composables/responsive'
import Masonry from '@/components/Masonry.vue'

export default {
  props: {
    posts: {
      type: Array,
      required: true
    },
  },
  setup () {
    return {
      ...useResponsive(),
    }
  },
  components: {
    PostListItem,
    Masonry,
  },
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";


.common {
  width: 100%;

  @media @phone {
    margin-bottom: 1em;
  }
}
</style>
