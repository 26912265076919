<template>
  <div
    class="container">
    <login-cover v-if="!authenticated" />
    <div
      v-if="authenticated"
      class="left">
      <input
        v-model="keyword"
        @keypress="enter">
      <p
        v-for="(album,index) in albums"
        v-show="!search"
        :key="index"
        class="hoverable touchable"
        :class="{back: album.ischeck}"
        @click="pic(index, album.user._id, false)">
        {{ album.user.nickname }}
      </p>
      <p
        v-show="albums.length > 0 && hasMore && !search"
        class="bule hoverable touchable"
        @click="loadMore">
        {{ $t('loadmore') }}
      </p>
      <p
        v-show="albums.length > 0 && !hasMore && !search"
        class="grey hoverable touchable"
        @click="loadMore">
        {{ $t('nomore') }}
      </p>
      <p
        v-for="(album,index) in searchAlbum"
        v-show="search"
        :key="index"
        class="hoverable touchable"
        :class="{back: album.ischeck}"
        @click="pic(index, album.user._id, true)">
        {{ album.user.nickname }}
      </p>
    </div>
    <div
      v-if="authenticated"
      class="right">
      <div class="content">
        <div
          v-for="(picture, index) in pictures"
          :key="index">
          <img
            v-if="picture.type === MediaType.IMAGE"
            :src="picture.thum"
            class="product hoverable"
            @click="view(index)">
          <img
            v-if="picture.type === MediaType.VIDEO"
            :src="picture.thum"
            class="product hoverable"
            @click="view(index)">
          <img
            v-if="picture.type === MediaType.VIDEO"
            class="video"
            src="@/assets/video_tag.png">
          <img
            v-show="picture.showcheck && !picture.ischeck"
            class="edit"
            src="@/assets/Select_Off.png">
          <img
            v-show="picture.showcheck && picture.ischeck"
            class="edit"
            src="@/assets/Select_On.png">
        </div>
        <div
          v-show="pictures.length > 0"
          class="hoverable touchable load"
          @click.prevent="more">
          <span>{{ $t('viewMore') }}</span>
        </div>
      </div>
      <div class="bottom">
        <span
          v-show="!editprocess && pictures.length>0"
          class="hoverable touchable"
          @click="edit(true)">{{ $t('edit') }}</span>
        <span
          v-show="editprocess"
          class="red hoverable touchable"
          @click="remove">{{ $t('delete') }}({{ dealPicture }})</span>
        <span
          v-show="editprocess"
          class="cancel hoverable touchable"
          @click="edit(false)">{{ $t('cancel') }}</span>
      </div>
    </div>
  </div>
</template>


<script>
//@ts-check
import LoginCover from '@/components/LoginCover.vue'
import api from '@/api/media'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useApplication } from '@/pinia/application'
import { useToast } from '@/composables/toast'
import { MediaType } from '@/utils/defines'
import { useCollectionStore } from '@/pinia/star'
import { computed, onMounted, ref, watch } from 'vue'
import { useHead } from '@unhead/vue'

const limit = 10

export default {
  setup () {
    const application = useApplication()
    const collectionStore = useCollectionStore()
    const { showToast } = useToast()
    const page = ref(1)
    const busy = ref(false)
    const keyword = ref('')
    const searchAlbum = ref([])
    const search = ref(false)
    const albumId = ref(null)
    const { authenticated } = useCurrentUser()

    useHead({
      title: 'My Stars | Perohub',
    })

    const albums = computed(() => collectionStore.groups)
    const pictures = computed(() => collectionStore.medias)
    const dealPicture = computed(() => collectionStore.selectedMediasCount)
    const editprocess = computed(() => collectionStore.editing)
    const hasMore = computed(() => collectionStore.hasMore)

    watch(authenticated, () => {
      if (authenticated.value) {
        loadMore()
      }
    })

    watch(albums, () => {
      if (pictures.value.length === 0 && albumId.value === null && albums.value.length > 0) {          
        pic(0, albums.value[0].user._id)
      }
    })

    const loadMore = async function () {
      try {
        busy.value = true
        await collectionStore.getStarGroups(page.value)
        page.value += 1
      } catch (err) {
        showToast(err)
      }
    }

    const pic = async function (index, _albumId, _search) {
      collectionStore.$patch({ medias: [], hasMore: true })
      
      if (_search && searchAlbum.value.length > 0) {
        searchAlbum.value.forEach(album => {
          album.ischeck = false
        })

        searchAlbum.value[index].ischeck = true
      } else {
        collectionStore.$patch({
          groups: collectionStore.groups.map(album => {
            album.ischeck = false
            return album
          })
        })
      }
      albumId.value = _albumId
      await collectionStore.getMedias(albumId.value)
    }

    const enter = async function (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode === 13) {
        search.value = true
        await albumsSearch(keyword.value)
      }

      if (keyword.value == '') {
        search.value = false
      }
    }

    const albumsSearch = async function (keyword) {
      try {
        const albums = await api.albumsSearch(keyword)
        albums.forEach(album => {
          album.ischeck = false
        })

        searchAlbum.value = albums
      } catch (err) {
        showToast(err)
      } finally {
        busy.value = false
      }
    }

    onMounted(() => {
      page.value = albums.value.length / limit + 1
      if (authenticated.value && albums.value.length === 0) {
        loadMore()
      }
    })

    return {
      ...useResponsive(),
      authenticated,
      openBrowser: application.openBrowser,
      ...useToast(),
      MediaType,
      albums,
      pictures,
      dealPicture,
      editprocess,
      edit: collectionStore.edit,
      remove: collectionStore.remove,
      more: collectionStore.more,
      view: collectionStore.view,
      page,
      busy,
      loadMore,
      enter,
      albumsSearch,
      keyword,
      searchAlbum,
      search,
      albumId,
      hasMore,
      pic,
    }
  },
  components: {
    LoginCover,
  }
}
</script>


<style lang="less" scoped>

img {
  object-fit: cover;
}

.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  padding-bottom: 80px;


  .left {
    width: 130px;
    text-align: center;
    margin-right: 40px;

    input {
      display: inline-block;
      width: 110px;
      height: 30px;
      border-radius: 15px;
      background: #ffffff url('@/assets/ic_tabs_discovery@2x.png') no-repeat;
      background-size: 20px 20px;
      background-position: 10px center;
      border: none;
      outline: none;
      padding-left: 10px;
      font-size: 14px;
      text-indent: 25px;
      border: 1px solid #f1f1f1;
    }


    p {
      width: 120px;
      height: 30px;
      border-radius: 15px;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      margin-top: 6px;
      margin-left: auto;
      margin-right: auto;
      white-space:nowrap; 
      text-overflow:ellipsis; 
      overflow:hidden;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;

      &.back {
        background-color: #f1f1f1;
      }

      &.bule {
        border: 1px solid #55caff;
        color: #55caff;
      }

      &.grey {
        border: 1px solid #f1f1f1;
      }

    }
  }

  .right {
    flex: 1;

    .content {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 114px;
        height: 114px;
        margin-right: 2px;
        margin-bottom: 2px;
        position: relative;
        .product {
          width: 114px;
          height: 114px;
          
        }

        .video {
          position: absolute;
          width: 50px;
          height: 50px;
          left: 28%;
          top: 28%;
        }

        .edit {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }

      .load {
        border: solid 1px #55caff;
        display: flex;
        justify-content:center;
        align-items:center;
        box-sizing: border-box;

        span {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #55caff;

        }
      }
    }

    .bottom {
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      margin-top: 20px;
      margin-bottom: 30px;
      

      span {
        width: 120px;
        height: 30px;
        border-radius: 15px;
        background-color: #ff5579;
        display: inline-block;
        line-height: 30px;
        color: #9b9b9b;
        background-color: #f1f1f1;

        &.cancel {
          border: solid 1px #cfcfcf;
        }

        &.red {
          background-color: #ff5579;
          color: white;
          margin-right: 6px;
        }
      }
    }
  }
}

</style>
