//@ts-check
import { zh } from './zh'
import { ja } from './ja'
import { en } from './en'
import { createI18n } from 'vue-i18n'
import { register } from 'timeago.js'
import timeagoZhcn from 'timeago.js/lib/lang/zh_CN'
import timeagoZhtw from 'timeago.js/lib/lang/zh_TW'
import timeagoJa from 'timeago.js/lib/lang/ja'


register('zh-CN', timeagoZhcn)
register('zh-TW', timeagoZhtw)
register('zh-HK', timeagoZhtw)
register('zh-SG', timeagoZhcn)
register('ja', timeagoJa)




const possibleLocal = navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language

export const locale = process.env.NODE_ENV === 'production' ? possibleLocal : 'ja'
// const locale = possibleLocal

const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  'ja-JP': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
  'zh-CN': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
}

export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: 'en',
  // @ts-ignore
  dateTimeFormats,
  messages: {
    zh,
    ja,
    en,
  }
})