<template>
  <div class="container">
    <div
      class="user-info-container"
      :class="{ 'min-width-info': !isOnMobile, 'mobile-container-info': isOnMobile }">
      <div class="buttons">
        <more-menu
          v-if="userMoreMenuItems.length > 0"
          v-model="showUserMoreMenu"
          :items="userMoreMenuItems"
          :negative-top="20">
          <vue-feather
            v-if="isOnMobile"
            class="hoverable touchable"
            type="more-horizontal"
            @click="showUserMoreMenu = true"></vue-feather>
          <vue-feather
            v-else
            class="hoverable touchable"
            type="more-horizontal"
            @click="showUserMoreMenu = true"
            @mouseover="showUserMoreMenu = true"
            @mouseleave="showUserMoreMenu = false"></vue-feather>
        </more-menu>

        <pr-button
          v-if="user._id && currentUserId === user._id"
          type="blue"
          @click.prevent.stop="$router.push({
            path: '/i/edit'
          })">
          {{ $t('updateProfile') }}
        </pr-button>

        <pr-button
          v-else-if="user.vipsSet"
          type="blue"
          @click.prevent.stop="buyContactClicked">
          {{ $t('getInTouch') }}
        </pr-button>
      </div>

      <div class="avatar">
        <router-link
          v-if="user._id === currentUserId && $route.path.includes('/i')"
          :to="`/users/${user._id}`">
          <img
            loading="lazy"
            :src="user.avatarURL">
        </router-link>
        <img
          v-else
          loading="lazy"
          :src="user.avatarURL">
        <pr-button
          v-if="user._id !== currentUserId"
          :type="userCopy.followed ? 'default' : 'primary'"
          :flat="userCopy.followed"
          class="hoverable"
          @click.prevent.stop="userCopy.followed ? unfollow(user._id, userCopy) : follow(user._id, userCopy)">
          {{ userCopy.followed ? $t('followed') : $t('follow') }}
        </pr-button>
      </div>

      <div class="info">
        <h3>
          {{ user.nickname }} 
          <circle-badge
            v-if="user.tag && user.tag.visible"
            :member-tag="user.tag.memberTag"
            @click.stop="$router.push(`/users/${user.tag.authorId}/circles`)"></circle-badge>
        </h3>

        <div
          v-if="user.officialBadge"
          class="badge">
          <img
            loading="lazy"
            :src="user.officialBadge.iconUrl">
          <span style="margin-left: 0.5em;">{{ user.officialBadge.title }}</span>
        </div>

        <p
          v-linkify:options="{ className: 'userinfo-linkified' }">
          {{ user.motto }}
        </p>

        <div class="records">
          <div
            class="record"
            :class="{ hoverable: !isInUserPage, touchable: !isInUserPage }" 
            @click.prevent.stop="goToUserPage">
            <p>
              {{ $t('works') }}
            </p>
            <h3>{{ shortString(user.postCount) }}</h3>
          </div>

          <div
            v-if="!isInUserList"
            class="record hoverable touchable"
            @click.prevent.stop="goToFollowers">
            <p>
              {{ $t('followers') }}
            </p>
            <h3>{{ shortString(user.followerCount) }}</h3>
          </div>
          <div
            v-else
            class="record">
            <p>
              {{ $t('followers') }}
            </p>
            <h3>{{ shortString(user.followerCount) }}</h3> 
          </div>

          <div
            v-if="!isInUserList"
            class="record hoverable touchable"
            @click.prevent.stop="goToFollowings">
            <p>
              {{ $t('following') }}
            </p>
            <h3>{{ shortString(user.followingCount) }}</h3>
          </div>
          <div
            v-else
            class="record">
            <p>
              {{ $t('following') }}
            </p>
            <h3>{{ shortString(user.followingCount) }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//@ts-check
import PrButton from './PRButton.vue'
import CircleBadge from './CircleBadge.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentCurrency } from '@/composables/currency'
import { useUserContact } from '@/composables/user_aisatu'
import { useUserMoreMenu } from '@/composables/user_moremenu'
import MoreMenu from '@/components/MoreMenu.vue'
import { useUserFollow } from '@/composables/user_follow'
import { useCurrentUser } from '@/composables/user_data'
import { useRouter, useRoute } from 'vue-router'
import { computed, reactive } from 'vue'
import VueFeather from 'vue-feather'

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const userCopy = reactive(Object.assign({}, props.user))

    const isInUserList = computed(() => {
      return route.path.indexOf('followers') !== -1 || route.path.indexOf('followings') !== -1
    })

    const isInUserPage = computed(() => {
      return route.path.indexOf('/users/') !== -1
    })

    const goToFollowers = () => {
      router.push(`/users/${props.user._id}/followers`)
    }

    const goToFollowings = () => {
      router.push(`/users/${props.user._id}/followings`)
    }

    const goToUserPage = () => {
      if (!isInUserPage.value) {
        router.push(`/users/${props.user._id}`)
      }
    }

    const shortString = (value) => {
      let newValue = value

      if (value >= 1000) {
        const suffixes = ['', 'k', 'm', 'b', 't']
        const suffixNum = Math.floor(('' + value).length / 3)
        let shortValue = 0

        for (let precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
          const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')

          if (dotLessShortValue.length <= 2) {
            break
          }
        }

        if (shortValue % 1 !== 0) {
          shortValue = Number(shortValue.toFixed(1))
        }

        newValue = shortValue + suffixes[suffixNum]
      }

      return newValue
    }

    return {
      userCopy,
      isInUserList,
      isInUserPage,
      goToFollowers,
      goToFollowings,
      goToUserPage,
      shortString,
      ...useCurrentUser(),
      ...useResponsive(),
      ...useCurrentCurrency(),
      ...useUserContact(props.user),
      ...useUserMoreMenu(props.user),
      ...useUserFollow(),
    }
  },
  components: {
    PrButton,
    CircleBadge,
    VueFeather,
    MoreMenu,
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

.vue-feather {
  color: @minor;
  width: 24px;
}

.user-info-container {
  padding: 16px;
  width: 100%;
  margin: 0 auto;
  background: white;
  border: solid 1px #dedede;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  max-width: 100%;

  &.mobile-container-info {
    padding: 16px 0 0 0;
    border: none;

    .buttons {
      right: 3px;
      top: 16px;
    }
  }

  .buttons {
    position: absolute;
    right: 16px;
    top: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > button {
      height: 26px;
      border-radius: 13px;
      font-size: 12px;
      margin-left: 20px;
    }
  }

  .avatar {
    display: inline-block;
    vertical-align: top;

    img {
      display: block;
      border-radius: 40px;
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin: 0 auto;
    }

    button {
      margin-top: 12px;
      width: 88px;
      height: 25px;
      border-radius: 2px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .info {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin-left: 6px;
    flex-grow: 1;

    > h3 {
      font-size: 17px;
      font-weight: 500;
      color: @black;
    }

    .badge {

      img {
        display: inline-block;
        width: 17px;
        height: 17px;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        font-size: 12px;
        color: @grey;
      }
    }

    > p {
      margin-top: 7px;
      margin-bottom: 12px;
      font-size: 14px;
      color: #9b9b9b;
      line-height: 1.5;
      white-space: pre-wrap;

      .userinfo-linkified {
        color: @blue;
        cursor: pointer;
        opacity: 0.65;
        -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
        float: none;
      }
    }

    .records {
      display: flex;
      width: 100%;

      @media @phone {
        .record {
          margin: 0 0.2em !important;
        }
      }

      .record {
        min-width: 60px;
        display: flex;
        margin: 0 0.8em;

        h3 {
          display: inline-block;
          color: @main;
          font-size: 18px;
          font-weight: 500;
          vertical-align: bottom;
          margin-left: 0.7em;
          flex-grow: 0;
        }

        p {
          display: inline-block;
          font-size: 12px;
          color: @grey;
          vertical-align: bottom;
          flex-grow: 1;
          white-space: nowrap;
        }
      }
    }
  }
}

.min-width-info {
  min-width: 394px;
}
</style>
