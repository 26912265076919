<template>
  <div class="discount-codes-container">
    <header style="display: flex; justify-content: space-between; margin-bottom: 1rem;">
      <pr-button
        :loading="loading"
        @click="get">
        {{ $t('refresh') }}
      </pr-button>

      <pr-button
        @click="$router.push('/i/publisher/discountCodes/create')">
        {{ $t('create') }}
      </pr-button>
    </header>

    <data-table :value="discountCodes">
      <column
        header="Code"
        field="code">
      </column>
      <column
        header="Discount">
        <template #body="slotProps">
          {{ slotProps.data.discount * 100 }}% OFF
        </template>
      </column>
      <column
        header="artworks">
        <template #body="slotProps">
          {{ slotProps.data.posts && slotProps.data.posts.length > 0 ? slotProps.data.posts.length : $t('unlimited') }}
        </template>
      </column>
      <column
        header="Expired At">
        <template #body="slotProps">
          {{ slotProps.data.expiresAt ? new Date(slotProps.data.expiresAt).toLocaleString() : $t('never') }}
        </template>
      </column>
      <column
        header="action">
        <template #body="slotProps">
          <pr-button
            @click="remove(slotProps.data._id)">
            {{ $t('delete') }}
          </pr-button>
        </template>
      </column>
    </data-table>
  </div>
</template>

<script>
//@ts-check
import { deleteDiscountCode, getDiscountCodes } from '@/api/discountcode'
import { useToast } from '@/composables/toast'
import { ref, onMounted } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import PrButton from '@/components/PRButton.vue'

export default {
  setup () {
    const discountCodes = ref([])
    const loading = ref(false)
    const { Toast } = useToast()

    const get = async function () {
      try {
        loading.value = true
        discountCodes.value = await getDiscountCodes()
      } catch (err) {
        Toast(err)
      } finally {
        loading.value = false
      }
    }

    const remove = async function (discountCodeId) {
      try {
        await deleteDiscountCode(discountCodeId)
        await get()
      } catch (err) {
        Toast(err)
      }
    }

    onMounted(async () => {
      await get()
    })

    return {
      discountCodes,
      loading,
      get,
      remove,
    }
  },
  components: {
    DataTable,
    Column,
    PrButton,
  }
}
</script>