<template>
  <div class="notification-container-y43">
    <p
      v-linkify:options="{ className: 'userinfo-linkified' }"
      class="title"
      v-html="sanitize(msg.title)"></p>
    <p
      v-linkify:options="{ className: 'userinfo-linkified' }"
      class="content"
      v-html="sanitize(msg.content)"></p>
  </div>
</template>

<script>
import { sanitize } from 'dompurify'
import { getMessage as apiGetMessage } from '@/api/message.js'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Notification Detail | Perohub',
    })

    return {
      ...useToast(),
    }
  },
  data () {
    return {
      msg: {}
    }
  },
  mounted () {
    this.getMessage()
  },
  methods: {
    sanitize,
    getMessage () {
      //
      apiGetMessage(this.$route.params.notificationId)
        .then(msg => {
          this.msg = msg
          console.info(this.msg)
        })
        .catch(err => {
          //

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
  },
  components: {
    
  }
}
</script>

<style lang="less">
@import '@/assets/base.less';

.notification-container-y43 {
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  margin-top: 10px;

  .userinfo-linkified {
    color: @blue;
    cursor: pointer;
    opacity: 0.65;
  }

  .title {
     font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    border-bottom: 1px solid #f1f1f1;
    line-height: 30px;
    padding-bottom: 6px;
    margin-bottom: 8px;
  }

  .content {
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .link {
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #55caff;

    
  }
}

</style>
