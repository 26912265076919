//@ts-check
import { useChannelStore } from '@/pinia/channel'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useCurrentUser } from './user_data'
import { useToast } from './toast'
import { APP_URL } from '@/config'


export const useChannelMoremenu = function (getterChannel) {
  const showChannelMoreMenu = ref(false)
  const channelStore = useChannelStore()
  const router = useRouter()
  const { authenticated } = useCurrentUser()
  const { t } = useI18n()
  const { Toast } = useToast()


  const manageableChannelIds = computed(() => {
    return channelStore.manageableChannels.map(channel => channel._id)
  })


  const channelMoreMenuItems = computed(() => {
    const items = []


    if (manageableChannelIds.value.includes(getterChannel.value._id)) {
      items.push({
        label: t('review'),
        action () {
          router.push(`/channels/${getterChannel.value._id}/review`)
        },
      })

      items.push({
        label: t('edit'),
        action () {
          router.push(`/channels/${getterChannel.value._id}/edit`)
        },
      })
    } else {
      items.push({
        label: t('contribute'),
        action () {
          router.push(`/channels/${getterChannel.value._id}/submit`)
        },
      })
    }

    items.push({
      label: t('share'),
      async action () {
        try {
          showChannelMoreMenu.value = false
          const url = new URL(`/channels/${getterChannel.value._id}`, APP_URL)
          await navigator.clipboard.writeText(url.toString())
          Toast('link copied', 'info')
        } catch (err) {
          Toast({
            message: err.message,
          })
        }
      }
    })


    return items
  })

  watch(authenticated, () => {
    if (authenticated.value) {
      channelStore.fetchManageableChannels()
    }
  })


  onMounted(() => {
    if (authenticated.value) {
      channelStore.fetchManageableChannels()
    }
  })


  return {
    showChannelMoreMenu,
    channelMoreMenuItems,
  }
}