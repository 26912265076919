<template>
  <div v-if="circle._id">
    <header class="outline">
      <img
        :src="circle.coverUrl">

      <div class="info-container">
        <div class="title">
          <h1>{{ circle.title }}</h1>

          <!-- <pr-button
            v-if="circle.isMember && circle.author._id !== currentUser._id"
            type="primary"
            size="small">{{ $t('dm') }}</pr-button> -->
          <more-menu
            v-if="menuItems.length > 0"
            v-model="moreMenuVisible"
            :items="menuItems">
            <span
              class="touchable hoverable moreicon"
              @click.prevent.stop="moreMenuVisible = !moreMenuVisible">
              <img
                loading="lazy"
                src="@/assets/Navigation_More@2x.png">
            </span>
          </more-menu>

          <pr-button
            v-if="!circle.isMember"
            type="primary"
            size="small"
            @click="showPicker = true">{{ $t('join') }}</pr-button>
        </div>

        <tags
          :tags="tags"
          size="small"
          :lighter="true"></tags>

        <router-link
          :to="`/circles/${circle._id}/readme`"
          class="description touchable hoverable">
          <p>{{ circle.description }}</p>
          <img
            loading="lazy"
            src="@/assets/icon_page_nextpage_grey@2x.png"
            alt="introduction">
        </router-link>
      </div>
    </header>

    <router-link :to="`/users/${circle.author._id}`">
      <div class="middle hoverable">
        <img
          loading="lazy"
          :src="circle.author.avatarURL">
        <div>
          <span class="black">{{ circle.author.nickname }}
            <router-link
              v-if="circle.author.tag && circle.author.tag.memberTag != null"
              :to="`/user/${circle.author.tag.authorId}/circles`">
              <circle-badge :member-tag="circle.author.tag.memberTag"></circle-badge>
            </router-link>  
          </span>
          <span>{{ ellipseString(circle.author.motto) }}</span>
        </div>
        <span
          v-show="!circle.isMember"
          class="right">{{ priceText }}</span>
        <span
          v-show="circle.isMember"
          class="right grey">{{ $t('joined') }}</span>
      </div>
    </router-link>

    <circle-join-picker
      v-model="showPicker"
      :circle="circle"></circle-join-picker>
  </div>
</template>

<script>
import Tags from '@/components/Tags.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import { useFormattedString } from '@/composables/formatted_string'
import CircleJoinPicker from '@/components/CircleJoinPicker.vue'
import { ref } from 'vue'
import PrButton from '@/components/PRButton.vue'
import { useCurrentUser } from '@/composables/user_data'
import MoreMenu from '@/components/MoreMenu.vue'
import { useCircleMoreMenu } from '@/composables/circle_moremenu'


export default {
  props: {
    circle: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    priceText: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props) {
    const showPicker = ref(false)
    return {
      ...useFormattedString(),
      ...useCurrentUser(),
      ...useCircleMoreMenu(props.circle, showPicker),
      showPicker,
    }
  },
  components: {
    Tags,
    CircleBadge,
    CircleJoinPicker,
    PrButton,
    MoreMenu,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.outline {
  border-color: @grey;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 0.25em;
}

header {
  display: flex;
  justify-content: flex-start;
  height: 9em;
  margin-bottom: 1em;
}

header > img {
  display: block;
  width: 9em;
  height: 9em;
  min-width: 9em;
  min-height: 9em;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

header .info-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1em;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
      color: #333333;
      font-size: 26px;
      margin-right: auto;
    }

    .moreicon {
      display: flex;
      align-items: center;
      margin-right: 1em;

      img {
        width: 25px;
      }
    }
  }
}

header .description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;

  > * {
    display: block;
    color: #9b9b9b;
    font-size: 16px;
  }

  img {
    height: 16px;
    width: 16px;
  }
}

.middle {
  padding: 8px 10px;
  position: relative;
  border: 1.5px solid @border;
  margin-top: 10px;
  text-align: left;

  img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    vertical-align: -1px;
  }

  div {
    display: inline-block;
    margin-left: 0.5em;

    span {
      display: block;
      font-size: 14px;
      color: @grey;
    }
    .black {
      color: @black;
    }
  }

  .right {
    position: absolute;
    right: 10px;
    top: 20px;
    font-size: 12px;
    color: @yellow;
    border: 1px solid @yellow;
    padding: 0px 10px;
    border-radius: 11.5px;
  }
  .grey {
    color: @grey;
    border: 1px solid @grey;
  }

  p {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.34);
    font-size: 14px;
    color: #ffffff;

    span {
      display: inline-block;
      vertical-align: middle;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      float: right;
      display: block;
      height: 16px;
      width: 16px;
      margin-top: 4px;
    }
  }
}
</style>