<template>
  <div class="edit-container">
    <div style="width: 100%; max-width: 40rem;">
      <div class="title-container">
        <float-label>
          <input-text
            id="title"
            v-model="title"
            :placeholder="`${$t('artworkTitle')}`"
            type="text">
          </input-text>
          <label for="title">{{ $t('artworkTitle') }}</label>
        </float-label>

        <div class="content-container">
          <Textarea
            v-model="content"
            :auto-resize="true"
            rows="5"
            :placeholder="$t('artworkContent')"></Textarea>
        </div>
      </div>

      <a
        class="outline hoverable touchable"
        @click.prevent.stop="localeEnabled = !localeEnabled">
        {{ localeEnabled ? $t('useAutoTranslation') : $t('useManualTranslation') }}
      </a>

      <tabs
        v-show="localeEnabled"
        :value="Object.keys(titleLocales).filter(lang => lang !== defaultLocale)[0]">
        <tab-list>
          <tab
            v-for="lang in Object.keys(titleLocales).filter(lang => lang !== defaultLocale)"
            :key="lang"
            :value="lang">
            {{ lang }}
          </tab>
        </tab-list>

        <tab-panels>
          <tab-panel
            v-for="lang in Object.keys(titleLocales).filter(lang => lang !== defaultLocale)"
            :key="lang"
            :value="lang">
            <div class="title-container">
              <float-label>
                <input-text
                  :id="`${lang}-title`"
                  v-model="titleLocales[lang]"
                  :placeholder="`${lang} ${$t('artworkTitle')}`"
                  type="text">
                </input-text>
                <label :for="`${lang}-title`">{{ $t('artworkTitle') }}</label>
              </float-label>

              <div class="content-container">
                <Textarea
                  v-model="contentLocales[lang]"
                  :placeholder="`${lang} ${$t('artworkContent')}`"></Textarea>
              </div>
            </div>
          </tab-panel>
        </tab-panels>
      </tabs>

      <div>
        <text-block-editor
          v-for="(_, index) in textMedias"
          :key="index"
          v-model="textMedias[index]"
          :can-toggle-browse="priceInfo.price > 0">
        </text-block-editor>

        <pr-button
          style="width: 100%; margin-bottom: 1.5rem;"
          @click="addTextMedia">
          {{ $t('addTextBlock') }}
        </pr-button>
      </div>

      <div
        v-show="immutableArtwork && (immutableArtwork.type === 'album' || immutableArtwork.type === 'comics')"
        ref="mediasContainerRef"
        class="medias-container">
        <div
          v-for="(media, index) in medias"
          :key="media._id"
          class="media touchable hoverable"
          @click.stop="media.canBrowse = !media.canBrowse">
          <div
            v-show="!media.canBrowse && immutableArtwork.price > 0"
            class="backdrop"></div>
          <div class="overlay">
            <Button
              icon="pi pi-upload"
              class="p-button-rounded"
              @click.stop="fireFileInput(index)">
            </Button>
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click.stop="medias.splice(index, 1)">
            </Button>

            <span v-show="media.canBrowse && immutableArtwork.price > 0">{{ $t('freeCover') }}</span>
          </div>
          <img
            v-if="media.type === MediaType.IMAGE || (media.type === MediaType.VIDEO && !media.thum.includes('blob:'))"
            class="cover"
            :src="media.thum">
          <video
            v-else-if="media.type === MediaType.VIDEO && media.thum.includes('blob:')"
            class="cover"
            :src="media.thum"></video>
          <i
            v-else-if="media.type === MediaType.FILE"
            class="pi pi-file icon-cover"></i>
          <span v-if="media.type === MediaType.FILE">{{ media.filename }}</span>
          <img
            v-if="media.type === MediaType.VIDEO"
            class="video-tag"
            src="@/assets/Video.png">
        </div>
        <div
          class="media"
          @click.stop="fireFileInput(-1)">
          <i
            class="pi pi-plus"
            style="font-size: 2rem;"></i>
        </div>
      </div>

      <div>
        <pr-cell
          v-if="!isFree"
          v-show="immutableArtwork && (immutableArtwork.type === 'album' || immutableArtwork.type === 'comics')"
          :title="$t('useWatermark').toString()">
          <toggle-switch v-model="useWatermark"></toggle-switch>
        </pr-cell>

        <pr-cell
          title="R18">
          <toggle-switch v-model="isR18"></toggle-switch>
        </pr-cell>

        <pr-cell
          :title="$t('canBeCollected').toString()">
          <toggle-switch v-model="collectible"></toggle-switch>
        </pr-cell>

        <pr-cell
          :title="$t('hideStars').toString()">
          <toggle-switch v-model="hideStars"></toggle-switch>
        </pr-cell>

        <pr-cell
          v-if="immutableArtwork && immutableArtwork.price > 0">
          <prices-setter
            v-model="priceInfo">
          </prices-setter>
        </pr-cell>
      </div>

      <div class="buttons-container">
        <pr-button
          type="primary"
          :loading="updating"
          :disabled="uploading"
          @click="submit">{{ $t('edit') }}</pr-button>
      </div>
    </div>

    <input
      v-show="false"
      ref="mediaInputRef"
      type="file"
      accept="*"
      multiple
      @change="triggerFileInput">

    <watermark-positions-picker
      v-model="watermarkPositions"
      :visible="showWatermarkPositionsPicker"
      @close="showWatermarkPositionsPicker = false">
    </watermark-positions-picker>
  </div>
</template>


<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import { useArtworkEdit } from '@/composables/artwork_edit'
import ToggleSwitch from 'primevue/toggleswitch'
import WatermarkPositionsPicker from '@/components/WatermarkPositionsPicker.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import PricesSetter from '@/components/PricesSetter.vue'
import TextBlockEditor from '@/components/TextBlockEditor.vue'
import { MediaType } from '@/utils/defines'
import PrCell from '@/components/PRCell.vue'
import FloatLabel from 'primevue/floatlabel'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'


export default {
  setup () {
    return {
      ...useArtworkEdit(),
      MediaType,
    }
  },
  components: {
    PrButton,
    ToggleSwitch,
    WatermarkPositionsPicker,
    TabPanel,
    InputText,
    Textarea,
    Button,
    PricesSetter,
    TextBlockEditor,
    PrCell,
    FloatLabel,
    Tabs,
    TabList,
    Tab,
    TabPanels,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.edit-container {
  padding: 1rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

input, textarea {
  border: none;
  width: 100%;
}

textarea {
  height: fit-content;
  min-height: 8rem;
}

.title-container, .content-container, .medias-container, .buttons-container {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

button {
  width: 100%;
}

.medias-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media @phone {
    grid-template-columns: 1fr 1fr;
  }
}

.media {
  width: 100%;
  height: auto;
  min-height: 8rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  @media @phone {
    aspect-ratio: unset;
  }

  .cover {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
  }

  .icon-cover {
    width: 7rem;
    height: 7rem;
    font-size: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: @light-grey;
    top: 0;
    left: 0;
  }

  .video-tag {
    width: 4rem;
    height: 4rem;
    z-index: 1;
  }
}

.media img, .media video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 2;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem;
}

.overlay > span {
  background-color: @blue;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 54px;
  border-radius: 8px;
  position: absolute;
  left: calc(50% - 27px);
  bottom: 5px;
  color: white;
  font-weight: bolder;
}


.backdrop {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>