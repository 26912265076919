<template>
  <article class="article">
    <h1>什么是 Tron 钱包？什么是 USDT？</h1>
    <p>USDT 是一个锚定美元的数字货币，与美元兑换为 1:1，由美国泰达（Tether）公司发行。</p>
    <p>USDT 在多个公链发行，Perohub 使用 trc20 协议的 USDT 支付提现，需要使用 Tron 钱包来接收 trc20 USDT。</p>
    <p>获得 Tron 钱包，最简单的方式是通过数字货币交易所，比如币安，火币，okx，bitget 等。</p>
    <p>也可以使用自己的离线钱包，比如 imtoken，Coinbase Wallet，Bitget Wallet 等。</p>
    <h1>如何通过交易所获得 Tron 钱包？</h1>
    <p>进入交易所网站或 app 后，依次点击“资产”，“入金”，“USDT”，选择“TRC20”，部分交易所操作可能有所不同，但大同小异。</p>
    <h1>如何出售 USDT 为本国法币？</h1>
    <p>通过交易所进行 c2c 场外交易，由交易所撮合和担保，可以方便地将数字资产出售为法币。</p>
  </article>
</template>
