<template>
  <div
    class="transition-image"
    :class="classNames.join(' ')"
    :style="{
      width: styles.width,
      height: styles.height,
      pointerEvents: styles.pointerEvents,
      aspectRatio: styles.aspectRatio,
      borderRadius: styles.borderRadius,
    }">
    <skeleton
      v-show="!loaded"
      ref="loadingPlaceholder"
      :style="{
        width: styles.width,
        height: styles.height,
        objectFit: styles.objectFit,
        aspectRatio: styles.aspectRatio,
        pointerEvents: styles.pointerEvents,
      }">
    </skeleton>
    <img
      v-show="thumbnail && !displayFullImage"
      ref="thumbnailDom"
      crossorigin="anonymous"
      :src="thumbnail"
      :alt="alt"
      :style="{
        objectFit: styles.objectFit,
        aspectRatio: styles.aspectRatio,
        pointerEvents: styles.pointerEvents,
      }">
    <img
      v-show="src"
      ref="fullImageDom"
      crossorigin="anonymous"
      :style="{
        ...innerStyle,
        objectFit: styles.objectFit,
        aspectRatio: styles.aspectRatio,
        pointerEvents: styles.pointerEvents,
      }"
      :src="src"
      :alt="alt"
      :loading="thumbnail ? 'eager' : 'lazy'"
      @error="handleFullImageError">
  </div>
</template>

<script>
//@ts-check
import { until } from '@vueuse/core'
import ColorThief from 'colorthief'
import { nextTick, onMounted, ref, watch } from 'vue'
import Skeleton from 'primevue/skeleton'

// @ts-ignore
const colorthief = new ColorThief()
const errorUrl = 'https://placehold.co/100x100?text=failed'

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    classNames: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  emits: ['on-dominant-color'],
  setup (props, { emit }) {
    const displayFullImage = ref(props.thumbnail === '')
    const fullImageDom = ref(null)
    const thumbnailDom = ref(null)
    const innerStyle = ref({
      opacity: 0
    })
    const loaded = ref(false)
    const loadingPlaceholder = ref(null)
    let dominantColorTries = 0
    const maxDominantColorTries = 10
    let hookedFullDom = false
    let hookedThumbnailDom = false

    watch(fullImageDom, () => {
      hookupIfNeeded()
    })

    watch(thumbnailDom, () => {
      hookupIfNeeded()
    })

    watch(props, () => {
      hookupIfNeeded()
    })

    const hookupIfNeeded = function () {
      if (props.thumbnail && thumbnailDom.value && !hookedThumbnailDom) {
        thumbnailDom.value.onload = () => {
          innerStyle.value.opacity = 1
          loaded.value = true
        }
        hookedThumbnailDom = true
      }

      if (props.src && fullImageDom.value && !hookedFullDom) {
        fullImageDom.value.onload = () => {
          displayFullImage.value = true
          loaded.value = true

          const animate = function () {
            const value = innerStyle.value.opacity + 0.1
            innerStyle.value.opacity = Math.min(value, 1)

            if (innerStyle.value.opacity < 1) {
              return requestAnimationFrame(animate)
            } else if (props.thumbnail && props.src) {
              // both thumbnail and src provided, means it is displaying a large image
              notify(fullImageDom.value)
            }
          }

          requestAnimationFrame(animate)
        }
        hookedFullDom = true
      }
    }

    const notify = function (dom) {
      nextTick(() => {
        if (dominantColorTries >= maxDominantColorTries) {
          return console.log('get dominant colors max tries reached')
        }

        if (dom) {
          emit('on-dominant-color', colorthief.getColor(dom))                    
        } else {
          dominantColorTries += 1
          notify(dom)
        }
      })
    }

    const handleFullImageError = () => {
      nextTick(async () => {
        await until(fullImageDom).toMatch(dom => dom !== null)
        fullImageDom.value.src = errorUrl
      })
    }

    onMounted(() => {
      hookupIfNeeded()
    })

    return {
      fullImageDom,
      thumbnailDom,
      displayFullImage,
      innerStyle,
      loaded,
      loadingPlaceholder,
      handleFullImageError,
    }
  },
  components: {
    Skeleton,
  }
}
</script>

<style>
.transition-image {
  position: relative;
  overflow: hidden;
}

.transition-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}
</style>
