<template>
  <div class="pr-tabs">
    <div
      v-if="as === 'dyn-tabs' || as === 'static-tabs' || as === 'segmented-control'"
      ref="headerRef"
      class="pr-tabs-header"
      :class="{
        'pr-tabs-header-segmented': as === 'segmented-control',
        'pr-tabs-header-tabs': as === 'static-tabs' || as === 'dyn-tabs',
        fixed,
        evenly: evenly || as === 'segmented-control',
      }"
      :style="{
        top: fixed ? `${top}px` : undefined,
        padding: as === 'segmented-control' ? '0px 2px' : undefined,
        gap: as === 'segmented-control' ? '0px' : '1rem',
        width: as === 'segmented-control' ? undefined : '100%',
      }">
      <div
        v-for="i in tabCount"
        :key="i - 1"
        class="pr-tabs-header-item touchable"
        :class="{
          active: modelValue === i - 1
        }"
        @click="$emit('update:modelValue', i - 1)">
        <slot :name="`header-${i - 1}`"></slot>
      </div>
    </div>

    <div
      v-else-if="as === 'toolbar'"
      class="pr-tabs-toolbar">
      <div
        v-for="i in tabCount"
        :key="i - 1"
        class="pr-tabs-toolbar-item"
        @click="$emit('update:modelValue', i - 1)">
        <slot :name="`toolbar-${i - 1}`"></slot>
      </div>
    </div>

    <div
      v-if="as === 'dyn-tabs'"
      class="pr-tabs-content"
      :style="{
        paddingTop: fixed ? `${headerHeight + top}px` : undefined
      }">
      <slot :name="`tab-${modelValue}`"></slot>
    </div>
    <div
      v-else-if="as === 'static-tabs' || as === 'segmented-control'"
      class="pr-tabs-content"
      :style="{
        paddingTop: fixed ? `${headerHeight + top}px` : undefined
      }">
      <div
        v-for="i in tabCount"
        v-show="modelValue === i - 1"
        :key="i - 1"
        class="pr-tabs-content-item">
        <slot :name="`tab-${i - 1}`"></slot>
      </div>
    </div>
  </div>
</template>


<script>
//@ts-check
import { useElementBounding } from '@vueuse/core'
import { onMounted, onUnmounted, ref, watch } from 'vue'


export default {
  props: {
    as: {
      type: String,
      // dyn-tabs, static-tabs, segmented-control, toolbar
      default: 'dyn-tabs',
      required: true,
    },
    tabCount: {
      type: Number,
      required: true
    },
    modelValue: {
      type: Number,
      default: 0
    },
    fixed: {
      type: Boolean,
      default: false
    },
    top: {
      type: Number,
      default: 0
    },
    evenly: {
      type: Boolean,
      default: false
    },
    keepScroll: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const headerRef = ref(null)
    const { height: headerHeight } = useElementBounding(headerRef)
    const scrolls = []

    if (props.keepScroll) {
      const observeScrollY = function () {
        scrolls[props.modelValue] = window.scrollY
      }

      watch(() => props.modelValue, () => {
        window.scrollTo(0, scrolls[props.modelValue])
      })

      onMounted(() => {
        document.addEventListener('scroll', observeScrollY)
      })

      onUnmounted(() => {
        document.removeEventListener('scroll', observeScrollY)
      })
    }

    return {
      headerRef,
      headerHeight,
    }
  },
}
</script>


<style lang="less">
@import '@/assets/base.less';

.pr-tabs-header {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  box-sizing: border-box;

  &.fixed {
    position: fixed;
    z-index: 3;
  }

  &.evenly {
    justify-content: space-between;
  }
}

.pr-tabs-header-item {
  text-align: center;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.evenly .pr-tabs-header-item {
  flex: 1;
}

.pr-tabs-header-tabs {
  background: white;
  padding: 8px 0.8em;

  .pr-tabs-header-item {
    font-weight: 500;
    font-size: 14px;
    color: @minor;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0 10px;
    width: fit-content;

    &.active {
      background: @border;
      border: 1px solid @border;
      color: @minor;
    }
  }
}

.pr-tabs-header-segmented {
  background-color: #f1f1f1;

  .pr-tabs-header-item {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #cfcfcf;
    padding: 0;
    border-radius: 2px;

    &.active {
      color: #9b9b9b;
      background: white;
      height: 26px;
      line-height: 26px;
      margin-top: 2px;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.pr-tabs-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 0;
  border-top: 1px solid @border;
  backdrop-filter: blur(6px);

  .pr-tabs-toolbar-item {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}
</style>
