import { api } from './base'

export default {
  async fetchIncome(timeNodes, nodeType) {
    try {
      const res = await api.get('/users/account/chart', {
        params: {
          timeNodes,
          nodeType
        }
      })

      const data = res.data
      if (data.code === 200) {
        const labels = []
        const datasets = [{
          label: 'USD',
          data: []
        }]

        data.result.forEach(income => {
          if (nodeType === 'month' || nodeType === '2month') {
            labels.push(income.startTimeNode.substring(0, 7))
          } else {
            labels.push(income.startTimeNode)
          }

          datasets[0].data.push(income.sales)
        })

        return {
          labels: labels,
          datasets: datasets
        }
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchCoinsRecords(page, limit) {
    try {
      const res = await api.get('/users/account/detail', {
        params: {
          page,
          limit
        }
      })

      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchWithdraws(page, limit = 10) {
    try {
      const res = await api.get('/users/withdraws/list', {
        params: {
          page,
          limit
        }
      })

      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchWithdrawTotal() {
    try {
      const res = await api.get('/users/withdraws/total')
      
      const data = res.data
      if (data.code === 200) {
        return data.result.withdrawTotal
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
