//@ts-check
import { computed, onMounted, ref, watch } from "vue"
import { getSelfTags } from "@/api/tag"
import { useRoute } from "vue-router"
import { useArtworkStore } from "@/pinia/artwork"
import { useUserStore } from "@/pinia/user"
import { useToast } from "./toast"



export const useUserSelfTagList = function () {
  const tags = ref([])
  const route = useRoute()
  const userId = route.params.userId
  const username = route.params.username
  const selectedTagId = ref('')
  const tagArtworkIds = ref([])
  const artworkStore = useArtworkStore()
  const userStore = useUserStore()
  const { Toast } = useToast()


  const tagArtworks = computed(() => {
    return tagArtworkIds.value.map(artworkId => artworkStore.artworks[artworkId]).filter(artwork => artwork)
  })


  watch(selectedTagId, async () => {
    if (selectedTagId.value) {
      const tag = tags.value.find(tag => {
        return tag._id === selectedTagId.value
      })

      tag.artworkIds.forEach(artworkId => artworkStore.getArtworkById(artworkId))

      tagArtworkIds.value = tag.artworkIds
    }
  })


  const getTags = async function () {
    try {
      if (userId) {
        tags.value = await getSelfTags(userId)
      } else if (username) {
        const userId = await userStore.getUserByName(username)
        tags.value = await getSelfTags(userId)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  onMounted(() => {
    if (tags.value.length === 0) {
      getTags()
    }
  })


  return {
    tags,
    selectedTagId,
    tagArtworks,
  }
}