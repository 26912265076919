<template>
  <div class="create-discount-code-container">
    <div class="form">
      <float-label>
        <input-text
          id="code"
          v-model="code"
          type="text"
          :disabled="loading"
          :maxlength="10">
        </input-text>
        <label for="code">Code</label>
      </float-label>

      <div>
        <div>
          <div>Apply to all my artworks</div>
          <toggle-switch v-model="allMyArtworks"></toggle-switch>
        </div>
        <div v-show="!allMyArtworks">
          <span>
            {{ postIds.length }} selected
          </span>
          <pr-button @click="showPicker = true">
            {{ $t('clickToSelect') }}
          </pr-button>
        </div>
      </div>

      <float-label>
        <input-number
          id="discount"
          v-model="discount"
          :min="0"
          :max="80"
          :step="5"
          suffix="% OFF">
        </input-number>
        <label for="discount">Discount</label>
      </float-label>

      <div>
        <div>
          <div>Never Expires</div>
          <toggle-switch v-model="neverExpires"></toggle-switch>
        </div>
        <div v-show="!neverExpires">
          <div>Expires At</div>
          <Calendar
            v-model="expiresAt"
            :min-date="new Date()">
          </Calendar>
        </div>
      </div>

      <pr-button
        type="primary"
        :loading="loading"
        @click="create">
        {{ $t('create') }}
      </pr-button>

      <artwork-picker
        v-model="showPicker"
        @confirm="artworkIds => {
          showPicker = false
          postIds = artworkIds
        }">
      </artwork-picker>
    </div>
  </div>
</template>

<script>
//@ts-check
import { ref, watch } from 'vue'
import { createDiscountCode } from '@/api/discountcode'
import { useToast } from '@/composables/toast'
import InputText from 'primevue/inputtext'
import ArtworkPicker from '@/components/ArtworkPicker.vue'
import PrButton from '@/components/PRButton.vue'
import ToggleSwitch from 'primevue/toggleswitch'
import InputNumber from 'primevue/inputnumber'
import Calendar from 'primevue/calendar'
import { useRouter } from 'vue-router'
import FloatLabel from 'primevue/floatlabel'

export default {
  setup () {
    const code = ref('')
    const expiresAt = ref(null)
    const postIds = ref([])
    const loading = ref(false)
    const { Toast } = useToast()
    const showPicker = ref(false)
    const allMyArtworks = ref(true)
    const discount = ref(0)
    const neverExpires = ref(true)
    const router = useRouter()

    watch(allMyArtworks, () => {
      if (allMyArtworks.value) {
        postIds.value = []
      }
    })

    watch(neverExpires, () => {
      if (neverExpires.value) {
        expiresAt.value = null
      }
    })

    watch(code, () => {
      // only allow uppercase letters and numbers
      code.value = code.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
    })

    const create = async function () {
      try {
        loading.value = true
        const discountValue = Number((discount.value / 100).toFixed(2))
        if (discountValue > 0.8 || discountValue < 0) {
          throw new Error('Discount must be between 0 and 80')
        }
        await createDiscountCode({
          code: code.value,
          expiresAt: expiresAt.value,
          postIds: postIds.value,
          discount: discountValue,
        })
        router.push('/i/publisher/discountCodes')
      } catch (err) {
        Toast(err)
      } finally {
        loading.value = false
      }
    }

    return {
      code,
      expiresAt,
      postIds,
      create,
      showPicker,
      allMyArtworks,
      discount,
      neverExpires,
      loading,
    }
  },
  components: {
    InputText,
    ArtworkPicker,
    PrButton,
    ToggleSwitch,
    InputNumber,
    Calendar,
    FloatLabel,
  }
}
</script>

<style scoped>
.create-discount-code-container {
  padding-top: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form > * {
  width: 100%;
}

span input, .p-inputnumber {
  width: 100%;
}
</style>