<template>
  <div class="user-issues-container">
    <issue-list :issues="issues">
    </issue-list>
  </div>
</template>


<script>
//@ts-check
import { useAssigneeIssues } from '@/composables/issue_assignee'
import { useRoute } from 'vue-router'
import IssueList from '@/components/IssueList.vue'

export default {
  setup () {
    const route = useRoute()

    return {
      ...useAssigneeIssues(route.params.userId)
    }
  },
  components: {
    IssueList
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.user-issues-container {
  width: 50rem;
  margin-left: auto;
  margin-right: auto;

  @media @phone {
    width: 100%;
  }
}
</style>