//@ts-check
import { api } from './base'
//@ts-ignore
import { reusePendingPromise } from 'reuse-pending-promise'

const _fetchPosts = async (channelId, page, limit = 5) => {
  try {
    const res = await api.get(`/channels/${channelId}/mixedPosts`, {
      params: {
        page,
        limit,
      }
    })
    
    const data = res.data
    if (data.code === 200) {
      return data.result
    } else {
      throw new Error(data.message)
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchPosts = reusePendingPromise(_fetchPosts)

export const collectInto = async function (postId, channelId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/collect`, {
      postId,
    })
    
    const data = res.data
    if (data.code === 200) {
      return data.result
    } else {
      throw new Error(data.message)
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createChannel = async function ({ title, description, needVerify, royaltyRate, titleLocales, descriptionLocales }) {
  try {
    const res = await api.post('/channels/create', {
      title,
      description,
      needVerify,
      royaltyRate,
      titleLocales,
      descriptionLocales,
    })
    
    const data = res.data
    if (data.code === 200) {
      return data.result
    } else {
      throw new Error(data.message)
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateChannel = async function (channelId, doc) {
  try {
    const res = await api.post(`/channels/${channelId}/update`, doc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const contributeArtwork = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/submit`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getReviewingPosts = async function (channelId, page, limit) {
  try {
    const res = await api.get(`/channels/${channelId}/reviewPosts`, {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const approveReviewingPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/pass`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const rejectReviewingPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/reject`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const removeChannelPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/remove`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const pinChannelPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/promote`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const unpinchannelPost = async function (channelId, postId) {
  try {
    const res = await api.post(`/channels/${channelId}/post/unPromote`, {
      postId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

const _fetchMyChannels = async function () {
  try {
    const res = await api.get('/channels/admin/channels')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchMyChannels = reusePendingPromise(_fetchMyChannels)

const _fetchSubscribedChannels = async function () {
  try {
    const res = await api.get('/channels/user/channels')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const fetchSubscribedChannels = reusePendingPromise(_fetchSubscribedChannels)

export const _getChannelAlgoPosts = async function (channelId, limit = 5) {
  try {
    const res = await api.get(`/channels/${channelId}/algoPosts`, {
      params: {
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getChannelAlgoPosts = reusePendingPromise(_getChannelAlgoPosts)

export const searchChannels = async function (keyword, page, limit) {
  try {
    const res = await api.get('/channels/search/channels', {
      params: {
        keywords: keyword,
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  async fetchWeixinSubscriptions() {
    try {
      const res = await api.get('/channels/wechat/channels')
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchOne(channelId) {
    try {
      const res = await api.get(`/channels/${channelId}/channel`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else if (data.code === 204) {
        throw new Error("无数据")
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchOneByNumber(number) {
    try {
      const res = await api.get(`/channels/${number}/info`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchUsers(channelId, page, limit = 10) {
    try {
      const res = await api.get(`/channels/${channelId}/girls`, {
        params: {
          page,
          limit
        }
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchPromotedPosts(channelId) {
    try {
      const res = await api.get(`/channels/${channelId}/topPosts`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchPosts(channelId, page, limit = 5) {
    try {
      const res = await api.get(`/channels/${channelId}/mixedPosts`, {
        params: {
          page,
          limit
        }
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async updateSubscriptions(channelIds) {
    try {
      const res = await api.post(`/channels/user/updateSubscribe`, {
        channelIds
      })
      
      const data = res.data
      if (data.code === 200) {
        return
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async applyFeatured(channelId, postId) {
    try {
      const res = await api.post(`/channels/${channelId}/promoteApply`, {
        postId
      })
      
      const data = res.data
      if (data.code === 200) {
        return
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchApplicableChannels(page = 1, limit = 10) {
    try {
      const res = await api.get(`/channels/applicable/channels`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result.filter(channel => channel._id === '5873d400e57b02001cfb8399' || channel._id === '5873d50be57b02001cfb839a')
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async generateApk(channelIds) {
    try {
      const res = await api.post(`/partners/dynamicApk/generate`, {
        channelIds
      })
      
      const data = res.data
      if (data.code === 200) {
        return data
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchApkstatus() {
    try {
      const res = await api.get(`/partners/dynamicApk/status`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchApkid(partnerId) {
    try {
      const res = await api.get(`/partners/${partnerId}/detail`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchallchannels() {
    try {
      const res = await api.get(`/channels/bookable/channels?page=1`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async collectRequest(page, limit = 10) {
    try {
      const res = await api.get(`/channels/collectRequest/posts`, {
        params: {
          page,
          limit
        }
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async updateStatus(channelId, postId, status) {
    try {
      const res = await api.post(`/channels/${channelId}/post/updateStatus`, {
        postId,
        status
      })
      
      const data = res.data
      if (data.code === 200) {
        return data
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getChannelPostTime(page, limit = 5, channelId) {
    try {
      const res = await api.get(`/channels/${channelId}/timePosts`, {
        params: {
          page,
          limit
        }
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getChannelPostHot(page, limit = 5, channelId) {
    try {
      const res = await api.get(`/channels/${channelId}/heatPosts`, {
        params: {
          page,
          limit
        }
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getPromotedChannel(page, limit = 5) {
    try {
      const res = await api.get(`/channels/promoted/channels?page=${page}&limit=${limit}`)
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },
}
