<template>
  <div
    v-if="!isOnMobile"
    class="me-container">
    <ul class="left">
      <li
        v-for="(nav, index) in navs"
        :key="index"
        :class="{ active: $route.path.includes(nav.path) }"
        @click="select(index)">
        <badge
          v-if="unreadCount > 0 && nav.path.includes('/notifications')"
          :value="unreadCount > 99 ? '99+' : unreadCount">
        </badge>
        {{ nav.title }}
      </li>
    </ul>

    <div class="right">
      <router-view
        v-slot="{ Component }"
        class="view">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>

  <div
    v-else
    class="mypage-mobile-container">

    <login-cover v-if="!authenticated"></login-cover>
    
    <div v-else-if="$route.path === '/i' || $route.path === '/i/'">
      <header>
        <div class="actions">
          <div>
            <badge
              v-if="unreadCount > 0"
              :value="unreadCount > 99 ? '99+' : unreadCount"></badge>
            <img
              class="touchable"
              src="@/assets/message.png"
              @click.prevent="$router.push('/i/notifications')">
          </div>

          <div>
            <img
              v-if="currentUser.authed || allowPirate"
              class="touchable"
              src="@/assets/wallet.png"
              @click.prevent="(currentUser.canWithdraw || allowPirate) ? $router.push('/i/wallet') : $router.push('/i/publisher/verify')">
          </div>

          <div>
            <img
              class="touchable"
              src="@/assets/Setting.png"
              @click.prevent="$router.push('/i/settings')">
          </div>
        </div>
        <pr-button
          type="primary"
          @click.prevent="$router.push('/i/publisher')">
          <img src="@/assets/ic_navigation_creator.png">
        </pr-button>
      </header>

      <profile class="profile-container"></profile>
    </div>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { isOnMobile } from '@/utils'
import { useResponsive } from '@/composables/responsive'
import LoginCover from '@/components/LoginCover.vue'
import Profile from '@/pages/i/profile.vue'
import PrButton from '@/components/PRButton.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useSelfNavigation } from '@/composables/user_selfnav'
import { allowPirate } from '@/config'
import Badge from 'primevue/badge'
import { useNotificationStore } from '@/pinia/notification'
import { computed, onMounted } from 'vue'

export default {
  setup () {
    const notificationStore = useNotificationStore()

    onMounted(() => {
      notificationStore.fetchUnreadCount()
    })

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      ...useSelfNavigation(),
      allowPirate,
      unreadCount: computed(() => notificationStore.unreadCount),
    }
  },
  watch: {
    authenticated (value) {
      if (!value) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    select (index) {
      this.$router.push(this.navs[index].path)
    }
  },
  components: {
    LoginCover,
    Profile,
    PrButton,
    Badge,
  }
}
</script>

<style lang="less" scoped>
.me-container {
  text-align: center;
  margin-top: 14px;
  width: 1024px;
  margin-left: auto;
  margin-right: auto;

  .left {
    display: inline-block;
    width: 120px;
    margin-right: 40px;
    margin-left: 0px;
    vertical-align: top;

    li {
      display: block;
      height: 30px;
      width: 120px;
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: center;

      &.active {
        color: #9b9b9b;
        border-radius: 15px;
        background-color: #f1f1f1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .right {
    text-align: left;
    display: inline-block;
    width: 816px;
  }
}

.view {
  min-height: 400px;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

@media @phone {
  .mypage-mobile-container {
    padding-top: 3em;

    header {
      height: 3em;
      display: flex;
      align-items: center;
      padding: 0.5em;
      padding-top: 1em;
      padding-bottom: 1em;
      position: fixed;
      z-index: 1;
      top: 0;
      width: 100%;
      box-sizing: border-box;
      background-color: white;

      .actions {
        flex-grow: 1;
        display: flex;
        align-items: center;

        > div {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .p-badge {
            position: absolute;
            top: -0.5em;
            right: -0.5em;
          }
        }

        img {
          width: 2em;
          margin-right: 0.5em;
        }
      }

      button {
        flex-grow: 0;
        height: 1.6em;
        width: 3.4em;

        img {
          height: 1.2em;
          margin-top: 0.2em;
        }
      }
    }

    .profile-container {
      padding: 0.5em;
    }
  }
}
</style>
