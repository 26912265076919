<template>
  <div class="wrap">
    <login-cover v-if="!authenticated" />
    <router-link
      v-for="circle in my.circles"
      :key="circle._id"
      class="circle"
      :to="`/circles/${circle._id}`">
      <circle-cell-desktop
        v-if="my.circles.length>0"
        class="touchable hoverable circle"
        :circle="circle"
        @click="$router.push(`/circles/${circle._id}`)"></circle-cell-desktop>
    </router-link>
    <div
      v-if="join"
      class="empty">
      <img src="@/assets/Page_empty_guider.png">
      <p>
        暂时没有加入的应援团
      </p>
    </div>
    <load-more-button
      v-if="authenticated"
      class="a-link"
      :busy="my.busy"
      :has-more="my.hasMore"
      :trigger="loadmore"></load-more-button>
  </div>
</template>

<script>
import { getMyCircles } from '@/api/circle'
import CircleCellDesktop from '@/components/CircleCellDesktop.vue'
import LoginCover from '@/components/LoginCover.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'My Circles | Perohub',
    })

    return {
      ...useToast(),
      ...useCurrentUser()
    }
  },
  data () {
    return {
      my: {
        page: 1,
        hasMore: true,
        busy: false,
        circles: [],
      },
      limit: 30,
      join: false,
      loading: true
    }
  },
  watch: {
    authenticated () {
      this.loadmore()
    }
  },
  mounted () {
    this.loadmore()
  },
  methods: {
    getMyCircles () {
      this.my.busy = true
      this.loading = false
      getMyCircles(this.my.page, this.limit)
        .then(circles => {
          this.my.busy = false
          if (circles.length === 0) {
            this.my.hasMore = false

            if (this.my.circles.length === 0) {
              this.join = true
              //this.$router.push('/circles/all')
            }

          } else {
            circles.forEach(circle => this.my.circles.push(circle))

            this.my.page += 1
          }
        })
        .catch(err => {
          this.my.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    loadmore () {
      if(this.authenticated) {
        this.getMyCircles()
      }
    }
  },
  components: {
    CircleCellDesktop,
    LoginCover,
    LoadMoreButton
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.wrap {
  padding: 0px 0px 0px 0px;
  width: 800px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;

  .circle {
    float: left;
  }
  .a-link {
    width:100%;
    text-align: center;
    padding: 10px;
    clear: both;
  }

  .empty {
    margin: 0 auto;
    margin-top: 10%;

    img {
      width: 80px;
      height: 80px;
      margin-left: 25%;
    }
  }
  .loading {
    margin: 0 auto;
    margin-top: 10%;
    color: @blue;
    font-size: 14px;
  }
}
</style>
