<template>
  <div class="reports-container">
    <pr-tabs
      v-if="authenticated"
      v-model="selected"
      :tab-count="currentUser.authed ? 3 : 2"
      as="segmented-control"
      style="width: 96%; margin: 0 auto; margin-bottom: 10px;">
      <template #header-0>
        {{ $t('allReports') }}
      </template>
      <template #header-1>
        {{ $t('myReports') }}
      </template>
      <template
        v-if="currentUser.authed"
        #header-2>
        {{ $t('reportsToMe') }}
      </template>

      <template #tab-0>
        <all />
      </template>
      <template #tab-1>
        <mine />
      </template>
      <template #tab-2>
        <accusation />
      </template>
    </pr-tabs>
  </div>
</template>

<script>
import All from '@/components/ReportCenterAll.vue'
import Mine from '@/components/ReportCenterMine.vue'
import Accusation from '@/components/ReportCenterAccusation.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import PrTabs from '@/components/PrTabs.vue'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Report Center | Perohub',
    })

    return {
      ...useResponsive(),
      ...useCurrentUser()
    }
  },
  data () {
    return {
      selected: 0,
    }
  },
  components: {
    All,
    Mine,
    Accusation,
    PrTabs
  }
}
</script>

<style lang="less" scoped>
.reports-container {
  margin-top: 10px;
}

@media (min-width: 800px) {
  .reports-container {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
