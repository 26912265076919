//@ts-check
import { useRoute } from "vue-router"
//@ts-ignore

import postapi from "@/api/post"
import { useCurrentUser } from "./user_data"
import { computed, watch } from "vue"
import { useArtworkStore } from "@/pinia/artwork"
import { useApplication } from "@/pinia/application"
import { untrustLimit } from "@/config"
import { useToast } from "./toast"


export const useArtworkPurchase = function (postId) {
  const route = useRoute()
  const { authenticated } = useCurrentUser()
  const artworkStore = useArtworkStore()
  const application = useApplication()
  const { Toast } = useToast()

  const justPaid = computed(() => {
    if (route.query.payment_intent_client_secret || route.query.justPaid) {
      return true
    }
    return false
  })


  const artwork = computed(() => {
    return artworkStore.artworks[postId]
  })

  const nsfw = computed(() => {
    if (artwork.value) {
      return artwork.value.pictures.filter(media => media.untrust >= untrustLimit).length > 0      
    }

    return false
  })


  watch(artwork, () => {
    if (route.params.postId && justPaid.value) {
      checkPaidStatus()
    }
  })


  const buyPost = ({ postId }) => {
    if (!authenticated.value) {
      return application.showLogin()
    }
    
    const processors = []

    if (!nsfw.value) {
      // processors.push('stripe')
    }

    processors.push('order2faka', 'alipay', 'wechat')

    if (processors.length > 0) {
      application.openPaymentPicker({ postId, processors })
    } else {
      Toast({
        message: 'no available payment processors at the moment'
      })
    }
  }


  const checkPaidStatus = async function () {
    const checkPaid = async function () {
      try {
        const post = await postapi.getOne(postId)
        if (post.paid) {
          Toast({
            message: 'payment succeed',
          }, 'ok')
  
          
        } else {
          setTimeout(checkPaid, 1500)
        }
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    checkPaid()
  }


  return {
    buyPost,
    justPaid,
  }
}