import { api } from './base'
import { reusePendingPromise } from 'reuse-pending-promise'


export const createCircle = async (circleData) => {
  try {
    const res = await api.post('/groups', {
      ...circleData,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const buy = async function (circleId, currency, {
  processor,
  returnUrl,
  monthNumbers,
  subProcessor,
}) {
  try {
    const res = await api.post('/groups/buy', {
      circleId,
      currency,
      processor,
      returnUrl,
      intendMonthNumbers: monthNumbers,
      subProcessor,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getCirclePosts = async function (circleId, page = 1, limit = 5, sort = 'des') {
  try {
    const res = await api.get(`/groups/${circleId}/posts`, {
      params: {
        page,
        limit,
        sort,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


const _getCircle = async function (circleId) {
  try {
    const res = await api.get(`/groups/${circleId}/info`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getCircle = reusePendingPromise(_getCircle)


const _getChapters = async function (circleId, page) {
  try {
    const res = await api.get(`/groups/${circleId}/chapters`, {
      params: {
        page
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getChapters = reusePendingPromise(_getChapters)


export const getMonthNumbers = async function (circleId) {
  try {
    const res = await api.get(`/groups/${circleId}/month_numbers`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getActiveMonthNumbers = async function (circleId) {
  try {
    const res = await api.get(`/groups/${circleId}/active_month_numbers`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getMyCircles = async function (page, limit) {
  try {
    const res = await api.get('/groups/my', {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getAllCircles = async function (page, limit) {
  try {
    const res = await api.get('/groups/all', {
      params: {
        page,
        limit
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getCircleKinds = async function () {
  try {
    const res = await api.get('/groups/allCategory')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const addExistingPostTo = async function (postId, circleId) {
  try {
    const res = await api.post('/groups/postToGroup', {
      postId,
      groupId: circleId,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getlatestPost = async function (circleId) {
  try {
    const res = await api.get(`/groups/${circleId}/latestChapter`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getMembers = async function (circleId, page, limit = 10) {
  try {
    const res = await api.get(`/groups/${circleId}/validUsers`, {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const removePostFromCircle = async function (postId, circleId) {
  try {
    const res = await api.delete(`/groups/${circleId}/posts/${postId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const quitNextMonth = async function (circleId) {
  try {
    const res = await api.post(`/groups/${circleId}/scheduleQuit`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const completeCircle = async function (circleId) {
  try {
    const res = await api.post(`/groups/${circleId}/complete`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


const _getUserCircles = async function (userId) {
  try {
    const res = await api.get(`/users/${userId}/circles`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUserCircles = reusePendingPromise(_getUserCircles)


export const createCircleGrant = async function (circleId, userId, monthNumbers) {
  try {
    const res = await api.post(`/groups/${circleId}/grants`, {
      inviteeId: userId,
      monthNumbers,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getCircleGrants = async function (circleId) {
  try {
    const res = await api.get(`/groups/${circleId}/grants`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const cancelCircleGrant = async function (circleId, circleRightId, monthNumbers) {
  try {
    const res = await api.delete(`/groups/${circleId}/grants/${circleRightId}`, {
      data: {
        monthNumbers,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateCircle = async function (circleId, doc) {
  try {
    const res = await api.put(`/groups/${circleId}`, {
      ...doc
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const categoryGroups = async function (page, limit, category) {
  try {
    const res = await api.get(`/groups/categoryGroups`, {
      params: { page, limit, category }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getCircleMomentPosts = async function (page, limit) {
  try {
    const res = await api.get(`/groups/display/posts`, {
      params: { page, limit }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const recommendedCircles = async function (page, limit) {
  try {
    const res = await api.get(`/groups/recommended/groups`, {
      params: { page, limit }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}
