<template>
  <div
    v-if="circle"
    ref="div"
    class="container">
    <circle-header
      :circle="circle"
      :tags="tags"
      :price-text="priceText"></circle-header>

    <!--普通应援团-->
    <div v-if="circle.displayType === 'album' || circle.displayType === 'article'">
      <pr-tabs
        v-model="selected"
        :tab-count="2"
        as="segmented-control"
        style="margin-top: 0.5em;">
        <template #header-0>
          {{ $t('overview') }}
        </template>
        <template #header-1>
          {{ $t('works') }}
        </template>

        <template #tab-0>
          <section
            v-for="chapter in chapters"
            :key="chapter._id">
            <header>
              {{ chapter.groupTitle }}
            </header>

            <div class="posts">
              <router-link
                v-for="post in chapter.posts.filter(post => post.type === 'album' || post.type === 'article')"
                :key="post._id"
                :to="`/posts/${post._id}`">
                <div class="post touchable hoverable">
                  {{ getPostTitle(post) }}
                </div>
              </router-link>
              <router-link
                v-for="post in chapter.posts.filter(post => post.type === 'comics')"
                :key="post._id"
                :to="`/comics/${post._id}`">
                <div class="post touchable hoverable">
                  {{ getPostTitle(post) }}
                </div>
              </router-link>
            </div>
          </section>

          <load-more-button
            :has-more="chapterHasmore"
            :busy="loading"
            :trigger="getChapters"></load-more-button>
        </template>
        <template #tab-1>
          <div class="posts">
            <post-list :posts="posts"></post-list>
          </div>
          <load-more-button
            :busy="loading"
            :has-more="postHasmore"
            :trigger="loadMorePosts"></load-more-button>
        </template>
      </pr-tabs>
    </div>




    <div
      v-else-if="circle.displayType === 'comics'"
      class="comic">
      <p>
        <span>{{ $t('allChapters', { count: circle.postsCount }) }}</span>
        <span
          class="normal hoverable"
          :class="{ 'grey-span': sort === 'asc' }"
          @click="onSortUpdated('asc')">
          <img
            v-if="sort === 'asc'"
            src="@/assets/ic-comic-clockwise-selected@2x.png">
          <img
            v-else
            src="@/assets/ic-comic-clockwise@2x.png">
          {{ $t('normalOrder') }}
        </span>
        <span
          class="reverse hoverable"
          :class="{ 'grey-span': sort === 'des' }"
          @click="onSortUpdated('des')">
          <img
            v-if="sort === 'des'"
            src="@/assets/ic-comic-anticlockwise@2x.png">
          <img
            v-else
            src="@/assets/comic-ic-comic-anticlockwise-selected@2x.png">
          {{ $t('reversedOrder') }}
        </span>
      </p>
      <div>
        <router-link
          v-for="post in posts"
          :key="post._id"
          class="circle"
          :to="{ name: 'comic', params: { postId: post._id }}">
          <span
            class="hoverable"
            style="text-overflow: ellipsis;">{{ post.title }}</span> 
        </router-link>
      </div>
      <load-more-button
        :has-more="postHasmore"
        :busy="loading"
        :trigger="loadMorePosts"></load-more-button>
    </div>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import { useCurrentCurrency } from '@/composables/currency'
import { useCirclePresent } from '@/composables/circle_present'
import { useCurrentUser } from '@/composables/user_data'
import { useFormattedString } from '@/composables/formatted_string'
import CircleHeader from '@/components/CircleHeader.vue'
import { useResponsive } from '@/composables/responsive'
import PrTabs from '@/components/PrTabs.vue'


export default {
  setup () {
    const { currency } = useCurrentCurrency()

    return {
      currency,
      ...useCirclePresent(currency, {
        fromNetwork: true,
      }),
      ...useCurrentUser(),
      ...useFormattedString(),
      ...useResponsive(),
    }
  },
  data () {
    return {
      showPicker: false,
      selected: 0,
    }
  },
  components: {
    LoadMoreButton,
    PostList,
    CircleHeader,
    PrTabs,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

@media (min-width: 800px) {
  .report-wrap-web {
    width: 600px;
    height: 526px;
  }
}

.container {
  position: relative;
  padding-bottom: 60px;
  width: 32%;
  padding-top: 16px;
  min-width: 816px;
  margin: 0 auto;
  padding-bottom: 16px;

  .posts {
    margin-top: 16px;
  }


  > header {
    height: 320px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 360px;
    width: 100%;

    button {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 60px;
      height: 30px;
      font-size: 14px;
      background: rgba(0, 0, 0, 0);
      color: white;
      border: 1px solid white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
    }
  }

  section {
    padding: 10px;
    margin-bottom: 12px;

    header {
      font-size: 14px;
      height: 24px;
      font-weight: 500;
      color: @light-grey;
      border-bottom: 1px solid @border;
    }

    .posts {
      .post {
        display: inline-block;
        width: 32%;
        height: 30px;
        line-height: 30px;
        background: @border;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 300;
        color: @light-grey;
        padding: 0 6px;
        box-sizing: border-box;
        margin-bottom: 6px;
        vertical-align: top;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right:10px;
        min-width: 252px;
      }

      span {
        text-align: center;
        display: block;
        color: @blue;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  > footer {
    position: static;
    width: 92%;
    bottom: 0;
    background-color: @blue;
    color: white;
    height: 45px;
    line-height: 45px;
    text-align: center;
    min-width: 490px;
    margin-left: 3px;
    min-width: 800px;
    font-weight: bold;
  }

  .comic {
    p {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #cfcfcf;
      margin-top: 10px;

      span {

        img {
          width: 14px;
          height: 14px;
          position: relative;
          top: 2px;
        }
      }

      .reverse {
        float: right;
        margin-right: 16px;


      }
      .normal {
        float: right;

      }
      .grey-span {
        color: #9b9b9b;
      }
    }

    div {
      margin-top: 10px;

      span {
        display: inline-block;
        width: 31.9%;
        margin-right: 1%;
        height: 30px;
        border-radius: 4px;
        background-color: #f1f1f1;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 30px;
        color: #9b9b9b;
        padding-left: 10px;
        margin-bottom: 10px;
        font-weight: 300;
      }
    }
  }
}
</style>
