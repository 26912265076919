<template>
  <div
    v-if="isOnMobile"
    class="container">
    <div class="circles">
      <div class="kind">
        <h2>{{ title }}</h2>
      </div>

      <router-link
        v-for="circle in all.circles"
        :key="circle._id"
        :to="`/circles/${circle._id}`">
        <circle-cell
          class="touchable hoverable"
          :circle="circle"
          @click="$router.push(`/circles/${circle._id}`)"></circle-cell>
      </router-link>

      <load-more-button
        :busy="all.busy"
        :has-more="all.hasMore"
        :trigger="getAllCircles"></load-more-button>
    </div>
  </div>

  <div
    v-else
    class="wrap">
    <div class="kind">
      <h2>{{ title }}</h2>
    </div>

    <router-link
      v-for="circle in all.circles"
      :key="circle._id"
      class="circle"
      :to="`/circles/${circle._id}`">
      <circle-cell-desktop
        class="circle"
        :circle="circle"
        @click="$router.push(`/circles/${circle._id}`)"></circle-cell-desktop>
    </router-link>

    <load-more-button
      class="a-link"
      :busy="all.busy"
      :has-more="all.hasMore"
      :trigger="loadmore"></load-more-button>
  </div>
</template>

<script>
import { categoryGroups } from '@/api/circle'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import CircleCellDesktop from '@/components/CircleCellDesktop.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import CircleCell from '@/components/CircleCell.vue'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Artworks Marketplace | Perohub',
    })

    return {
      ...useCurrentUser(),
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      all: {
        page: 1,
        hasMore: true,
        busy: false,
        circles: [],
      },
      limit: 30,
      selected: 0,
      loading: true
    }
  },
  computed: {
    type () {
      if (this.$route.path.includes('comics')) {
        return 'comics'
      } else if (this.$route.path.includes('photos')) {
        return 'album'
      }

      return 'album'
    },
    title () {
      if (this.type === 'comics') {
        return this.$t('manga')
      } else if (this.type === 'album') {
        return this.$t('photo')
      }

      return this.$t('photo')
    },
  },
  mounted () {
    this.getAllCircles() 
  },
  methods: {
    getAllCircles () {
      this.all.busy = true
      this.loading = false
      categoryGroups(this.all.page, this.limit, this.type)
        .then(circles => {
          this.all.busy = false

          if (circles.length === 0) {
            this.all.hasMore = false
          } else {
            circles.forEach(circle => this.all.circles.push(circle))
            this.all.page += 1
          }
        })
        .catch(err => {
          this.all.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    loadmore () {
      this.getAllCircles()
    }
  },
  components: {
    CircleCellDesktop,
    LoadMoreButton,
    CircleCell,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.wrap {
  padding: 0px 0px 0px 0px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row wrap;
  align-content: flex-start;

  .kind {
    position: relative;
    margin-bottom: 10px;
    margin-top: 20px;
    h2 {
      font-size: 23px;
      font-weight: normal;
      color: #4a4a4a;
    }

    span {
      position: absolute;
      right: 4px;
      font-size: 14px;
      color: #cfcfcf;
      top: 3px;

      img {
        width: 15px;
        height: 15px;
        margin-left: 6px;
        position: relative;
        top: 2px;
      }
    }
  }


  .loading {
    margin: 0 auto;
    margin-top: 10%;
    color: @blue;
    font-size: 14px;
  }
  .circle {
    float: left;
  }
  .circle:hover {
    background-color: rgba(0, 0, 0, 0.01);
    opacity: 1;
  }
  .a-link {
    width:100%;
    text-align: center;
    padding: 10px;
    clear: both;

  }
}


.container {
  padding-bottom: 60px;

  .circles {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 16px;

    .circle {
      margin-bottom: 20px;
    }
  }

  .kind {
    position: relative;
    margin-bottom: 10px;
    h2 {
      font-size: 23px;
      font-weight: normal;
      color: #4a4a4a;
    }

    span {
      position: absolute;
      right: 4px;
      font-size: 14px;
      color: #cfcfcf;
      top: 3px;

      img {
        width: 15px;
        height: 15px;
        margin-left: 6px;
        position: relative;
        top: 2px;
      }
    }
  }
}
</style>
