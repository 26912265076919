<template>
  <div class="container">
    <textarea
      v-model="content"
      :placeholder="$t('setAutoreply')"
      cols="30"
      rows="10"></textarea>
    
    <hr>

    <div class="price">
      <span>{{ $t('chatPrice') }}</span>
      <prices-setter
        v-model="priceInfo"
        :show-free-button="false"></prices-setter>
    </div>

    <pr-button
      type="primary"
      @click="save">{{ $t('save') }}</pr-button>

    <pr-button
      @click="clear">{{ $t('resetChat') }}</pr-button>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import PricesSetter from '@/components/PricesSetter.vue'
import PrButton from '@/components/PRButton.vue'
import { setAutoreply, clearAutoreply } from '@/api/user'

import { useI18n } from 'vue-i18n'
import { useCurrentUser } from '@/composables/user_data'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    const { authenticated, currentUser } = useCurrentUser()
    const { t } = useI18n()
    const content = ref('')
    const priceInfo = ref({
      defaultCurrency: 'USD',
      price: 0,
      prices: {
        CNY: 0,
        JPY: 0,
        EUR: 0,
        USD: 0,
      }
    })
    const { Toast } = useToast()

    useHead({
      title: t('setAutoreply')
    })

    watch(authenticated, () => {
      if (authenticated.value) {
        initIfNeeded()
      }
    })

    const initIfNeeded = function () {
      content.value = currentUser.value.contactInfo
      const prices = currentUser.value.vipPrices
      if (prices) {
        for (const currency of Object.keys(prices)) {
          if (currency !== 'JPY') {
            const convertedPrice = Number((prices[currency] / 100).toFixed(2))
            priceInfo.value.prices[currency] = convertedPrice
          } else {
            priceInfo.value.prices[currency] = prices[currency]
          }

          if (currency === priceInfo.value.defaultCurrency) {
            priceInfo.value.price = priceInfo.value.prices[currency]
          }
        }
      }
    }

    const save = async function () {
      try {
        
        const prices = priceInfo.value.prices
        for (const currency of Object.keys(prices)) {
          if (currency !== 'JPY') {
            prices[currency] = Math.round(prices[currency] * 100)
          }
        }
        await setAutoreply(content.value, prices)
        Toast(t('success'), 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        
      }
    }

    const clear = async function () {
      
      try {
        await clearAutoreply()
        Toast(t('success'), 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        
      }
    }

    onMounted(() => {
      if (authenticated.value) {
        initIfNeeded()
      }
    })


    return {
      content,
      priceInfo,
      save,
      clear,
    }
  },
  components: {
    PricesSetter,
    PrButton,
  }
}
</script>


<style scoped lang="less">
.container {
  padding: 1em;

  textarea {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
  }

  > button {
    margin-top: 1em;
    width: 100%;
  }
}
</style>