<template>
  <div class="posts">
    <post-list :posts="posts">
    </post-list>

    <load-more-button
      v-show="posts.length > 0"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search(keyword)">
    </load-more-button>

    <pr-empty
      v-if="posts.length === 0 && !hasMore"
      :text="$t('noPostsFound')">
    </pr-empty>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { searchPosts as apiSearchPosts } from '@/api/post'

export default {
  props: {
    keyword: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      posts: [],
      hasMore: true,
      busy: false
    }
  },
  mounted () {
    if (this.keyword && this.posts.length === 0) {
      this.search(this.keyword)
    }
  },
  methods: {
    async search () {
      this.busy = true

      try {
        const posts = await apiSearchPosts(this.keyword, 5, this.page)
        if (this.page === 1) {
          this.posts = posts
        } else if (posts.length > 0) {
          this.posts = [...this.posts, ...posts]
        }

        if (posts.length === 0) {
          this.hasMore = false
        }

        this.page = this.page + 1
      } catch (err) {
        this.showToast(err)
      } finally {
        this.busy = false
      }
    }
  },
  components: {
    LoadMoreButton,
    PostList,
    PrEmpty,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}
</style>
