import { computed, onMounted, ref, watch } from 'vue'
import postapi from '@/api/post'
import { useCurrentUser } from './user_data'
import { reusePendingPromise } from 'reuse-pending-promise'
import { useArtworkStore } from '@/pinia/artwork'
import { useToast } from './toast'


const limit = 7


export const useArtworkFollowing = function () {
  let timestamp = Date.now()
  const refreshing = ref(false)
  const loading = ref(false)
  const timelineHasmore = ref(true)
  const { authenticated } = useCurrentUser()
  const postIds = ref([])
  const artworkStore = useArtworkStore()
  const { Toast } = useToast()


  const timelinePosts = computed(() => {
    return postIds.value.map(postId => artworkStore.artworks[postId])
  })


  watch(authenticated, () => {
    if (authenticated.value && timelinePosts.value.length === 0) {
      getOldTimelinePosts()
    }
  })


  const getNewTimelinePosts = async function () {
    try {
      refreshing.value = true
      const timestamp = timelinePosts.value.length > 0 ?
                        timelinePosts.value[0].createdAt :
                        Date.now()
      const posts = await postapi.getTimelinePostsSince(timestamp, limit)
      posts.forEach(post => artworkStore.cacheArtwork(post))
      postIds.value = [...posts.map(post => post._id).reverse(), ...postIds.value]
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      refreshing.value = false
    }
  }

  const _getOldTimelinePosts = async function () {
    try {
      loading.value = true
      const posts = await postapi.getTimelinePostsTill(timestamp, limit)
      if (posts.length > 0) {
        timestamp = posts[posts.length - 1].createdAt
        posts.forEach(post => artworkStore.cacheArtwork(post))
        postIds.value.push(...posts.map(post => post._id))
      } else {
        timelineHasmore.value = false
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const getOldTimelinePosts = reusePendingPromise(_getOldTimelinePosts)

  onMounted(async () => {
    if (authenticated.value && timelinePosts.value.length === 0) {
      await getOldTimelinePosts()
    }
  })


  return {
    refreshing,
    loading,
    timelinePosts,
    getNewTimelinePosts,
    getOldTimelinePosts,
    timelineHasmore,
  }
}