<template>
  <div class="container">
    <div v-show="channelshow">
      <a
        class="outline touchable"
        @click.prevent="showWhy"
      >常见拒绝原因</a>

      <div
        v-for="post in posts"
        :key="post._id"
        class="post"
        :class="{ selected: post._id === selectedPostId }"
        @click="selectedPostId = post._id"
      >
        <div class="left">
          <img
            v-show="post._id === selectedPostId"
            src="@/assets/Select_On.png"
          >
          <img
            v-show="post._id !== selectedPostId"
            src="@/assets/Select_Off.png"
          >
        </div>
        <!--<h1>{{ post.title }}</h1>
        <p>{{ post.content || '无文字内容' }}</p>-->
        <article-list-item
          :post="post"
          class="wrap-border"
        />
      </div>

      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="getPosts"
      />

      <img
        class="common-touchable contribute"
        src="@/assets/icon_post_channel_contribute.png"
        alt="contribute"
        @click="contribute"
      >
    </div>
    <div v-show="!channelshow">
      该频道暂不支持编辑推荐
    </div>
  </div>
</template>

<script>
import channel from '@/api/channel'
import api, { getUserPromotedPosts } from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue' 
import ArticleListItem from '@/components/ArticleListItem.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue'

export default {
  setup () {
    useHead({
      title: 'Apply for Channel Top | Perohub',
    })

    return {
      ...useCurrentUser()
    }
  },
  data () {
    return {
      posts: [],
      timestamp: new Date().getTime(),
      busy: false,
      page: 1,
      hasMore: true,
      channels: [],
      selectedPostId: '',
      channelshow: true
    }
  },
  computed: {
    reviewingSize () {
      let channelpotion = {}
      console.info(this.channels)
      for (let i=0; i<this.channels.length; i++) {
        if (this.$route.query.channelId == this.channels[i]._id) {
          channelpotion = this.channels[i]
        }
      }

      //console.info()
      return channelpotion.reviewQueueSize
    },
    queueSize () {
      let channelpotion = {}
      for (let i=0; i<this.channels.length; i++) {
        if (this.$route.query.channelId == this.channels[i]._id) {
          channelpotion = this.channels[i]
        }
      }
      return channelpotion.pickQueueSize
    }
  },
  watch: {
    authenticated (value) {
      if (value) {
        this.getTopPosts()
        this.getPosts()
      }
    }
  },
  mounted () {
    if (this.$route.query.channelId == '5873d400e57b02001cfb8399' || this.$route.query.channelId == '5873d50be57b02001cfb839a') {
      this.getquery()
      if (this.authenticated) {
        this.getTopPosts()
        this.getPosts()
      }
    } else {
      this.channelshow = false
    }
    
  },
  methods: {
    showWhy () {
    },
    getquery () {
      channel
        .fetchApplicableChannels()
        .then(channels => {
          
          this.channels = channels
        })
        .catch(err => {
          

          console.error(err)
        })
    },
    getTopPosts () {
      getUserPromotedPosts(this.user._id)
        .then(posts => {
          posts.reverse().forEach(post => this.posts.unshift(post))
        })
        .catch(err => {
          console.error(err)

          this.$toast({
            message: err,
            position: 'bottom'
          })
        })
    },
    getPosts () {
      this.busy = true

      const userId = this.user._id
      const timestamp = this.timestamp

      api
        .getPosts({ timestamp, limit: 10, userId }, 'old')
        .then(posts => {
          this.busy = false

          if (posts.length === 0) {
            this.hasMore = false
          } else {
            posts.forEach(post => this.posts.push(post))
            this.timestamp = posts[posts.length - 1].createdAt
          }
        })
        .catch(err => {
          console.error(err)

          this.busy = false

          this.$toast({
            message: err,
            position: 'bottom'
          })
        })
    },
    contribute () {
      this.$messagebox
        .confirm(`该频道目前有 ${this.reviewingSize} 作者正在等待审核, ${this.queueSize} 位作者正在等待推荐, 您确定要申请吗？`)
        .then(() => {
          const channelId = this.$route.query.channelId
          const postId = this.selectedPostId

          

          return channel.applyFeatured(channelId, postId)
        })
        .then(() => {
          

          this.$toast({
            position: 'bottom',
            message: '申请成功'
          })
        })
        .catch(err => {
          

          console.error(err)

          this.$toast({
            position: 'bottom',
            message: err.message ? err.message : err
          })
        })
    }
  },
  components: {
    LoadMoreButton,
    ArticleListItem,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.post {
  padding: 1em;
  padding-left: 3em;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 0px;

  .left {
    display: block;
    height: 100%;
    width: 20px;
    float: left;
    position: absolute;
    left: 1em;
    top: 1em;

    img {
      width: 20px;
    }
  }


  h1 {
    font-size: 17px;
    font-weight: 500px;
    color: @black;
  }

  p {
    font-size: 13px;
    color: @black;
  }

  &.selected {
    background: @border;
  }
}
.wrap-border {
  position: relative;
  top: -10px;
}
.contribute {
  position: fixed;
  right: 16px;
  bottom: 63px;
  z-index: 11;
  height: 64px;
  width: 64px;
}
</style>
