<template>
  <div v-if="circle">
    <header>
      <p>{{ $t('grantAccessTo', { title: circle.title }) }}</p>
      <pr-button
        style="margin-left: auto;"
        @click="popupVisible = true">
        {{ $t('grantUser') }}
      </pr-button>
    </header>

    <data-table :value="circleRights">
      <column
        header="User"
        field="user.nickname">
      </column>
      <column
        header="months">
        <template #body="slotProps">
          <div class="month-numbers">
            <chip
              v-for="monthNumber in slotProps.data.monthNumbers"
              :key="monthNumber">
              {{ getMonthNumberTitle(monthNumber) }}
            </chip>
          </div>
        </template>
      </column>
      <column
        header="action">
        <template #body="slotProps">
          <pr-button
            :loading="loading"
            @click="remove(slotProps.data._id, slotProps.data.monthNumbers)">
            {{ $t('delete') }}
          </pr-button>
        </template>
      </column>
    </data-table>
  
    <pr-popup
      v-model="popupVisible"
      class="grant-popup">
      <template #header>
        <p>{{ $t('grantUser') }}</p>
      </template>
      <div class="form">
        <div>
          <user-picker v-model="selectedUserId"></user-picker>
        </div>
        <div class="month-numbers">
          <toggle-button
            v-for="monthNumber in allMonthNumbers"
            :key="monthNumber"
            :value="monthNumbers.includes(monthNumber)"
            :on-label="getMonthNumberTitle(monthNumber)"
            :off-label="getMonthNumberTitle(monthNumber)"
            @click="toggleMonthNumber(monthNumber)">
          </toggle-button>
        </div>
      </div>
      <template #footer>
        <pr-button
          :disabled="selectedUserId === null || monthNumbers.length === 0"
          :loading="loading"
          class="grant-confirm-button"
          type="primary"
          @click="create">{{ $t('confirm') }}</pr-button>
      </template>
    </pr-popup>
  </div>
</template>


<script>
//@ts-check
import { useCircleGrant } from '@/composables/circle_grant'
import PrButton from '@/components/PRButton.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import PrPopup from '@/components/PRPopup.vue'
import ToggleButton from 'primevue/togglebutton'
import Chip from 'primevue/chip'
import UserPicker from '@/components/UserPicker.vue'
import { useCirclePresent } from '@/composables/circle_present'
import { useCurrentCurrency } from '@/composables/currency'


export default {
  setup () {
    const { currency } = useCurrentCurrency()
    return {
      ...useCirclePresent(currency, { fromNetwork: true }),
      ...useCircleGrant()
    }
  },
  components: {
    PrButton,
    DataTable,
    Column,
    PrPopup,
    ToggleButton,
    Chip,
    UserPicker,
  }
}
</script>


<style lang="less">
@import "@/assets/base.less";
.grant-popup.p-drawer {
  height: 60% !important;
}

.grant-popup {
 .p-dialog {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 30rem;

    @media @phone {
      width: 19rem;
      min-width: 100%;
    }
  }

  .p-inputtext {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.grant-confirm-button {
  margin-top: 2rem;
  width: 100% !important;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";

.month-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;

  @media @phone {
    flex-direction: column;
    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.p-chip {
  margin-right: 0.5rem;
  padding: inherit 0.5rem;
}
</style>