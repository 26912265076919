import { api } from './base'

export default {
  async fetch(channelId) {
    try {
      const res = await api.get('/bookings/bookableObjs', {
        params: {
          channelId
        }
      })

      const data = res.data
      if (data.code === 200) {
        const placements = data.result

        placements.forEach(placement => {
          placement.records = new Array(placement.maxBookingIndex + 1)
          placement.bookingRecords.forEach(record => {
            placement.records[record.bookingIndex] = record
          })
        })
        return placements
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchMultiple(channelIds) {
    try {
      const res = await api.get('/bookings/channels/bookableObjs', {
        params: {
          channelIds
        }
      })

      const data = res.data
      if (data.code === 200) {
        const placements = data.result
        placements.forEach(placement => {
          placement.records = new Array(placement.maxBookingIndex + 1)
          placement.bookingRecords.forEach(record => {
            placement.records[record.bookingIndex] = record
          })
        })
        return placements
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async book(placementId, index, price, indexOffsetMax, minprice) {
    try {
      const res = await api.post('/bookings/booking', {
        bookingObjId: placementId,
        indexExpect: index,
        maxPrice: price,
        minPrice: minprice,
        indexOffsetMax: indexOffsetMax
      })
      
      const data = res.data
      if (data.code === 200) {
        return
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async cancel(placementId) {
    try {
      const res = await api.post('/bookings/unBooking', {
        bookingObjId: placementId
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async create(channelId, { description, maxBookingIndex }, startTimestamp, endTimestamp) {
    try {
      const res = await api.post('/bookings/create', {
        channelId,
        description,
        maxBookingIndex,
        status: 'on',
        startTimestamp,
        endTimestamp
      })
      
      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchOngoing(channelId) {
    try {
      const res = await api.get('/bookings/currentChannelObj', {
        params: {
          channelId
        }
      })

      const data = res.data
      if (data.code === 200) {
        if (!data.result) {
          return data.result
        }

        const placement = data.result
        placement.records = new Array(placement.maxBookingIndex + 1)
        placement.bookingRecords.forEach(record => {
          placement.records[record.bookingIndex] = record
        })

        return placement
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
