import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from './toast'
import { searchChannels as apiSearchChannels } from '@/api/channel'

const limit = 10

export const useChannelSearch = function () {
  const channelResults = ref([])
  const keyword = ref('')
  const route = useRoute()
  const channelPage = ref(1)
  const channelHasmore = ref(false)
  const searchInputDom = ref(null)
  const { Toast } = useToast()

  watch(keyword, () => {
    channelPage.value = 1
  })

  watch(searchInputDom, () => {
    if (searchInputDom.value) {
      searchInputDom.value.focus()
    }
  })

  const searchChannels = async function () {
    if (!keyword.value) {
      return
    }

    try {
      const channels = await apiSearchChannels(keyword.value, channelPage.value, limit)
      if (channels.length === 0) {
        channelHasmore.value = false
      }
      channelResults.value.push(...channels)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  onMounted(() => {
    keyword.value = route.query.q
  })

  return {
    keyword,
    channelHasmore,
    channelResults,
    searchChannels,
    searchInputDom,
  }
}