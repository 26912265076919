<template>
  <div class="container">
    <div
      v-if="!isOnMobile"
      class="pc-navbar">
      <router-link
        to="/"
        :class="{logonone: false}"
        class="logo hoverable touchable">
        <img
          src="@/assets/sitelogo.png"
          alt="">
      </router-link>

      <input
        ref="searchInput"
        v-model="keyword"
        type="text"
        :placeholder="$t('searchbarPlaceholder').toString()"
        @keyup.enter="$router.push({ path: '/search', query: { q: keyword, type: $route.query.type } })">

      <div
        class="user-portal hoverable touchable"
        @click="authenticated ? $router.push('/i/profile') : showLogin()"
        @mouseover="mouseover"
        @mouseleave="mouseleave">
        <badge
          v-if="unreadCount > 0 && !$route.path.includes('/i/')"
          :value="unreadCount > 99 ? '99+' : unreadCount"></badge>
        <img
          v-if="authenticated"
          class="avatar"
          :src="currentUser.avatarURL">
        <img
          v-else
          style="height: 32px; width: 32px;"
          src="@/assets/avatar_placeholder.png"
          class="avatar">
        <img
          src="@/assets/icon_headimage_list.png"
          class="caret">
      </div>

      <div class="navs">
        <router-link
          to="/create"
          class="hoverable touchable create-entry">
          {{ $t('createOnPerohub') }}
        </router-link>

        <router-link
          :to="{ name: 'CircleListAll' }"
          class="nav hoverable touchable">
          <img
            v-if="route.name && route.name.includes('CircleList')"
            src="@/assets/Fans_SwitchOn.png"
            alt="">
          <img
            v-else
            src="@/assets/Fans.png"
            alt="">
        </router-link>

        <router-link
          to="/channels"
          class="nav hoverable touchable">
          <img
            v-if="route.path === '/channels'"
            src="@/assets/icon_tabs_feeds_switchon@2x.png"
            alt="">
          <img
            v-else
            src="@/assets/icon_tabs_feeds_switchoff@2x.png"
            alt="">
        </router-link>

        <router-link
          to="/explore"
          class="nav hoverable touchable">
          <img
            v-if="route.path !== '/explore'"
            src="@/assets/Discovery_disable.png"
            alt="">
          <img
            v-else
            src="@/assets/Discovery_SwitchOn.png"
            alt="">
        </router-link>

        <router-link
          to="/stars"
          class="nav hoverable touchable">
          <img
            v-if="route.path !== '/stars'"
            src="@/assets/Gallery_disable.png"
            alt="">
          <img
            v-else
            src="@/assets/Gallery_SwitchOn.png"
            alt="">
        </router-link>
      </div>
    </div>

    <div
      v-show="moreMenuVisible"
      class="more-menu"
      @mouseover="mouseover"
      @mouseleave="mouseleave">
      <div
        class="more-menu-item"
        @click="$router.push('/i/wallet')">
        {{ $t('wallet') }}
      </div>
      <div
        class="more-menu-item danger"
        @click="logoutClicked">
        {{ $t('logout') }}
      </div>
    </div>
  </div>
</template>

<script>
import { standalone } from '@/utils'
import { useResponsive } from '@/composables/responsive'
import { useApplication } from '@/pinia/application'
import { useCurrentUser } from '@/composables/user_data'
import { useAuthenticator } from '@/pinia/authenticator'
import { mapActions } from 'pinia'
import Badge from 'primevue/badge'
import { computed, onMounted, ref } from 'vue'
import { useNotificationStore } from '@/pinia/notification'
import { useFocus } from '@vueuse/core'

const filteredPaths = ['/', '/stars', '/i', '/rules']

export default {
  setup () {
    const application = useApplication()
    const notificationStore = useNotificationStore()
    const searchInput = ref(null)

    useFocus(searchInput)

    onMounted(() => {
      notificationStore.fetchUnreadCount()
    })

    return {
      showLogin: application.showLogin,
      ...useResponsive(),
      ...useCurrentUser(),
      unreadCount: computed(() => notificationStore.unreadCount),
    }
  },
  data () {
    return {
      title: document.title,
      standalone: standalone(),
      keyword: '',
      moreMenuVisible: false,
    }
  },
  computed: {
    shouldGoBack () {
      let should = true

      filteredPaths.forEach(path => {
        if (path === this.$route.path) {
          should = false
        }
      })

      return should
    },
    route () {
      return this.$route
    },
  },
  methods: {
    ...mapActions(useAuthenticator, [
      'logout',
    ]),
    
    goback () {
      this.$router.back()
    },
    mouseover () {
      if (this.authenticated) {
        this.moreMenuVisible = true
      }
    },
    mouseleave () {
      if (this.authenticated) {
        this.moreMenuVisible = false
      }
    },
    logoutClicked () {
      this.moreMenuVisible = false
      this.logout()
    }
  },
  mounted () {
    const vm = this
    const target = document.querySelector('head > title')
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        vm.title = mutation.target.textContent
      })
    })

    observer.observe(target, { subtree: true, characterData: true, childList: true })

    if (this.$route.query.q) {
      this.keyword = this.$route.query.q
    } else {
      this.keyword = ''
    }

  },
  components: {
    Badge,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  position: relative;

  .more-menu {
    display: block;
    width: 184px;
    background: white;
    border-radius: 4px;
    border: solid 0.5px #dedede;
    position: absolute;
    top: 51px;
    right: 28px;
    z-index: 5;
    text-align: left;

    .more-menu-item {
      display: block;
      height: 32px;
      font-size: 14px;
      color: @black;
      padding: 5px 11px;
      box-sizing: border-box;
      border-bottom: solid 1px #f1f1f1;

      &.danger {
        color: #ff5555;
      }

      &:hover, &:active {
        cursor: pointer;
        background: #f1f1f1;
      }
    }
  }
  .mobile-navbar {
    margin-bottom: 1em;
  }

  .pc-navbar {
    height: 52px;
    -webkit-backdrop-filter: blur(1.2px);
    backdrop-filter: blur(1.2px);
    background-color: rgba(255, 255, 255, 0.97);
    box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.13);
    padding-left: 22%;

    .logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: 78px;

      img {
        height: 18px;
      }

      span {
        color: @main;
        display: block;
        position: absolute;
        top: -6px;
        right: -16px;
      }
    }

    .logonone {
      position: relative;
      top: 10px;
    }

    input {
      margin-top: 11px;
      display: inline-block;
      width: 40%;
      height: 30px;
      border-radius: 15px;
      background: #f1f1f1 url('../assets/Sear_icon.png') no-repeat;
      background-size: 20px 20px;
      background-position: 10px center;
      border: none;
      outline: none;
      padding-left: 10px;
      font-size: 14px;
      text-indent: 25px;

      &:focus {
        background: white;
      }
    }

    .navs {
      float: right;
      display: flex;
      gap: 0.8rem;
      align-items: center;
      margin-top: 10px;

      .create-entry {
        margin-right: 1rem;
        font-weight: bolder;

        @media (max-width: 1230px) {
          display: none;
        }
      }

      > a {
        color: @warm-grey;
      }

      .nav {
        display: inline-block;
        height: 35px;
        width: 35px;

        img {
          width: 35px;
        }
      }
    }

    .user-portal {
      float: right;
      height: 52px;
      line-height: 52px;
      display: block;
      margin-right: 27px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      position: relative;

      .p-badge {
        position: absolute;
        left: -0.4rem;
        top: 0.4rem;
      }

      img {
        vertical-align: middle;
      }

      .avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-color: #d8d8d8;
        border: solid 1px #cfcfcf;
        border-radius: 16px;
        object-fit: cover;
      }

      .caret {
        width: 8px;
        margin-left: 0.4em;
      }
    }
  }
}
</style>
