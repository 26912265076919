<template>
  <div class="channels">
    <router-link
      v-for="channel in channels"
      :key="channel._id"
      :to="`/channels/${channel._id}`">

      <div class="channel touchable hoverable">
        <h3>{{ channel.title }}</h3>
        <ins v-if="channel.type !== 'official'">{{ $t('adds', { count: channel.userCount }) }}</ins>

        <pr-button
          :type="channel.isAdded ? 'default' : 'primary'"
          :disabled="channel.isAdded"
          @click.prevent.stop="subscribe(channel._id)">
          {{ channel.isAdded ? $t('added') : $t('add') }}
        </pr-button>
      </div>
    </router-link>

    <load-more-button
      v-show="channels.length > 0"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search(keyword)">
    </load-more-button>

    <pr-empty
      v-if="channels.length === 0 && !hasMore"
      :text="$t('noChannelsFound')">
    </pr-empty>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PrButton from '@/components/PRButton.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useSubscriptionUpdating } from '@/composables/channel_subscribe'
import { useToast } from '@/composables/toast'
import { searchChannels as apiSearchChannels } from '@/api/channel'


export default {
  props: {
    keyword: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      ...useSubscriptionUpdating(),
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      channels: [],
      hasMore: true,
      busy: false
    }
  },
  methods: {
    async search () {
      this.busy = true

      try {
        const channels = await apiSearchChannels(this.keyword, this.page, 5)

        if (this.page === 1) {
          this.channels = channels
        } else if (channels.length > 0) {
          channels.forEach(channel => this.channels.push(channel))
        }

        if (channels.length === 0) {
          this.hasMore = false
        }

        this.page = this.page + 1
      } catch (err) {
        this.showToast(err)
      } finally {
        this.busy = false
      }
    },
    subscribe (channelId) {
      const channel = this.channels.filter(channel => channel._id === channelId)[0]
      this.subscribeChannel(channelId, channel)
    }
  },
  mounted () {
    if (this.keyword && this.channels.length === 0) {
      this.search(this.keyword)
    }
  },
  components: {
    LoadMoreButton,
    PrButton,
    PrEmpty,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}

.channels {
  overflow: hidden;
  text-align: left;
  width: 720px;
  margin: auto;

  @media @phone {
    width: 100%;
  }
}

.channel {
  width: 345px;
  height: 62px;
  line-height: 62px;
  border-radius: 4px;
  background-color: white;
  border: solid 1px #f1f1f1;
  margin-bottom: 8px;
  float: left;
  margin-right: 10px;

  @media @phone {
    width: 100%;
  }

  h3 {
    display: inline-block;
    font-size: 17px;
    font-weight: 500;
    color: #333333;
    margin-left: 11px;
  }

  ins {
    display: inline-block;
    font-size: 10px;
    color: #cfcfcf;
    margin-left: 6px;
  }

  button {
    float: right;
    margin-top: 14px;
    margin-right: 11px;
    height: 30px;
    border-radius: 4px;
    font-size: 13px;
  }
}

a {
  clear: both;
}
</style>
