//@ts-check
import { computed, isRef, ref } from "vue"
import { useCurrentUser } from "./user_data"
import { useI18n } from 'vue-i18n'
import { useRouter } from "vue-router"
import { APP_URL } from "@/config"
import { completeCircle, quitNextMonth } from "@/api/circle"
import { useToast } from "@/composables/toast"
import { useConfirm } from "./confirm"


export const useCircleMoreMenu = function (plainOrRefCircle, showPickerRef) {
  const moreMenuVisible = ref(false)
  const circle = isRef(plainOrRefCircle) ? plainOrRefCircle : ref(plainOrRefCircle)
  const { authenticated, currentUser } = useCurrentUser()
  const { t } = useI18n()
  const router = useRouter()
  const { Toast } = useToast()
  const { confirm } = useConfirm()


  const menuItems = computed(() => {
    const items = []

    if (!circle.value) {
      return items
    }

    items.push({
      label: t('share'),
      async action () {
        await navigator.clipboard.writeText(`${APP_URL}/circles/${circle.value._id}`)
        Toast('link copied', 'info')
        moreMenuVisible.value = false
      },
    })

    if (!authenticated.value) {
      return items
    }

    items.push({
      label: t('editMemberTag'),
      action () {
        router.push(`/circles/${circle.value._id}/tag`)
      },
    })

    if (!circle.value.isMember) {
      return items
    }

    // @ts-ignore
    if (currentUser.value._id !== circle.value.author._id) {
      if (circle.value.status !== 'completed') {
        items.push({
          label: t('cancelNextMonth'),
          async action () {
            try {
              moreMenuVisible.value = false
              
              confirm({
                header: t('cancelNextMonth'),
                message: `You will lose access to ${circle.value.title} from next month on`,
                accept: async () => {
                  await quitNextMonth(circle.value._id)
                  Toast(t('success'), 'ok')
                },
              })
            } catch (err) {
              Toast({
                message: err.message,
              })
            }
          },
          isDanger: true,
        })
      }

      if (!circle.value.forceComplement) {
        items.push({
          label: t('complement'),
          action () {
            showPickerRef.value = true
          }
        }) 
      }
    } else {
      items.push({
        label: t('circleMembers'),
        action () {
          router.push(`/circles/${circle.value._id}/members`)
        }
      })

      items.push({
        label: t('edit'),
        action () {
          router.push(`/circles/${circle.value._id}/edit`)
          moreMenuVisible.value = false
        },
      })

      items.push({
        label: t('grantCircleRight'),
        action () {
          router.push(`/circles/${circle.value._id}/grant`)
        }
      })

      items.push({
        label: t('completeCircle'),
        async action () {
          try {
            moreMenuVisible.value = false

            confirm({
              header: `Mark ${circle.value.title} as Completed`,
              message: 'Only upfront purchase will be accepted',
              accept: async () => {
                await completeCircle(circle.value._id)
                Toast(t('success'), 'ok')
              },
            })
          } catch (err) {
            Toast({
              message: err.message,
            })
          }
        },
        isDanger: true,
      })
    }

    return items
  })


  return {
    menuItems,
    moreMenuVisible,
  }
}