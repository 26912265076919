<template>
  <div class="email-edit-container">
    <float-label>
      <icon-field>
        <input-icon class="pi pi-envelope"></input-icon>
        <input-text
          id="email"
          v-model="email"
          type="email">
        </input-text>
      </icon-field>
      <label for="email">{{ $t('email') }}</label>
    </float-label>

    <float-label v-if="captchaSent">
      <icon-field>
        <input-icon class="pi pi-lock"></input-icon>
        <input-text
          id="captcha"
          v-model="captcha"
          type="captcha">
        </input-text>
      </icon-field>
      <label for="captcha">{{ $t('enterCaptcha') }}</label>
    </float-label>

    <pr-button
      :loading="captchaSending"
      :disabled="time !== 60"
      @click="requestCaptcha">
      {{ captchaButtonText }}
    </pr-button>

    <pr-button
      v-if="captchaSent"
      :loading="loading"
      type="primary"
      @click="save">
      {{ $t('bind') }}
    </pr-button>
  </div>
</template>


<script>
//@ts-check
import { computed, ref } from 'vue'
import InputText from 'primevue/inputtext'
import PrButton from '@/components/PRButton.vue'
import { useI18n } from 'vue-i18n'
import { useToast } from '@/composables/toast'
import { requestEmailReplacementCaptcha, updateEmail } from '@/api/user'
import { useConfirm } from '@/composables/confirm'
import FloatLabel from 'primevue/floatlabel'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'


export default {
  setup () {
    const email = ref('')
    const captcha = ref('')
    const loading = ref(false)
    const captchaSending = ref(false)
    const time = ref(60)
    const captchaSent = ref(false)
    const { t } = useI18n()
    const { Toast } = useToast()
    const merge = ref(false)
    const { confirm } = useConfirm()

    const captchaButtonText = computed(() => {
      if (captchaSending.value) {
        return t('sending')
      }

      if (time.value === 60) {
        return t('getCaptcha')
      } else {
        return t('timeleft', { time: time.value })
      }
    })

    const countdown = () => {
      if (time.value === 0) {
        return
      }
      time.value -= 1
      setTimeout(countdown, 1000)
    }

    const requestCaptcha = async () => {
      try {
        captchaSending.value = true
        await requestEmailReplacementCaptcha(email.value)
        captchaSent.value = true
        time.value = 60
        countdown()
      } catch (err) {
        Toast(err)
      } finally {
        captchaSending.value = false
      }
    }

    const save = async () => {
      try {
        loading.value = true
        await updateEmail(email.value, captcha.value, merge.value)
        Toast({
          message: 'ok',
        }, 'ok')
      } catch (error) {
        if (error.message && error.message.includes('already')) {
          confirm({
            header: 'This email is already in use. Do you want to merge accounts?',
            message: 'If that account has usage records on Perohub, merge will not be allowed.',
            async accept () {
              merge.value = true
              await save()
            },
            reject () {
              merge.value = false
            },
            acceptLabel: 'Merge',
            rejectLabel: t('cancel'),
          })
        } else {
          console.error(error)
          Toast(error)
        }
      } finally {
        loading.value = false
      }
    }


    return {
      email,
      captcha,
      loading,
      save,
      captchaSent,
      requestCaptcha,
      captchaButtonText,
      captchaSending,
      time,
    }
  },
  components: {
    InputText,
    PrButton,
    FloatLabel,
    IconField,
    InputIcon,
  }
}
</script>


<style scoped lang="less">
@import '@/assets/base.less';

.email-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;

  @media @desktop {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

  input, > span {
    width: 100%;
  }

  button {
    width: 100%;
  }
}
</style>