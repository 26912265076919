<template>
  <div
    v-if="authenticated"
    class="container"
  >
    <div class="placements">
      <placement
        v-for="(placement, index) in placements"
        :key="index"
        :placement="placement"
        @clickPlace="clickPlace"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/placement'
import Placement from '@/components/Placement.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue';

export default {
  setup () {
    useHead({
      title: 'Book App Promotion Slots | Perohub',
    })

    return {
      ...useCurrentUser()
    }
  },
  data () {
    return {
      placements: []
    }
  },
  mounted () {
    this.fetchPlacements()
  },
  methods: {
    fetchPlacements () {
      

      api
        .fetch()
        .then(placements => {
          
          this.placements = placements

          if (this.placements.length === 0) {
            this.$toast({
              message: '无预约位开放',
              position: 'bottom'
            })
          }
        })
        .catch(err => {
          

          let message = ''

          if (err.data) {
            message = err.data.message
          } else if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.$toast({
            message,
            position: 'bottom'
          })
        })
    }
  },
  components: {
    Placement
  }
}
</script>

<style scoped>
.container {
  padding-top: 1em;
}

.container > button {
  display: block;
  margin: auto;
}
</style>
