//@ts-check
import { ref } from "vue"


export const useMediaListButtonColor = function (length = 0) {
  const buttonColors = ref([])

  const onCoverDominantColor = function (index) {
    return function ([r, g, b]) {
      const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b
      if (brightness > 128) {
        // light color, dark button
        buttonColors.value[index] = '#333333'
      } else {
        buttonColors.value[index] = '#ffffff'
      }
    }
  }

  return {
    onCoverDominantColor,
    buttonColors
  }
}

