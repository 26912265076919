//@ts-check
import { getCurrentInstance } from 'vue'
import { useToast as usePrimeToast } from 'primevue/usetoast'

const toastMessageHistory = new Map()

export const useToast = function () {
  const toast = usePrimeToast()

  const Toast = function (messageOrMessageWrapper, level = 'error') {
    let message = ''
    let title = ''
    if (typeof messageOrMessageWrapper === 'string') {
      message = messageOrMessageWrapper
    } else {
      message = messageOrMessageWrapper.message
    }

    // throttle
    if (toastMessageHistory.has(message)) {
      if (Date.now() - toastMessageHistory.get(message) < 1500) {
        return
      }
    }

    let severity = 'error'
    if (level === 'error') {
      severity = 'error'
      title = 'Error'
    } else if (level === 'info') {
      severity = 'info'
      title = 'Info'
    } else if (level === 'warn') {
      severity = 'warn'
      title = 'Warning'
    } else if (level === 'ok') {
      severity = 'success'
      title = 'Success'
    }
    // @ts-ignore
    toast.add({ severity, summary: title, detail: message, life: 5000 }) 
    toastMessageHistory.set(message, Date.now())
  }

  const showToastWithButtons = function ({
    message,
    onConfirm,
    onReject,
    rejectLabel,
    confirmLabel,
    type = 'error'
  }) {
    let title = ''
    let severity = 'error'
    if (type === 'error') {
      severity = 'error'
      title = 'Error'
    } else if (type === 'info') {
      severity = 'info'
      title = 'Info'
    } else if (type === 'warn') {
      severity = 'warn'
      title = 'Warning'
    } else if (type === 'ok') {
      severity = 'success'
      title = 'Success'
    }

    toast.add({
      // @ts-ignore
      severity,
      summary: title,
      detail: message,
      // life: 5000,
      onConfirm,
      onReject,
      rejectLabel,
      confirmLabel,
      group: 'withButtons'
    })

    return function () {
      toast.removeGroup('withButtons')
    }
  }

  return {
    Toast,
    showToast: Toast,
    showToastWithButtons,
  }
}