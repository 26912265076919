import { api } from './base'

export const starMedias = async function (picIds) {
  try {
    const res = await api.post('/medias/starMulti', {
      picIds,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getResolutions = async function (mediaId) {
  try {
    const res = await api.get(`/medias/${mediaId}/resolutions`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  async albums(page = 1, limit = 10) {
    try {
      const res = await api.get('/medias/favorite/owners', {
        params: {
          page,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async albumsDelete(pictureIds) {
    try {
      const res = await api.post('/medias/unstarMulti', {
        pictureIds
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async albumsSearch(keywords) {
    try {
      const res = await api.get('/medias/favorite/ownersSearch', {
        params: {
          keywords
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async oldImages(timestamp, ownerId) {
    try {
      const res = await api.get('/medias/favorite/old', {
        params: {
          timestamp,
          ownerId
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async newImages(timestamp, ownerId, limit = 10) {
    try {
      const res = await api.get('/medias/favorite/new', {
        params: {
          timestamp,
          ownerId,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
