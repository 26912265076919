import { api } from "./base"



export const deleteComment = async function (commentId) {
  try {
    const res = await api.delete(`/comments/${commentId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getComments = async (postId, page, limit) => {
  try {
    const res = await api.get(`/comments`, { params: { postId, page, limit } })
    return res.data.result.comments
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const postComment = async (postId, content) => {
  try {
    const res = await api.post(`/comments`, { postId, content })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getCommentReplies = async (commentId, page, limit) => {
  try {
    const res = await api.get(`/comments/reply`, { params: { commentId, page, limit } })
    return res.data.result.comments
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const postCommentReply = async (commentId, content) => {
  try {
    const res = await api.post(`/comments/reply`, { commentId, content })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}
