//@ts-check
import { ref, computed, watch, onMounted } from 'vue'
// @ts-ignore

import { createCircle } from '@/api/circle'
import { useRouter } from 'vue-router'
import { useToast } from '@/composables/toast'


export const useCircleApply = function ({
  coverUrl,
}) {
  const { Toast } = useToast()
  const title = ref('')
  const description = ref('')
  const introduction = ref('')
  const salesType = ref('year')
  const forceComplement = ref(false)
  const pricesData = ref({
    price: 0,
    defaultCurrency: 'JPY',
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    }
  })
  const upfrontPricesData = ref({
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    }
  })
  const complementPricesData = ref({
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    }
  })
  const displayType = ref('album')
  const router = useRouter()
  const presetIndex = ref(0)


  const prices = computed(() => {
    return pricesData.value.prices
  })

  const upfrontPrices = computed(() => {
    return upfrontPricesData.value.prices
  })

  const complementPrices = computed(() => {
    return complementPricesData.value.prices
  })

  const defaultCurrency = computed(() => {
    return pricesData.value.defaultCurrency
  })

  watch(presetIndex, () => {
    updatePreset()
  })


  watch(pricesData, () => {
    if (presetIndex.value === 0) {
      // fantia like
      for (const currency of Object.keys(complementPrices.value)) {
        complementPricesData.value.prices[currency] = pricesData.value.prices[currency]
      }
    }
  })


  const updatePreset = function () {
    if (presetIndex.value === 0) {
      // fantia like
      forceComplement.value = false
      salesType.value = 'long-term'
      for (const currency of Object.keys(complementPrices.value)) {
        complementPricesData.value.prices[currency] = pricesData.value.prices[currency]
      }
    } else if (presetIndex.value === 1) {
      // youtube like
      salesType.value = 'long-term'
      forceComplement.value = true
      for (const currency of Object.keys(complementPrices.value)) {
        complementPricesData.value.prices[currency] = 0
      }
    }
  }

  const submit = async function () {
    try {
      

      const submitPrices = {
        prices: {},
        upfrontPrices: {},
        complementPrices: {},
      }

      for (const [index, pricesRef] of [prices, upfrontPrices, complementPrices].entries()) {
        for (const currency of Object.keys(pricesRef.value)) {
          const value = currency === 'JPY' ? pricesRef.value[currency] : Math.round(pricesRef.value[currency] * 100)
          if (index === 0) {
            submitPrices.prices[currency] = value
          } else if (index === 1) {
            submitPrices.upfrontPrices[currency] = value
          } else if (index === 2) {
            submitPrices.complementPrices[currency] = value
          }
        }
      }

      const data = {
        coverUrl: coverUrl.value,
        salesType: salesType.value,
        title: title.value,
        description: description.value,
        introduction: introduction.value,
        defaultCurrency: defaultCurrency.value,
        displayType: displayType.value,
        forceComplement: forceComplement.value,
        ...submitPrices,
      }

      const circle = await createCircle(data)
      router.push(`/circles/${circle._id}`)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      
    }
  }

  onMounted(() => {
    updatePreset()
  })

  return {
    title,
    description,
    introduction,
    coverUrl,
    submit,
    pricesData,
    salesType,
    displayType,
    upfrontPricesData,
    complementPricesData,
    forceComplement,
    presetIndex,
  }
}