<template>
  <div class="tags">
    <div
      v-for="(tag, index) in tags"
      :key="index"
      class="tag"
      :class="{ default: size === 'default', small: size === 'small', lighter: lighter }">
      {{ tag }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      required: true,
      type: Array
    },
    size: {
      type: String,
      default: 'default'
    },
    lighter: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.7em;

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
    height: 1.85em;
    line-height: 1.85em;
    border-radius: 1.7em;
    border: 1.2px solid @blue;
    font-size: 12px;
    color: @blue;

    &.small {
      background-color: @blue;
      color: white;
      height: 18px;
      line-height: 18px;
      font-size: 10px;
      font-weight: 600;
      margin-left: 0;
      margin-right: 8px;
    }

    &.small.lighter {
      font-weight: inherit;
    }
  }
}
</style>
