<template>
  <div
    v-if="channel && channel._id"
    class="channel">

    <channel-info
      :channel-id="channel._id"></channel-info>

    <div class="container">
      <pr-tabs
        v-model="selectedTabIndex"
        :tab-count="channel.useAlgoTimeline ? 4 : 3"
        as="segmented-control">
        <template #[`header-${channel.useAlgoTimeline?0:1}`]>
          {{ $t('forYou') }}
        </template>
        <template #[`header-${channel.useAlgoTimeline?1:2}`]>
          {{ $t('latestPicks') }}
        </template>
        <template #[`header-${channel.useAlgoTimeline?2:3}`]>
          {{ $t('hot') }}
        </template>
        <template #[`header-${channel.useAlgoTimeline?3:2}`]>
          {{ $t('best') }}
        </template>

        <template #[`tab-${channel.useAlgoTimeline?0:1}`]>
          <post-list
            class="item"
            :posts="channel.algoPosts"></post-list>
          <load-more-button
            :busy="initializing || algoLoading"
            :has-more="true"
            :trigger="getAlgoPosts"></load-more-button>
        </template>

        <template #[`tab-${channel.useAlgoTimeline?1:2}`]>
          <post-list
            class="item"
            :posts="channel.latestPosts"></post-list>
          <load-more-button
            :busy="initializing || latestLoading"
            :has-more="latestHasmore"
            :trigger="getLatestPosts"></load-more-button>
        </template>

        <template #[`tab-${channel.useAlgoTimeline?2:3}`]>
          <post-list
            class="item"
            :posts="channel.hotPosts"></post-list>
          <load-more-button
            :busy="initializing || hotLoading"
            :has-more="hotHasmore"
            :trigger="getHotPosts"></load-more-button>
        </template>

        <template #[`tab-${channel.useAlgoTimeline?3:2}`]>
          <post-list
            class="item"
            :posts="channel.bestPosts"></post-list>
          <load-more-button
            :busy="initializing || bestLoading"
            :has-more="bestHasmore"
            :trigger="getBestPosts"></load-more-button>
        </template>
      </pr-tabs>
    </div>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import { useChannelPresent } from '@/composables/channel_present'
import ChannelInfo from '@/components/ChannelInfo.vue'
import PrTabs from '@/components/PrTabs.vue'


export default {
  setup () {
    return {
      ...useChannelPresent()
    }
  },
  components: {
    LoadMoreButton,
    PostList,
    ChannelInfo,
    PrTabs,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
}

.item {
  margin-left: 4px;
  margin-right: 4px;
}
</style>
