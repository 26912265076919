<template>
  <div
    class="container">
    <pr-tabs
      v-model="selected"
      :tab-count="3"
      as="segmented-control">
      <template #header-0>
        {{ $t('overview') }}
      </template>
      <template #header-1>
        {{ $t('detail') }}
      </template>
      <template #header-2>
        {{ $t('payout') }}
      </template>

      <template #tab-0>
        <chart></chart>
      </template>
      <template #tab-1>
        <sheet></sheet>
      </template>
      <template #tab-2>
        <withdraw v-if="currentUser.authed"></withdraw>
        <pr-empty
          v-else
          :text="'not authed'"></pr-empty>
      </template>
    </pr-tabs>
  </div>
</template>

<script>
import Chart from '@/components/Bill.Chart.vue'
import Sheet from '@/components/Bill.Sheet.vue'
import Withdraw from '@/components/Bill.Withdraw.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useResponsive } from '@/composables/responsive'
import PrTabs from '@/components/PrTabs.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Bills',
    })

    return {
      ...useCurrentUser(),
      ...useResponsive(),
    }
  },
  data () {
    return {
      selected: 0,
    }
  },
  components: {
    Chart,
    Sheet,
    Withdraw,
    PrTabs,
    PrEmpty,
  },
}
</script>
