<template>
  <div class="container">
    <pr-cell
      v-for="channel in channels"
      :key="channel._id"
      :title="channel.title"
      is-link
      @click="$router.push(`/promote/featured/contribute?channelId=${channel._id}`)"
    />
  </div>
</template>

<script>
import api from '@/api/channel'
import PrCell from '@/components/PRCell.vue'
import { useHead } from '@unhead/vue';

export default {
  setup () {
    useHead({
      title: 'Select Channel | Perohub',
    })
  },
  data () {
    return {
      channels: []
    }
  },
  mounted () {
    

    api
      .fetchApplicableChannels()
      .then(channels => {
        //console.info(channels)
        
        this.channels = channels
      })
      .catch(err => {
        

        console.error(err)

        this.$toast({
          message: err,
          position: 'bottom'
        })
      })
  },
  components: {
    PrCell,
  },
}
</script>

<style lang="less" scoped>
</style>
