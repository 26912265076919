<template>
  <div
    v-if="!isOnMobile"
    class="container">
    <div class="left">
      <a
        class="hoverable touchable"
        :class="{ active: timelineShow }"
        @click.prevent="timelineShow = true">
        {{ $t('following') }}
      </a>

      <div ref="channelsDomRef">
        <a
          v-for="(channel, index) in draggableChannels"
          :key="channel._id"
          :class="{
            active: index === selected && !timelineShow,
            ignore: !channel.deletable || !authenticated,
          }"
          class="touchable hoverable"
          @click.prevent="selected = index, timelineShow = false"
          @mouseover="hoveringIndex = index"
          @mouseleave="hoveringIndex = -1">

          {{ channel.title }}

          <div
            v-if="channel.deletable && authenticated"
            v-show="hoveringIndex === index"
            class="unsubscribe"
            @mouseover="hoveringInsideIndex = index"
            @mouseleave="hoveringInsideIndex = -1"
            @click.stop="unsubscribeChannel(channel._id)">
            <img
              v-show="hoveringInsideIndex !== index"
              src="@/assets/icon_page_channel_manager_remove.png"
              alt="unsubscribe">
            <img
              v-show="hoveringInsideIndex === index"
              src="@/assets/icon_page_channel_manager_remove_hover.png"
              alt="unsubscribe">
          </div>
        </a>

        <router-link
          slot="footer"
          class="ignore add block-hoverable touchable"
          to="/search?type=2">
          <img
            src="@/assets/icon_page_channel_manager_add.png"
            alt="subscribe more channels">
        </router-link>
      </div>
    </div>

    <div class="right">
      <div v-show="timelineShow">
        <login-cover v-if="!authenticated"></login-cover>

        <div
          v-else
          class="common">
          <post-list :posts="timelinePosts"></post-list>
          <load-more-button
            :busy="loading"
            :has-more="timelineHasmore"
            :trigger="getOldTimelinePosts"></load-more-button>
        </div>
      </div>

      <channel-desktop
        v-if="selectedChannel"
        v-show="!timelineShow"
        :channel-id="selectedChannel._id"></channel-desktop>
    </div>
  </div>


  <!-- mobile -->
  <div
    v-else
    class="home-container-channel">
    <div class="searchbar-wrapper">
      <search-entry
        class="searchbar-entry"
        @touchend="$router.push('/search')"
        @click.prevent="$router.push('/search')"></search-entry>

      <img
        class="modify-tag touchable"
        src="@/assets/tag_editor.png"
        @click.prevent="customize">
    </div>

    <div
      v-if="selectedChannel"
      v-show="portalBarShow"
      class="detail-portal"
      @click="$router.push(`/channels/${selectedChannel._id}`)">
      <span>
        {{ $t('channelDetail') }}
      </span>
      <img src="@/assets/Get_into_Channel@3x.png">
    </div>

    <pr-tabs
      v-model="shiftedSelected"
      as="dyn-tabs"
      :tab-count="channels.length + 1"
      :fixed="true"
      :top="48"
      :keep-scroll="true">
      <template #header-0>
        {{ $t('follow') }}
      </template>
      <template
        v-for="(channel, index) in channels"
        #[`header-${index+1}`]>
        <span :key="channel._id">
          {{ channel.title }}
        </span>
      </template>

      <template #tab-0>
        <div
          v-if="authenticated"
          style="padding-bottom: 3em;">
          <post-list
            :posts="timelinePosts">
          </post-list>

          <load-more-button
            :busy="loading"
            :has-more="timelineHasmore"
            :trigger="getOldTimelinePosts"></load-more-button>
        </div>
        <login-cover v-else></login-cover>
      </template>

      <template
        v-for="(channel, index) in channels"
        #[`tab-${index+1}`]>
        <channel
          :key="channel._id"
          :channel-id="channel._id">
        </channel>
      </template>
    </pr-tabs>
  </div>
</template>

<script>
import ChannelDesktop from '@/components/ChannelDetailDesktop.vue'
import LoginCover from '@/components/LoginCover.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import Channel from '@/components/Channel.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useSubscribedChannels } from '@/composables/channel_used'
import { useArtworkFollowing } from '@/composables/artwork_following'
import { useResponsive } from '@/composables/responsive'
import { useSubscriptionUpdating } from '@/composables/channel_subscribe'
import SearchEntry from '@/components/SearchEntry.vue'
import { useChannelPortalBar } from '@/composables/channel_portal_bar'
import { useApplication } from '@/pinia/application'
import PrTabs from '@/components/PrTabs.vue'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    const subscribedChannelsComposition = useSubscribedChannels()
    const application = useApplication()

    useHead({
      title: 'Channels | Perohub',
    })

    return {
      showLogin: application.showLogin,
      ...useCurrentUser(),
      ...subscribedChannelsComposition,
      ...useArtworkFollowing(),
      ...useResponsive(),
      ...useSubscriptionUpdating(),
      ...useChannelPortalBar(
        subscribedChannelsComposition.selectedChannel,
      ),
    }
  },
  data () {
    return {
      hoveringIndex: -1,
      hoveringInsideIndex: -1
    }
  },
  methods: {
    dragging (event) {
      if (!event.relatedContext.element) {
        return false
      }

      // 返 false 代表 cancel
      return event.draggedContext.element.deletable && event.relatedContext.element.deletable
    },
    customize () {
      if (this.authenticated) {
        return this.$router.push('/channels/edit')
      }

      return this.showLogin()
    }
  },
  components: {
    ChannelDesktop,
    LoginCover,
    LoadMoreButton,
    PostList,
    Channel,
    SearchEntry,
    PrTabs,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.dragging {
  cursor: move;
}

.container {
  text-align: center;
  /* margin-right: 10%; */
  margin-top: 12px;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;

  .left {
    display: inline-block;
    width: 120px;
    margin-right: 40px;
    margin-top: 28px;
    vertical-align: top;

    a {
      display: block;
      height: 30px;
      width: 120px;
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 10px;
      border-radius: 15px;
      position: relative;

      &.active {
        color: #9b9b9b;
        background-color: @border;
      }

      &:hover {
        background-color: @border;
      }

      .unsubscribe {
        position: absolute;
        display: inline-block;
        right: 7px;
        top: 7px;
        height: 16px;
        line-height: 16px;
        width: 16px;

        img {
          width: 16px;
          height: 16px;

          &:hover, &:active {
            cursor: pointer;
          }
        }
      }
    }

    .add {
      height: 30px;
      line-height: 30px;
      border: solid 1px @grey;
      box-sizing: border-box;

      img {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -1px;

        &:active, &:hover {
          cursor: pointer;
        }
      }

      &:active, &:hover {
        cursor: pointer;
      }
    }
  }

  .right {
    text-align: left;
    display: inline-block;
    width: 67%;
    min-width: 810px;

    .common {
      width: 32%;
      min-width: 816px;
      margin: 20px auto;
    }
  }
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.searchbar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3em;
  background-color: white;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .searchbar-entry {
    margin-top: 0.5em;
    flex-grow: 1;
  }

  .modify-tag {
    flex-shrink: 0;
    float: right;
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    margin-left: 0.3em;
    margin-bottom: -0.2em;
  }
}


@media @phone {
  .container {
    width: auto !important;
    text-align: left !important;
  }

  .home-container-channel {
    padding-left: 6px;
    padding-right: 6px;
    overflow: hidden;

    .detail-portal {
      height: 2.5em;
      background-color: @border;
      color: @warm-grey;
      display: flex;
      align-items: center;
      padding-left: 0.5em;
      padding-right: 0.5em;
      position: fixed;
      top: 5.84em;
      right: 0;
      left: 0;
      z-index: 3;

      span {
        font-size: 12px;
        font-weight: 550;
      }

      img {
        width: 16px;
        margin-left: auto;
      }
    }

    .channel {
      margin-top: 2em;
      padding-bottom: 3em;

      .download-badge {
        margin-top: 3.5em;
      }
    }
  } 
}
</style>