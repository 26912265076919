import { api } from './base'

export default {
  async getAvailableTopics(page, limit) {
    try {
      const res = await api.get('/topics/canContribute/all', {
        params: {
          page,
          limit
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async contributeToTopic(topicId, postId) {
    try {
      const res = await api.post(`/topics/${topicId}/contribute`, {
        postId
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
