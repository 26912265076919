<template>
  <drawer
    v-model:visible="visible"
    position="full"
    class="media-browser-drawer">

    <galleria
      v-model:value="medias"
      v-model:activeIndex="index"
      :num-visible="5"
      :responsive-options="[{
        breakpoint: '1024px',
        numVisible: 8,
      }, {
        breakpoint: '768px',
        numVisible: 3,
      }]">
      <template #item="slotProps">
        <transition-image
          v-if="slotProps.item.type === MediaType.IMAGE"
          :src="slotProps.item.url"
          :style="{
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            height: 'auto',
            aspectRatio: slotProps.item.width / slotProps.item.height,
          }">
        </transition-image>
        <div
          v-else-if="slotProps.item.type === MediaType.VIDEO"
          class="video-container">
          <video-player
            :media="slotProps.item"
            :artwork-id="slotProps.item.postId"
            :paid="paid"></video-player>
        </div>
      </template>

      <template #thumbnail="slotProps">
        <div
          class="item-preview hoverable touchable">
          <img
            v-if="slotProps.item.type === MediaType.VIDEO"
            style="position: absolute;"
            src="@/assets/video_tag.png">
          <img
            :src="slotProps.item.thum">
        </div>
      </template>
    </galleria>
  </drawer>
</template>

<script>
//@ts-check
import { useApplication } from '@/pinia/application'
import { computed, ref, watch } from 'vue'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { useArtworkStore } from '@/pinia/artwork'
import { MediaType } from '@/utils/defines'
import Drawer from 'primevue/drawer'
import Galleria from 'primevue/galleria'
import TransitionImage from '@/components/TransitionImage.vue'

export default {
  setup () {
    const application = useApplication()
    const index = ref(0)
    const showPreviews = ref(true)
    const showVideo = ref(false)
    const artwortStore = useArtworkStore()

    const visible = computed({
      get () {
        return application.browserVisible
      },
      set (value) {
        if (!value) {
          application.closeBrowser()
        }
      }
    })

    const paid = computed(() => {
      if (application.browserArtworkId) {
        return artwortStore.artworks[application.browserArtworkId].paid
      }

      // adapt for stars page
      return true
    })

    const browserMedias = computed(() => {
      if (Array.isArray(application.browserMedias)) {
        return application.browserMedias
      }

      return []
    })

    const medias = computed(() => browserMedias.value.filter(media => media.type === MediaType.IMAGE || media.type === MediaType.VIDEO))

    watch(visible, () => {
      if (visible.value) {
        index.value = application.browserIndex
        if (application.browserArtworkId) {
          eventEmitter.emit(events.ViewerEntered, application.browserArtworkId)
        }
      } else {
        if (application.browserArtworkId) {
          eventEmitter.emit(events.ViewerQuitOnIndex, application.browserArtworkId, index.value)            
        }
        // if we browserArtworkId to null in closeBrowser, this watcher will not execute forever
        application.$patch({ browserArtworkId: null })
      }
    })

    watch(index, () => {
      if (medias.value[index.value]) {
        const media = medias.value[index.value]
        if (media.type === MediaType.VIDEO) {
          showVideo.value = true
        } else {
          showVideo.value = false
        }
      }
    })

    return {
      visible,
      medias,
      index,
      showPreviews,
      showVideo,
      paid,
      MediaType,
    }
  },
  components: {
    Drawer,
    VideoPlayer,
    Galleria,
    TransitionImage,
  }
}
</script>

<style>
.media-browser-drawer {
  background-color: #212121 !important;
}

.media-browser-drawer .p-drawer-content {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.p-galleria {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: none !important;
}

.p-galleria-content {
  flex: 1;
}

.p-galleria-items-container {
  flex: 1;
}

.p-galleria-thumbnails-content {
  background-color: #212121 !important;
}
</style>

<style scoped>
.media-browser {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.item-preview img {
  object-fit: contain;
  max-width: 3rem;
  max-height: 3rem;
}

.item-preview {
  position: relative;
}

.video-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  min-width: 20rem;
}
</style>