//@ts-check
import { MediaType } from "@/utils/defines"
import { computed } from "vue"
import { useRoute } from "vue-router"


export const useMediaListContent = function (post) {
  const route = useRoute()
  const isVisitorMode = Number(route.query.visitor) === 1

  const medias = computed(() => {
    if (post.type === 'article') {
      return post.pictures
    }

    if (post.type === 'comics') {
      return post.pictures
    }

    const textMedias = []
    const otherMedias = []

    for (const media of post.pictures.values()) {
      if (media.type === MediaType.TEXT) {
        textMedias.push(media)
      } else {
        otherMedias.push(media)
      }
    }

    return [...textMedias, ...otherMedias]
  })

  const getIsUnlock = function (post, media) {
    if (media.canBrowse) {
      return true
    }

    return (post.price === 0 || post.paid || post.prices['USD'] === 0) && !isVisitorMode
  }

  return {
    getIsUnlock,
    medias
  }
}