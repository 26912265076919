<template>
  <div style="display: flex; flex-direction: column; gap: 1rem; width: 100%;">
    <div style="display: flex; align-items: flex-start; align-items: center; gap: 0.5rem;">
      <Checkbox
        v-model="useCode"
        :binary="true">
      </Checkbox>
      <span>{{ $t('useDiscountCode') }}</span>
    </div>
    <input-text
      v-show="useCode"
      v-model="code"
      :placeholder="$t('enterDiscountCode')">
    </input-text>
  </div>
</template>


<script>
//@ts-check
import { ref, watch } from 'vue'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const useCode = ref(false)
    const code = ref(props.modelValue)

    watch(code, () => {
      emit('update:modelValue', code.value)
    })

    watch(useCode, () => {
      if (!useCode.value) {
        code.value = ''
      }
    })

    return {
      code,
      useCode
    }
  },
  components: {
    InputText,
    Checkbox
  }
}
</script>

<style scoped>
.p-inputtext {
  width: 100%;
}
</style>
