//@ts-check
import { getReviewingPosts, approveReviewingPost, rejectReviewingPost } from '@/api/channel'
import { useArtworkStore } from '@/pinia/artwork'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from './toast'


const limit = 10

export const useChannelReview = function () {
  const postIds = ref([])
  const artworkStore = useArtworkStore()
  const loading = ref(false)
  const page = ref(1)
  const hasMore = ref(true)
  const channelId = useRoute().params.channelId
  const { Toast } = useToast()


  const artworks = computed(() => {
    return postIds.value.map(postId => artworkStore.artworks[postId])
  })


  const get = async function () {
    try {
      loading.value = true
      const posts = await getReviewingPosts(channelId, page.value, limit)
      if (posts.length === 0) {
        hasMore.value = false
      } else {
        page.value += 1
        posts.forEach(post => artworkStore.cacheArtwork(post))
        postIds.value.push(...posts.map(post => post._id))
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }


  const approve = async function (postId) {
    try {
      await approveReviewingPost(channelId, postId)
      postIds.value.splice(postIds.value.indexOf(postId))
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  const reject = async function (postId) {
    try {
      await rejectReviewingPost(channelId, postId)
      postIds.value.splice(postIds.value.indexOf(postId))
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }


  onMounted(() => {
    if (postIds.value.length === 0) {
      get()
    }
  })

  return {
    artworks,
    page,
    loading,
    hasMore,
    approve,
    reject,
    locale: navigator.language.replace('-', '_'),
    get,
  }
}