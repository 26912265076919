<template>
  <pr-popup
    v-model="loginVisible"
    class="login-modal"
    :fullscreen-on-mobile="true">
    <template #header>
      {{ showRegister ? $t('register') : $t('loginToPR') }}
    </template>

    <div v-show="showRegister">
      <div>
        <Button
          v-if="!showVerfication"
          icon="pi pi-chevron-left"
          class="p-button-rounded p-button-text"
          @click="showRegister = false">
        </Button>
        <Button
          v-else
          icon="pi pi-chevron-left"
          class="p-button-rounded p-button-text"
          @click="showVerfication = false">
        </Button>

        <div
          v-show="!showVerfication"
          class="form-container">
          <float-label>
            <icon-field>
              <input-icon class="pi pi-envelope">
              </input-icon>
              <input-text
                id="new-email"
                v-model="newEmail"
                type="email"></input-text>
            </icon-field>
            <label for="new-email">{{ $t('email') }}</label>
          </float-label>

          <float-label>
            <icon-field>
              <input-icon class="pi pi-lock"></input-icon>
              <input-text
                id="new-password"
                v-model="newPassword"
                type="password"></input-text>
            </icon-field>
            <label for="new-password">{{ $t('password') }}</label>
          </float-label>

          <pr-button
            type="primary"
            size="large"
            :disabled="time !== 60"
            :loading="registerLoading"
            @click="register">
            {{ captchaButtonText }}
          </pr-button>
        </div>

        <div
          v-show="showVerfication"
          class="form-container">
          <float-label>
            <icon-field>
              <input-icon class="pi pi-lock"></input-icon>
              <input-text
                id="code"
                v-model="emailCode"
                type="text"></input-text>
            </icon-field>
            <label for="code">{{ $t('enterCaptcha') }}</label>
          </float-label>

          <pr-button
            type="primary"
            size="large"
            :loading="loginLoading"
            @click="loginEmail">
            {{ $t('login') }}
          </pr-button>
        </div>
      </div>
    </div>

    <tabs
      v-show="!showRegister"
      v-model:value="activeTab">
      <tab-list>
        <tab value="email">Email</tab>
        <tab value="phone">Phone Number</tab>
      </tab-list>

      <tab-panels>
        <tab-panel value="email">
          <div class="form-container">
            <float-label>
              <icon-field>
                <input-icon class="pi pi-envelope"></input-icon>
                <input-text
                  id="email"
                  v-model="email"
                  type="email"></input-text>
              </icon-field>
              <label for="email">{{ $t('email') }}</label>
            </float-label>

            <float-label>
              <icon-field>
                <input-icon class="pi pi-lock"></input-icon>
                <input-text
                  id="password"
                  v-model="password"
                  type="password"></input-text>
              </icon-field>
              <label for="password">{{ $t('password') }}</label>
            </float-label>

            <div style="display: flex; justify-content: flex-end;">
              <Button
                class="p-button-link"
                @click="showRegister = true">
                {{ $t('register') }}
              </Button>
            </div>

            <pr-button
              type="primary"
              size="large"
              :loading="loginLoading"
              @click="loginPassword">
              {{ $t('login') }}
            </pr-button>
          </div>
        </tab-panel>

        <tab-panel value="phone">
          <div class="form">
            <div class="phone-filed">
              <intl-tel-input
                v-model="phone"
                :country-code="countryCode ? countryCode.toLowerCase() : 'us'"
                :to-front="regionCodes"></intl-tel-input>
              <span
                :class="{ touchable: time === 60, hoverable: time === 60 }"
                @click="send">{{ buttonTitle }}</span>
            </div>

            <input-otp
              v-model="captcha"
              :length="6"
              size="small"
              style="margin: 1rem auto;"></input-otp>

            <p>{{ $t('loginAutoCreate') }}</p>

            <pr-button
              type="primary"
              size="large"
              style="width: 100%;"
              :loading="logining"
              @click="login">
              {{ $t('login') }}
            </pr-button>
          </div>
        </tab-panel>
      </tab-panels>
    </tabs>
  </pr-popup>
</template>


<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import IntlTelInput from '@/components/IntlTelInput.vue'
import { regionCodes } from '@/config'
import { useResponsive } from '@/composables/responsive'
import { useApplication } from '@/pinia/application'
import { computed, ref } from 'vue'
import { useCurrentCountry } from '@/composables/country'
import PrPopup from '@/components/PRPopup.vue'
import TabPanel from 'primevue/tabpanel'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { useRegisterEmail } from '@/composables/user_register_email'
import { useLoginEmail } from '@/composables/user_login_email'
import { useLoginPassword } from '@/composables/user_login_password'
import { useRegisterPhone } from '@/composables/user_register_phone'
import FloatLabel from 'primevue/floatlabel'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputOtp from 'primevue/inputotp'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'


export default {
  setup () {
    const application = useApplication()
    const activeTab = ref('email')
    const showRegister = ref(false)

    const loginVisible = computed({
      get: () => application.loginVisible,
      set: (value) => {
        application.updateLoginVisible(value)
      }
    })

    const registerComposition = useRegisterEmail()

    return {
      regionCodes,
      ...useResponsive(),
      loginVisible,
      ...useCurrentCountry(),
      activeTab,
      showRegister,
      ...registerComposition,
      ...useLoginEmail(registerComposition.newEmail),
      ...useLoginPassword(),
      ...useRegisterPhone(),
    }
  },
  components: {
    PrButton,
    IntlTelInput,
    PrPopup,
    TabPanel,
    InputText,
    Button,
    FloatLabel,
    InputIcon,
    IconField,
    InputOtp,
    Tabs,
    TabList,
    Tab,
    TabPanels,
  }
}
</script>

<style>
.login-modal.p-dialog {
  width: 22rem;
  height: 30rem;
}
</style>

<style scoped lang="less">
@import '@/assets/base.less';

.form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;

  input {
    width: 100%;
  }
}

.form {
  margin: 54px auto;
  width: 100%;
  max-width: 420px;

  button {
    height: 43px;
    margin: 40px auto;
    border-radius: 4px;
  }

  > p {
    font-size: 0.8rem;
    color: @minor;
  }
  
}

.phone-filed {
  position: relative;

  span {
    color: @blue;
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;

    &:not(.touchable) {
      color: @light-grey;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
