<template>
  <div
    ref="scroll"
    class="scroll">
    <router-link
      v-for="user in users"
      :key="user._id"
      class="user block-hoverable touchable"
      :to="`/users/${user._id}`">
      <user-avatar
        class="user-avatar"
        :user="user"
        :disabled="true" />
      <h6>{{ user.nickname }}</h6>

      <pr-button
        :type="user.followed ? 'default' : 'primary'"
        :flat="user.followed"
        :disabled="disabled"
        class="hoverable"
        @click.prevent.stop="user.followed ? unfollow(user._id, user) : follow(user._id, user)">
        {{ user.followed ? $t('followed') : $t('follow') }}
      </pr-button>
    </router-link>
  </div>
</template>

<script>
import UserAvatar from './UserAvatar.vue'
import PrButton from './PRButton.vue'
import { onMounted, ref } from 'vue'
import { useResponsive } from '@/composables/responsive'
import { useUserFollow } from '@/composables/user_follow'


export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  setup () {
    const scroll = ref(null)
    const disabled = ref(false)
    const { isOnMobile } = useResponsive()

    onMounted(() => {
      if (isOnMobile.value) {
        scroll.value.addEventListener('wheel', e => {
          const delta = e.deltaY
          scroll.value.scrollLeft += delta
          e.preventDefault()
        })
      }
    })

    return {
      disabled,
      scroll,
      ...useUserFollow(),
    }
  },
  components: {
    UserAvatar,
    PrButton
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.scroll::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.scroll {
  text-align: center;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 9px;

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 109px;
    height: 146px;
    border-radius: 4px;
    border: 1px solid @border;
    position: relative;
    padding: 1rem;

    h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      font-weight: 500;
      color: @black;
      max-width: 100%;
    }

    .user-avatar {
      width: 64px;
      height: 64px;
      object-fit: cover;
      border-radius: 32px;
    }

    button {
      width: 88px;
      height: 25px;
      border-radius: 2px;
      font-size: 13px;
      margin-top: 10px;
    }
  }
}
</style>