<template>
  <div class="container">
    <p>在每个频道的「频道详情」中即可给该频道投稿。</p>
    <p>您不会才知道吧！</p>
  </div>
</template>

<script>
import { useHead } from '@unhead/vue';

export default {
  setup () {
    useHead({
      title: 'Submit Artwork | Perohub',
    })
  }
}
</script>

<style scoped>
.container {
  padding: 0.6em;
}
</style>
