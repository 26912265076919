//@ts-check
import { useCurrentCurrency } from './currency'
import { computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useApplication } from '@/pinia/application'
import { useToast } from './toast'
import { useConfirm } from './confirm'


export const useUserContact = function (targetUser) {
  const { currency } = useCurrentCurrency()
  const route = useRoute()
  const { t } = useI18n()
  const { openPaymentPicker } = useApplication()
  const { Toast } = useToast()
  const { confirm } = useConfirm()

  const vipPrice = computed(() => {
    if (currency.value === 'JPY') {
      return targetUser.vipPrices[currency.value]
    }

    return Number((targetUser.vipPrices[currency.value] / 100).toFixed(2))
  })


  watch(targetUser, () => {
    showContactIfNeeded()
  })


  const intentContactBuy = function () {
    openPaymentPicker({
      userId: targetUser._id,
      // processors: ['stripe'],
      processors: ['order2faka', 'alipay', 'wechat']
    })
  }

  const showContactIfNeeded = function () {
    if (route.query.showContact && targetUser._id) {
      if (targetUser.vip) {
        confirm({
          header: t('aisatuTokenTitle', {
            code: targetUser.vipCode
          }),
          message: targetUser.contactInfo,
          acceptLabel: t('confirm'),
        })
      }
    }
  }

  const buyContactClicked = async function () {
    try {
      if (targetUser.vip) {
        confirm({
          header: t('aisatuTokenTitle', {
            code: targetUser.vipCode
          }),
          message: targetUser.contactInfo,
          acceptLabel: t('confirm'),
        })
      } else {
        confirm({
          message: t('aisatuUnlockText', {
            price: `${currency.value} ${vipPrice.value}`,
            nickname: targetUser.nickname
          }),
          accept: () => {
            intentContactBuy()
          }
        })
      }
    } catch (err) {
      if (err !== 'cancel') {
        Toast({
          message: err.message,
        }) 
      }
    }
  }

  onMounted(() => {
    showContactIfNeeded()
  })


  return {
    buyContactClicked,
  }
}