<template>
  <div class="container">
    <div
      v-for="(post, index) in collect.collects"
      :key="post._id"
      class="wrap">
      <div class="author">
        Collect request from
        <span
          class="blue hoverable touchable"
          @click="$router.push(`/channels/${post.channel.channelId}`)">
          #{{ post.channel.title }}
        </span>
        channel
        <span class="time">{{ post.channel.time }}</span>
      </div>

      <article-list-item
        :post="post.post"
        class="wrap-border">
      </article-list-item>

      <p class="propotion">
        This channel takes
        <span>{{ Math.round(post.channel.royaltyRate * 100) }}%</span>
        from the sales in channel
      </p>

      <div class="bottom">
        <pr-button
          class="pass"
          @click="update(post,'passed',index)">
          <img src="@/assets/Audit_Pass.png">
          {{ $t('approve') }}
        </pr-button>
        <pr-button
          class="cancel"
          @click="update(post,'never',index)">
          <img
            class=""
            src="@/assets/Audit_Refuse.png">
          {{ $t('reject') }}
        </pr-button>
      </div>
    </div>

    <load-more-button
      :busy="collect.busy"
      :has-more="collect.hasMore"
      :trigger="collectRequest">
    </load-more-button>
  </div>
</template>

<script>
import ArticleListItem from '@/components/ArticleListItem.vue'
import PrButton from '@/components/PRButton.vue'
import api from '@/api/channel'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import Time from '@/utils/time'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Artwork Collect Request | Perohub',
    })

    return {
      ...useCurrentUser(),
      ...useToast(),
    }
  },
  data () {
    return {
      collect: {
        page: 1,
        hasMore: true,
        busy: false,
        collects: [],
      },
    }
  },
  mounted () {
    this.collectRequest()
  },
  methods: {
    update (post, flag, num) {
     
      api
        .updateStatus(post.channel.channelId, post.post._id, flag)
        .then(data => {

          this.collect.collects.splice(num, 1)

          this.showToast({
            message: data.message,
          }, 'info')
        })
        .catch(err => {
          this.collect.busy = false

          console.error(err)

          this.showToast({
            message: err.response.data.message ? err.response.data.message : err,
          })
        })
    },
    getTime (time) {
      return Time.timestamptoyear(time)
    },
    collectRequest () {
      this.collect.busy = true

      api
        .collectRequest(this.collect.page, 10)
        .then(request => {
          this.collect.busy = false

          if (request.length === 0) {
            this.collect.hasMore = false
          } else {
            this.collect.hasMore = true
            request.forEach(chapter => {
              chapter.channel.time = this.getTime(chapter.channel.createdAt)
              this.collect.collects.push(chapter)

            })
            this.collect.page += 1
          }

        })
        .catch(err => {
          this.collect.busy = false

          console.error(err)

          this.showToast({
            message: err.response.data.message ? err.response.data.message : err,
          })
        })
    },
  },
  components: {
    ArticleListItem,
    PrButton,
    LoadMoreButton
  }
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.container {
  background-color: #fafafa;
  min-height: 100%;
  padding-top: 10px;
  margin-bottom: 0px !important;
  padding-bottom: 60px;

  .wrap {
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-top: 6px;
    margin-bottom: 10px;

    .author {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: justify;
      color: #9b9b9b;
      position: relative;
      line-height: 2.2;

      .time {
        position: absolute;
        right: 0px;
      }

      .blue {
        color: #55caff;
      }
    }

    .wrap-border {
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
    }

    .propotion {
      font-size: 17px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
      line-height: 2.4;
      border-bottom: 1px solid #f1f1f1;

      span {
        font-weight: 800;
      }
    }

    .bottom {
      margin-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      
      button {
        border-radius: 4px;
        background-color: #3cd480;
        border: solid 1px #3cd480;
        width: 46%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        outline: none;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        object-fit: cover; 
      }
      .pass {
        margin-right: 4%;
      }
      .cancel {
        background-color: #f1f1f1;
        color: #9b9b9b;
        border: solid 1px #f1f1f1;

        img {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}


</style>
