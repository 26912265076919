//@ts-check
import { computed, onMounted, ref, watch } from "vue"
import { useArtworkStore } from "@/pinia/artwork"
import { useRoute } from "vue-router"
import { locale } from "@/translations"
import { getPostTranslation } from "@/api/post"
import { useToast } from "./toast"


export const useArtworkText = function (artworkId) {
  const artworkStore = useArtworkStore()
  const currentLocale = ref(locale)
  const { Toast } = useToast()
  const route = useRoute()

  if (!artworkId) {
    artworkId = route.params.postId
  }


  const artworkLocales = computed(() => {
    if (!artwork.value) {
      return []
    }

    if (!artwork.value.contentLocales) {
      return []
    }

    return Object.keys(artwork.value.contentLocales).filter(locale => artwork.value.contentLocales[locale]).sort()
  })

  const artwork = computed(() => {
    return artworkStore.artworks[artworkId]
  })

  const artworkTextContent = computed(() => {
    if (!artwork.value) {
      return ''
    }

    return linkify(artwork.value.content)
  })

  const localizedContents = computed(() => {
    if (!artwork.value) {
      return {}
    }

    const contents = {}

    if (!artwork.value.contentLocales) {
      return contents
    }

    for (const locale of Object.keys(artwork.value.contentLocales)) {
      contents[locale] = linkify(artwork.value.contentLocales[locale])
    }

    return contents
  })

  watch(artwork, async () => {
    if (artwork.value && !artwork.value.contentLocales) {
      await attachTranslations(artwork.value._id)
    }
  })

  const attachTranslations = async function (artworkId) {
    try {
      const translations = await getPostTranslation(artworkId)
      artworkStore.attachTranslations(artworkId, translations)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const linkify = function (content) {
    if (!artwork) {
      return ''
    }

    content = content.replace(/\r\n/g, '<br/>')

    if (artwork.value.links.length === 0) {
      return content
    }

    const links = artwork.value.links
  
    if (links.length > 0) {
      for (let i = 0; i < links.length; i++ ) {
        //contentlink = content.replace('a', 'o');
        if ( links[i].type == "web") {
          content = content.replace(links[i].textMatch, `<a href="${links[i].options.url}">${links[i].textMatch}</a>`)
        } else if ( links[i].type == "user") {
          content = content.replace(links[i].textMatch, `<a class="userlink" url="${links[i].options.userId}">${links[i].textMatch}</a>`)
        } else if ( links[i].type == "channel") {
          content = content.replace(links[i].textMatch, `<a class="channellink" url="${links[i].options.channelId}">${links[i].textMatch}</a>`)
        } 
      }

    }

    const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
    const arr = content.match(reg)
    if (arr != null) {
      for (let i = 0; i < arr.length; i++) {
        content = content.replace(arr[i], `<a class="channellink" url="${arr[i]}">${arr[i]}</a>`)
      }
    }

    return content
  }

  onMounted(() => {
    if (route.path.includes('/posts/')) {
      attachTranslations(route.params.postId)
    }
  })

  return {
    artworkLocales,
    localizedContents,
    artworkTextContent,
    currentLocale,
  }
}