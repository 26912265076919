<template>
  <div
    v-if="post"
    class="item-container touchable"
    :class="{
      gray: post.fake,
      hoverable: !isOnMobile,
    }"
    @click.prevent.stop="$router.push(post.type === 'comics' ? `/comics/${post._id}` : `/posts/${post._id}`)">
    <post-header
      :post="post"
      :nickname="nickname"
      :created-at="createdAt"
      :price="String(price)"
      :price-text="priceText">
    </post-header>

    <img
      v-if="post.fake"
      class="fake-badge"
      src="@/assets/fake_original.png"
      alt="fake">

    <div class="item-content">
      <p class="item-content-text">
        {{ outofservice ? $t('outofservice', { countryCode }) : localeTitle }}
      </p>
    </div>


    <more-menu
      v-model="moreMenuVisible"
      :items="menuItems">
      <span
        v-if="isOnMobile"
        class="more touchable hoverable"
        @click.prevent.stop="moreMenuVisible = !moreMenuVisible">
        <img src="@/assets/More_icon.png">
      </span>
      <span
        v-else
        class="more touchable hoverable"
        @click.prevent.stop="moreMenuVisible = !moreMenuVisible"
        @mouseover="moreMenuVisible = true"
        @mouseleave="moreMenuVisible = false">
        <img src="@/assets/More_icon.png">
      </span>
    </more-menu>

    <div
      v-if="post.type === 'comics'"
      style="position: relative; width: 100%;">
      <img
        v-if="coverUrl && !outofservice"
        :style="{
          ...coverImageStyle,
          width: '100%',
        }"
        :src="coverUrl">

      <span
        v-if="post.title && !outofservice"
        class="comic-title">{{ post.title }}</span>
      <span
        v-else-if="!outofservice"
        class="comic-title">{{ post.coverTitle }}</span>
    </div>
    <post-list-item-picture-grid
      v-else-if="!outofservice && post.pictures.filter(media => media.type !== 2).length > 0">
    </post-list-item-picture-grid>

    <div class="item-bottom-container">
      <tags :tags="post.tags"></tags>
    </div>

    <div class="item-indicators-container">
      <a
        class="indicator hoverable"
        @click.prevent.stop="intentStar">
        <img
          v-if="!post.starred"
          src="@/assets/star.png">
        <img
          v-else
          src="@/assets/star_click.png">
        <span>{{ post.hideStars ? '' : post.starCount }}</span>
      </a>

      <a 
        class="indicator hoverable"
        @click.prevent.stop="$router.push(`/posts/${post._id}`)">
        <img
          src="@/assets/comment.png"
          alt="">
        <span>{{ post.commentCount }}</span>
      </a>

      <a
        class="indicator hoverable"
        @click.prevent.stop="like">
        <img
          v-if="!post.liked"
          src="@/assets/like.png">
        <img
          v-else
          src="@/assets/like_click.png">
        <span>{{ post.likes }}</span> 
      </a>
    </div>

    <!--report-->
    <pr-popup
      v-if="reportShow"
      v-model="reportShow">
      <report
        :pics-component="post"
        :post-id-component="post._id"
        :nickname-component="nickname"
        :user-id-component="post.user._id"
        :url="true"
        @close="reportShow = false"></report>
    </pr-popup>

    <media-saver
      v-if="post._id"
      v-model="collectorShow"
      :post="post"
      @completed="onStarCompleted"></media-saver>
  </div>
</template>


<script>
import Tags from '@/components/Tags.vue'
import Report from '@/pages/Report.vue'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { usePostMoreMenu } from '@/composables/artwork_moremenu'
import { useResponsive } from '@/composables/responsive'
import MediaSaver from '@/components/MediaSaver.vue'
import { useArtworkActions } from '@/composables/artwork_action'
import MoreMenu from '@/components/MoreMenu.vue'
import PostListItemPictureGrid from '@/components/PostListItemPictureGrid.vue'
import { useCurrentCountry } from '@/composables/country'
import PrPopup from './PRPopup.vue'
import PostHeader from '@/components/PostHeader.vue'

export default {
  props: {
    post: {
      type: Object,
      required: true
    },
  },
  setup (props) {
    const { currency } = useCurrentCurrency()

    return {
      ...useCurrentCountry(),
      ...useArtworkPresent(props.post._id, currency, props.post),
      currency,
      ...usePostMoreMenu(props.post),
      ...useResponsive(),
      ...useArtworkActions(props.post._id),
    }
  },
  components: {
    Tags,
    Report,
    MediaSaver,
    MoreMenu,
    PostListItemPictureGrid,
    PrPopup,
    PostHeader,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.loading {
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0px;
  text-align: center;
  z-index: 2;
  color: #333333;
  left: 10%;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
    margin-top: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    box-sizing: border-box;
    text-align: center;
    z-index: 1000;
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;
    width: 100%;
    display: inline-block;
  }
}

.item-container {
  width: 100%;
  margin-top: 1em;
  background: white;
  position: relative;
  padding: 1rem;
  padding-bottom: 0;
  border-radius: 0.5rem;
  border: solid 1px @border;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media @phone {
    border: none;
    border-bottom: solid 1px @border;
    border-radius: 0;
  }

  .more {
    position: absolute;
    right: 8px;
    top: 15px;

    img {
      width: 20px;
    }
  }
}

.item-header {
  span {
    font-size: 12px;
    color: @grey;
  }

  .item-avatar {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  .item-nickname {
    color: @black;
    font-weight: 500;
  }
}


.item-content {
  width: 100%;
  height: auto;
  text-align: left;
  display: flex;
  align-items: flex-start;

  .item-content-text {
    font-size: 17px;
    color: #a1a1a1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    display: -webkit-box;          /* Required for the line-clamp to work */
    -webkit-line-clamp: 2;         /* Number of lines you want to display */
    -webkit-box-orient: vertical;  /* Required for the line-clamp to work */
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    line-height: 1.3;
  }
}


.item-indicators-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  border-top: solid 1px @border;

  @media @phone {
    border-top: none;
  }

  a {
    font-size: 0.85em;
    height: 1.7em;

    &:first-child {
      margin-left: 5px;
    }
  }

  .indicator {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-right: solid 1px @border;
    padding: 0.5rem;
    min-height: 2.5rem;

    &:last-child {
      border-right: none;
    }

    img {
      width: 20px;
    }

    span {
      margin-left: 0.4em;
      font-size: 12px;
      color: @grey;
    }
  }
}

.gray {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.fake-badge {
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 120px;
  z-index: 10;
}

.item-bottom-container {
  width: 100%;
  height: 1.9em;
  padding-bottom: 0.5em;
}

.comic-title {
  position: absolute;
  display: inline-block;
  bottom: 1rem;
  width: calc(100% - 28px);
  left: 14px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.34);
}
</style>