<template>
  <div class="container">
    <div class="topics">
      <div
        v-for="topic in topics"
        :key="topic._id"
        class="topic"
        :style="{ backgroundImage: `url('${topic.coverUrl}')` }"
        @click="enterTopic(topic._id)"></div>
    </div>
    <load-more-button
      :busy="busy"
      :has-more="hasMore"
      :trigger="getTopics"
    />
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { getTopics, getGirls } from '@/api/explore'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Topics | Perohub',
    })

    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      users: [],
      topics: [],
      
      page: 1,
      busy: false,
      hasMore: true,
      swiperOption: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperSlides: []
    }
  },
  mounted () {
   
    this.getTopics()
  },
  methods: {    
    getUsers () {
      getGirls()
        .then(users => {
          this.users = users
        })
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    getTopics () {
      const limit = 9

      this.busy = true

      getTopics(this.page, limit)
        .then(topics => {
          this.busy = false

          if (this.page === 1) {
            this.topics = topics
          } else {
            topics.forEach(topic => this.topics.push(topic))
            //topics.forEach(topic =>this.swiperOption.virtual.slides.push(topic))
          }


          if (topics.length === 0) {
            this.hasMore = false
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    enterTopic (topicId) {
      const topic = this.topics.filter(topic => topic._id === topicId)[0]

      if (topic.type === 'web') {
        location.href = topic.url
      } else if (topic.type === 'posts') {
        this.$router.push(`/topics/${topicId}`)
      }
    }
  },
  components: {
    LoadMoreButton,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  margin-top: 28px;
  width: 100%;
  padding-bottom: 50px;
}

@media (min-width: 800px) { 
  .container {
    width: 816px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;

  }
  
}


.topics {
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: flex-start;


  .topic {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 90%;
    height: 10em;
    border-radius: 4px;
    border: solid 1px @border;
    margin: 8px auto;

    &:hover {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;
    }
  }
  @media (min-width: 800px) { 
    .topic {
      width: 255.4px;
      height: 129.5px;
    }
  }

}
</style>
