<template>
  <div
    v-if="authenticated"
    class="container">
    <pr-cell :title="$t('enablePushNotification').toString()">
      <toggle-switch v-model="enablePushNotification"></toggle-switch>
    </pr-cell>

    <pr-cell :title="$t('authorFollowMe').toString()">
      <toggle-switch v-model="authorFollow" />
    </pr-cell>

    <pr-cell :title="$t('authorNewPost').toString()">
      <toggle-switch v-model="authorPost" />
    </pr-cell>

    <pr-cell :title="$t('commentNew').toString()">
      <toggle-switch v-model="comment" />
    </pr-cell>

    <pr-cell :title="$t('authorSubmitMyChannel').toString()">
      <toggle-switch v-model="postContribute" />
    </pr-cell>
  </div>
</template>

<script>
import { updateNotificationSettings } from '@/api/user'
import { mapActions } from 'pinia'
import { useCurrentUser } from '@/composables/user_data'
import { useUserStore } from '@/pinia/user'
import { useToast } from '@/composables/toast'
import ToggleSwitch from 'primevue/toggleswitch'
import { useOneSignal } from '@/composables/onesignal'
import { onMounted, watch, ref } from 'vue'
import PrCell from '@/components/PRCell.vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

export default {
  setup () {
    const { optIn, optOut, isOptedIn } = useOneSignal()
    const { t } = useI18n()

    useHead({
      title: t('notifySetting'),
    })

    const enablePushNotification = ref(false)

    watch(enablePushNotification, async (value) => {
      if (value) {
        await optIn()
        if (!await isOptedIn()) {
          enablePushNotification.value = false
        }
      } else {
        optOut()
      }
    })

    onMounted(async () => {
      enablePushNotification.value = await isOptedIn()
    })

    return {
      enablePushNotification,
      ...useCurrentUser(),
      ...useToast(),
    }
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    authorPost: {
      get () {
        return this.currentUser.notifyFlags.authorPost
      },
      set (value) {
        console.log(value)
        this.updateReceiveAuthorNewPost(value)
      }
    },
    authorFollow: {
      get () {
        return this.currentUser.notifyFlags.authorFollow
      },
      set (value) {
        this.updateReceiveAuthorFollow(value)
      }
    },
    comment: {
      get () {
        return this.currentUser.notifyFlags.comment
      },
      set (value) {
        this.updateReceiveComment(value)
      }
    },
    postContribute: {
      get () {
        return this.currentUser.notifyFlags.postContribute
      },
      set (value) {
        this.updatePostContribute(value)
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, [
      'patchSelf'
    ]),
    updateReceiveAuthorNewPost (authorPost) {
      updateNotificationSettings({ authorPost })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
    updateReceiveAuthorFollow (authorFollow) {
      updateNotificationSettings({ authorFollow })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
    updateReceiveComment (comment) {
      updateNotificationSettings({ comment })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
    updatePostContribute (postContribute) {
      updateNotificationSettings({ postContribute })
        .then(user => this.patchSelf('notifyFlags', user.notifyFlags))
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    }
  },
  components: {
    ToggleSwitch,
    PrCell,
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 2em;
}
</style>
