<template>
  <pr-tabs
    v-show="show"
    v-model="selectedIndex"
    as="toolbar"
    :tab-count="5">
    <template #toolbar-0>
      <img
        v-show="!isCircles"
        src="@/assets/ic_tabs_fans@2x.png">
      <img
        v-show="isCircles"
        src="@/assets/ic_tabs_fan_switch_on@2x.png">
    </template>

    <template #toolbar-1>
      <img
        v-show="!isChannels"
        src="@/assets/ic_tabs_post@2x.png">
      <img
        v-show="isChannels"
        src="@/assets/ic_tabs_post_switch_on@2x.png">
    </template>

    <template #toolbar-2>
      <img
        v-show="!isExplore"
        src="@/assets/Discovery_disable.png">
      <img
        v-show="isExplore"
        src="@/assets/Discovery_SwitchOn.png">
    </template>

    <template #toolbar-3>
      <img
        v-show="!isStars"
        src="@/assets/ic_tabs_collect@2x.png">
      <img
        v-show="isStars"
        src="@/assets/ic_tabs_collect_swich_on@2x.png">
    </template>

    <template #toolbar-4>
      <div style="position: relative; display: flex; align-items: center; justify-content: center;">
        <badge
          v-if="unreadCount > 0 && path !== '/i'"
          :value="unreadCount > 99 ? '99+' : unreadCount"></badge>
        <img
          v-show="!isMe"
          src="@/assets/ic_tabs_user_center@2x.png">
        <img
          v-show="isMe"
          src="@/assets/ic_tabs_user_center_switch_on@2x.png">
      </div>
    </template>
  </pr-tabs>
</template>

<script>
//@ts-check
import { useNotificationStore } from '@/pinia/notification'
import Badge from 'primevue/badge'
import { computed, onMounted, ref, watch } from 'vue'
import PrTabs from './PrTabs.vue'
import { useRoute, useRouter } from 'vue-router'

const indexPathMap = {
  0: '/circles/all',
  1: '/channels',
  2: '/explore',
  3: '/stars',
  4: '/i',
}

export default {
  setup () {
    const notificationStore = useNotificationStore()
    const selectedIndex = ref(0)
    const router = useRouter()
    const route = useRoute()

    const path = computed({
      set (value) {
        router.replace(value)
      },
      get () {
        const path = route.path

        if (path.length > 1 && path.substring(path.length - 1) === '/') {  // remove "/" at the tail
          return path.substring(0, path.length - 1)
        } else {
          return path
        }
      },
    })

    const isStars = computed(() => path.value === '/stars')
    const isExplore = computed(() => path.value === '/explore')
    const isChannels = computed(() => path.value === '/channels')
    const isCircles = computed(() => path.value === '/circles/all')
    const isMe = computed(() => path.value === '/i')

    const show = computed(() => {
      return isStars.value || isExplore.value || isChannels.value || isCircles.value || isMe.value
    })

    watch(selectedIndex, (newVal) => {
      path.value = indexPathMap[newVal]
    })

    onMounted(() => {
      notificationStore.fetchUnreadCount()
    })

    return {
      unreadCount: computed(() => notificationStore.unreadCount),
      indexPathMap,
      selectedIndex,
      path,
      isStars,
      isExplore,
      isChannels,
      isCircles,
      isMe,
      show,
    }
  },
  components: {
    Badge,
    PrTabs,
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.p-badge {
  position: absolute;
  top: -0.25rem;
  left: 0;
}
</style>
