<template>
  <div class="promote-container">
    <div
      v-if="user.authed"
      class="author">
      <ins>{{ $t('creator') }}</ins>
      <pr-cell
        :title="$t('submitToChannels').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote/submit')"
      />
      <pr-cell
        :title="$t('discount').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote/discount')"
      />
      <pr-cell
        :title="$t('collectRequest').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote/submissions')"
      />
      <pr-cell
        :title="$t('coworkRequest').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote/cowork')"
      />
      <!-- <pr-cell
        title="预约发现推广位"
        is-link
        @click="$router.push('/i/publisher/promote/book/app')"
      />
      <pr-cell
        title="预约频道推广位"
        is-link
        @click="$router.push('/i/publisher/promote/book/channel')"
      /> -->
      <pr-cell
        :title="$t('submitToTopics').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote/topics')"
      />
      <!-- <pr-cell
        title="申请编辑推荐"
        is-link
        @click="$router.push('/i/publisher/promote/featured')"
      /> -->
    </div>
    <div
      v-else
      class="author">
      <ins>{{ $t('creator') }}</ins>
      <p>Only Open for Creators</p>
    </div>

    <div class="channel">
      <ins>{{ $t('channelOwner') }}</ins>
      <pr-cell
        :title="$t('shareChannel').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote/channel')"
      />
      <!-- <pr-cell
        title="开放频道推广位"
        is-link
        @click="$router.push('/i/publisher/promote/book/create')"
      /> -->
    </div>

    <div class="thirdparty">
      <ins>Third Parties</ins>
      <p>More to Come</p>
    </div>
  </div>
</template>

<script>
//@ts-check
import { useCurrentUser } from '@/composables/user_data'
import PrCell from '@/components/PRCell.vue'
import { useHead } from '@unhead/vue';

export default {
  setup () {
    useHead({
      title: 'Services | Perohub',
    })
    const { currentUser } = useCurrentUser()
    return {
      user: currentUser
    }
  },
  components: {
    PrCell
  }
}
</script>

<style lang="less">
@import "@/assets/base.less";

.promote-container {
  padding-top: 2em;
  ins {
    font-size: 14px;
    display: block;
    background: @border;
    height: 29px;
    line-height: 29px;
    padding-left: 16px;
    color: @minor;
  }

  p {
    margin: 0 16px 0 16px;
    color: #888;
    border-bottom: 1px solid @border;
    min-height: 48px;
    line-height: 48px;
  }

  button {
    width: 8em;
    height: 4em;
    margin: 2.5px;
  }
}


.promote-container > div {
  margin-bottom: 1em;
}
</style>
