<template>
  <div class="profile-container">
    <user-info :user="currentUser"></user-info>

    <pr-tabs
      v-if="currentUser.authed || allowPirate"
      v-model="selected"
      :tab-count="2"
      as="segmented-control">
      <template #header-0>
        {{ $t('myworks') }}
      </template>
      <template #header-1>
        {{ $t('purchased') }}
      </template>

      <template #tab-0>
        <div style="display: flex; gap: 1rem;">
          <pr-button
            v-if="!currentUser.authed"
            type="primary"
            class="wide"
            @click="contactTwitter">{{ $t('applyVerify') }}</pr-button>
          <pr-button
            type="primary"
            class="wide"
            @click="$router.push('/i/publisher/publish')">{{ $t('publish') }}</pr-button>
        </div>
        <post-list :posts="myArtworks"></post-list>
        <load-more-button
          :busy="myLoading"
          :has-more="myHasmore"
          :trigger="getMyPosts"></load-more-button>
      </template>
      <template #tab-1>
        <post-list
          :posts="purchasedArtworks"></post-list>
        <load-more-button
          :busy="buyLoading"
          :has-more="buyHasmore"
          :trigger="getBoughtPosts"></load-more-button>
      </template>
    </pr-tabs>

    <div
      v-else
      style="padding-top: 1rem;">
      <pr-button
        type="primary"
        class="wide"
        @click="contactTwitter">{{ $t('applyVerify') }}</pr-button>
      <h2 style="margin: 1rem 0">{{ $t('purchased') }}</h2>
      <post-list :posts="purchasedArtworks"></post-list>
      <load-more-button
        :busy="buyLoading"
        :has-more="buyHasmore"
        :trigger="getBoughtPosts"></load-more-button>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/components/UserInfo.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useUserMypage } from '@/composables/user_mypage'
import { allowPirate } from '@/config'
import PrTabs from '@/components/PrTabs.vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

export default {
  setup () {
    const { t } = useI18n()

    useHead({
      title: t('mypage')
    })

    const contactTwitter = function () {
      window.open('https://twitter.com/perohubcom')
    }

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      ...useUserMypage(),
      allowPirate,
      contactTwitter,
    } 
  },
  components: {
    UserInfo,
    LoadMoreButton,
    PrButton,
    PostList,
    PrTabs,
  }
}
</script>

<style>
.profile-container .pr-tabs-header {
  margin: 1em 0;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";

.profile-container {
  .wide {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1em;
  }
}
</style>
