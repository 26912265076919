<template>
  <div
    class="item-header"
    style="display: flex; align-items: center; gap: 1rem;">
    <user-avatar
      v-if="post.jointUsers.length === 0"
      class="item-avatar"
      :user="post.user"
      :disabled="true"
      @click.stop="$router.push(`/users/${post.user._id}`)"></user-avatar>
    <user-avatar-cluster
      v-else
      :users="post.jointUsers">
    </user-avatar-cluster>
    <div
      style="height: 3.2rem; flex: 1; display: flex; flex-direction: column; justify-content: center; gap: 0.1rem;">
      <h5
        class="item-nickname"
        :style="{
          fontSize: post.jointUsers.length > 0 ? '12px' : '15px',
        }">
        {{ post.jointUsers.length === 0 ? nickname : $t('andOthers', { nickname }) }}
        <circle-badge
          v-if="post.user.tag && post.user.tag.visible"
          :member-tag="post.user.tag.memberTag"
          @click.stop="$router.push(`/user/${post.user.tag.authorId}/circles`)"></circle-badge>
      </h5>
      <span>
        {{ createdAt }}&nbsp;&nbsp;
        {{ price === $t('free') ? $t('free') : priceText }}
      </span>
    </div>
  </div>
</template>

<script>
//@ts-check
import { useResponsive } from '@/composables/responsive'
import UserAvatar from '@/components/UserAvatar.vue'
import CircleBadge from '@/components/CircleBadge.vue'
import UserAvatarCluster from '@/components/UserAvatarCluster.vue'

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    priceText: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    nickname: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  setup () {
    return {
      ...useResponsive(),
    }
  },
  components: {
    UserAvatar,
    CircleBadge,
    UserAvatarCluster,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.item-header {
  span {
    font-size: 12px;
    color: @grey;
  }

  .item-avatar {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  .item-nickname {
    color: @black;
    font-weight: 500;
  }
}
</style>