//@ts-check
import { onMounted, ref, computed, watch, onActivated } from 'vue'
import { useResponsive } from './responsive'
import { useCurrentUser } from './user_data'
import { useChannelStore } from '@/pinia/channel'
import { useAuthenticator } from '@/pinia/authenticator'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'


export const useSubscribedChannels = function () {
  const selected = ref(0)
  const channelStore = useChannelStore()
  const timelineShow = ref(false)
  const { isOnMobile } = useResponsive()
  const { authenticated } = useCurrentUser()
  const authenticator = useAuthenticator()

  const channels = computed(() => channelStore.subscribedChannels)

  const selectedChannel = computed(() => {
    if (selected.value < 0) {
      return null
    }
    return channels.value[selected.value]
  })

  const shiftedSelected = computed({
    set (value) {
      selected.value = value - 1
    },
    get () {
      return selected.value + 1
    }
  })


  watch(selectedChannel, () => {
    if (selectedChannel.value) {
      eventEmitter.emit(events.ChannelLatestVisible, selectedChannel.value._id)
    }
  })


  watch(authenticated, async (value, oldValue) => {
    timelineShow.value = authenticated.value

    if (timelineShow.value) {
      selected.value = 0
    }

    if (authenticated.value && !oldValue) {
      await channelStore.fetchSubscriptions()
    }
  })


  watch(selectedChannel, () => {
    if (selectedChannel.value) {
      if (!timelineShow.value) {
        channelStore.$patch({ sourceChannelId: selectedChannel.value._id })        
      }
      channelStore.fetchChannel({ channelId: selectedChannel.value._id })
    }
  })


  watch(timelineShow, () => {
    if (timelineShow.value) {
      channelStore.$patch({ sourceChannelId: null })
    }
  })


  onMounted(async () => {
    channelStore.fetchSubscriptions()

    if (!isOnMobile.value) {
      await authenticator.checkAuth()
      timelineShow.value = authenticated.value

      if (timelineShow.value) {
        selected.value = -1
      }
    }

    if (selected.value >= 0 && selectedChannel.value) {
      channelStore.fetchChannel({ channelId: selectedChannel.value._id })
    } else if (selected.value === -1) {
      timelineShow.value = true
    }
  })


  onActivated(async () => {
    if (selectedChannel.value && !timelineShow.value) {
      channelStore.$patch({ sourceChannelId: selectedChannel.value._id })
    }
  })


  return {
    selected,
    channels,
    timelineShow,
    selectedChannel,
    shiftedSelected,
  }
}