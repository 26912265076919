<template>
  <div class="container">
    <p>Total ${{ (total / 100).toFixed(2) }}</p>
    <div class="withdraws">
      <div
        v-for="withdraw in withdraws"
        :key="withdraw._id"
        class="withdraw">
        <span class="money">
          {{ withdraw.currency }} {{ (withdraw.amount / 100).toFixed(2) }}
        </span>
        <span
          class="status"
          :class="{
            success: withdraw.status === 'completed',
            reviewing: withdraw.status === 'reviewing',
            rejected: withdraw.status === 'rejected' }">
          {{ withdraw.status === 'completed' ? "Transfered" : '' }}
          {{ withdraw.status === 'rejected' ? "Failed" : '' }}
          {{ withdraw.status === 'reviewing' ? "Processing" : '' }}
        </span>
        <span class="time">{{ new Date(withdraw.createdAt).toLocaleString() }}</span>
      </div>
 
      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="fetch"></load-more-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/bill'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      withdraws: [],
      page: 1,
      busy: false,
      total: '...',
      hasMore: true,
    }
  },
  mounted () {
    this.fetch()
    this.fetchTotal()
  },
  methods: {
    fetch () {
      this.busy = true

      api
        .fetchWithdraws(this.page)
        .then(withdraws => {
          withdraws.forEach(withdraw => this.withdraws.push(withdraw))

          this.busy = false
          this.page += 1

          if (withdraws.length < 10) {
            this.hasMore = false
          }
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    },
    fetchTotal () {
      api
        .fetchWithdrawTotal()
        .then(total => {
          this.total = total
        })
        .catch(err => {
          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    },
  },
  components: {
    LoadMoreButton
  },
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  > a {
   width: 12em;
   margin-left: auto;
   margin-right: auto;
   display: block;
   text-align: center;
  }
}

p {
  margin-top: 1em;
  margin-left: 1em;
  margin-bottom: 1em;
}

.withdraws {
  margin-top: 1em;
}

.withdraw {
  background: white;
  margin-bottom: 0.5em;
  padding: 1em;
  overflow: hidden;
  border-bottom: solid @border 2px;

  span {
    display: inline-block;
  }

  .money {
    font-weight: bold;
    width: 5em;
  }

  .time {
    color: #aaa;
    width: auto;
  }

  .status {
    float: right;
  }

  .success {
    color: rgb(76, 217, 100);
  }

  .reviewing {
    color: rgb(255, 204, 0);
  }

  .rejected {
    color: rgb(255, 45, 85);
  }
}
</style>
