<template>
  <div
    class="btn-group"
    :class="{ selected: selectedOption.value || delighted }">
    <li
      v-if="selectedOption.name !== undefined"
      class="dropdown-toggle"
      @click.stop.prevent="toggle">
      {{ selectedOption.name }}
      <span class="caret"></span>
    </li>

    <ul
      v-show="showOptions"
      class="dropdown-menu">
      <li
        v-for="option in options"
        :key="option.name"
        class="touchable">
        <a
          :class="{last: optionlength === index}"
          @click.prevent="selectedOption = option">
          {{ option.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    options: {
      type: [Array, Object],
      default () {
        return []
      }
    },
    modelValue: {
      type: Object,
      required: true,
    },
    delighted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const selectedOption = ref(props.modelValue)
    const showOptions = ref(false)

    const toggle = function () {
      showOptions.value = !showOptions.value
    }

    watch(selectedOption, () => {
      showOptions.value = false
      emit('update:modelValue', selectedOption.value)
    })

    watch(props, () => {
      selectedOption.value = props.modelValue
    })

    return {
      selectedOption,
      showOptions,
      optionlength: 0,
      index: 0,
      toggle,
    }
  },
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.btn-group {
  width: 80px;
  height: 24px;
  line-height: 24px;
  border-radius: 35px;
  border: solid 1px #cccccc;
  color: #cccccc;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  font-size: 14px;
  font-weight: 500;

  &.selected {
    border: solid 1px @main;
    color: @main;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.dropdown-toggle {
  text-align: center;
  text-transform: none;
  float: none;
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;

  &:hover {
    cursor: pointer;
  }

  span {
    display: inline-block;
    margin-right: 4px;
  }
}

.hide {
  display: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: white;
  border: 1px solid @main;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  border-radius: 14px;
  min-width: 100px;
}

.dropdown-menu > li > a {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 2px;
  padding-bottom: 3px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #333333;
  white-space: nowrap;
  text-decoration: none;
  color: @main;
  border-bottom: 1px solid @main;

  &:hover {
    background: #efefef;
    color: @main;
  }
}

.dropdown-menu > li > .last {
  border-bottom: 0px;
}


.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0
}

.caret {
  display: relative;
  width: 0;
  position: relative;
  top: 10px;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  float: right;
}
</style>
