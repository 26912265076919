//@ts-check
import { follow as apiFollow, unfollow as apiUnfollow } from '@/api/user'
import { isRef, ref } from 'vue'
import { useToast } from './toast'
import { useOneSignal } from './onesignal'


export const useUserFollow = function () {
  const { Toast } = useToast()
  const { optIn, isOptedIn } = useOneSignal()
  const following = ref(false)

  const registerPushNotification = async function () {
    try {
      if (!await isOptedIn()) {
        await optIn()
      } 
    } catch (err) {
      console.error(err)
    }
  }

  const follow = async function (userId, mutableUser) {
    try {
      following.value = true
      await apiFollow(userId)
      if (isRef(mutableUser)) {
        mutableUser.value.followed = true
      } else {
        mutableUser.followed = true
      }

      registerPushNotification()
    } catch (err) {
      Toast({
        message: err.message ? err.message : err,
      })
    } finally {
      following.value = false
    }
  }


  const unfollow = async function (userId, mutableUser) {
    try { 
      following.value = true
      await apiUnfollow(userId)
      if (isRef(mutableUser)) {
        mutableUser.value.followed = false
      } else {
        mutableUser.followed = false
      }
    } catch (err) {
      Toast({
        message: err.message ? err.message : err,
      })
    } finally {
      following.value = false
    }
  }


  return {
    follow,
    unfollow,
  }
}