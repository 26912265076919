<template>
  <div class="container">
    <div v-if="!isOnMobile">
      <div class="user">
        <p>{{ $t('recommendedSth', { item: $t('users') }) }}</p>
        <router-link :to="`/explore/user`">
          <span
            class="hoverable touchable"
            @click="$router.push('/explore/user')"
          >{{ $t('viewAll') }}<img src="@/assets/icon_page_nextpage_grey.png"></span>
        </router-link>
      </div>
      
      <user-vertical-list-expore :users="users" />

      <div
        v-show="topics.length > 0"
        class="user usertopic">
        <p>{{ $t('recommendedSth', { item: $t('topic') }) }}</p>
        <router-link :to="`/topics`">
          <span class="hoverable touchable">{{ $t('allTopics') }}<img src="@/assets/icon_page_nextpage_grey.png"></span>
        </router-link>
      </div>

      <div
        v-show="topics.length > 0"
        class="top-translate">
        <swiper
          ref="mySwiper"
          :options="swiperOption">
          <swiper-slide
            v-for="(slide,index) in topics"
            :key="index"
            @click="enterTopic(slide._id)">
            <img
              class="topicimg"
              :src="slide.coverUrl">
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination"></div>
          <div
            slot="button-prev"
            class="swiper-button-prev">
            <img src="@/assets/icon-page-aduser-movetoleft@2x 2.png">
          </div>
          <div
            slot="button-next"
            class="swiper-button-next">
            <img src="@/assets/icon-page-aduser-movetoleft@2x.png">
          </div>
        </swiper>
      </div>

      <div class="user usertopic">
        <p>{{ $t('recommendedSth', { item: $t('circle') }) }}</p>
      </div>
      <div class="circle">
        <router-link
          v-for="circle in circlesuggest.circles"
          :key="circle._id"
          :to="`/circles/${circle._id}`">
          <div>
            <img
              class="back"
              :src="circle.coverUrl">
            <p>{{ circle.title }}</p>
            <img
              class="headimg"
              loading="lazy"
              :src="circle.author.avatarURL">
            <span>{{ circle.author.nickname }}</span>
          </div>
        </router-link>
      </div>

      <div class="user usertopic">
        <p>{{ $t('recommendedSth', { item: $t('channels') }) }}</p>
        <span
          class="hoverable touchable"
          @click="getPromotedChannel()">
          {{ $t('shuffle') }}
          <img src="@/assets/icon-discovery-channellist-refresh@2x.png">
        </span>
      </div>

      <div class="channel">
        <router-link
          v-for="channel in channelpromote.channels"
          :key="channel._id"
          :to="`/channels/${channel._id}`">
          <span
            class="hoverable touchable"
            :class="color[parseInt(4*Math.random())]">{{ channel.title }}</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="isOnMobile"
      class="user-mobile">
      <div class="users">
        <router-link
          v-for="user in users"
          :key="user._id"
          class="user"
          :to="`/users/${user._id}`">
          <img
            loading="lazy"
            :src="user.avatarURL"
            :alt="user.nickname">
          <h6>{{ user.nickname }}</h6>
        </router-link>
      </div>

      <div
        v-show="topics.length > 0"
        class="user usertopic">
        <p>{{ $t('recommendedSth', { item: $t('topic') }) }}</p>
        <router-link :to="`/topics`">
          <span class="hoverable touchable">{{ $t('allTopics') }}<img src="@/assets/icon_page_nextpage_grey.png"></span>
        </router-link>
      </div>

      <swiper :options="swiperOptionPhone">
        <swiper-slide
          v-for="slide in topics.filter((_, index) => index < 4)"
          :key="slide._id"
          @click="enterTopic(slide._id)">
          <img
            class="topicimgPhone hoverable touchable"
            :src="slide.coverUrl">
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-paginationPhone"></div>
      </swiper>

      <div class="user usertopic">
        <p>{{ $t('recommendedSth', { item: $t('circle') }) }}</p>
      </div>

      <div class="circle-slide">
        <router-link
          v-for="circle in circlesuggest.circles"
          :key="circle._id"
          :to="`/circles/${circle._id}`">
          <div class="hoverable touchable">
            <img
              class="back"
              :src="circle.coverUrl">
            <p>{{ circle.title }}</p>
            <img
              class="headimg"
              loading="lazy"
              :src="circle.author.avatarURL">
            <span>{{ circle.author.nickname }}</span>
          </div>
        </router-link>
      </div>

      <div class="user usertopic">
        <p>{{ $t('recommendedSth', { item: $t('channels') }) }}</p>
        <span
          class="hoverable touchable"
          @click="getPromotedChannel()">
          {{ $t('shuffle') }}
          <img src="@/assets/icon-discovery-channellist-refresh@2x.png">
        </span>
      </div>

      <div class="channel">
        <router-link
          v-for="channel in channelpromote.channels"
          :key="channel._id"
          :to="`/channels/${channel._id}`">
          <span
            class="hoverable touchable"
            :class="color[parseInt(4*Math.random())]">{{ channel.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserVerticalListExpore from '@/components/UserVerticalListExpore.vue'
import { getTopics as apiGetTopics, getExploredUsers } from '@/api/explore'
import channel from '@/api/channel'
import { recommendedCircles } from '@/api/circle'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  components: {
    UserVerticalListExpore,
    Swiper,
    SwiperSlide
  },
  setup () {
    useHead({
      title: 'Explore | Perohub',
    })

    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data () {
    return {
      users: [],
      topics: [],
      channelpromote: {
        page: 1,
        hasMore: true,
        busy: false,
        channels: [],
        limit: 10,
      },
      circlesuggest: {
        page: 1,
        hasMore: true,
        busy: false,
        circles: [],
        limit: 5,
      },
      color: ['red', 'zise', 'orange', 'green', 'blue'],
      page: 1,
      busy: false,
      hasMore: true,
      swiperOption: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperSlides: [],
      swiperOptionPhone: {
          slidesPerView: 1,
          mousewheel: true,
          autoplay: true,
          pagination: {
            el: '.swiper-paginationPhone',
            clickable: true
          }
      },
      swiperSlidesPhone: [1, 2, 3, 4, 5]
    }
  },
  mounted () {
    this.getUsers()
    this.getPromotedChannel()
    this.getSuggsetCircles()

    this.getTopics()
  },
  methods: {
    toSlide(i) {
      if (this.$refs.mySwiper.swiper) {
        this.$refs.mySwiper.swiper.slideTo(i, 0)        
      }
    },
    getSuggsetCircles () {

      this.circlesuggest.busy = true
      recommendedCircles(this.circlesuggest.page, this.circlesuggest.limit)
        .then(circles => {
          this.circlesuggest.busy = false
          if (circles.length < this.circlesuggest.limit) {
            this.circlesuggest.page = 1
          } else {
            this.circlesuggest.page += 1
          }
          this.circlesuggest.circles = []
          this.circlesuggest.circles = circles
        })
        .catch(err => {
          this.circlesuggest.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    async getPromotedChannel () {
      try {
        this.channelpromote.busy = true
        const channels = await channel.getPromotedChannel(this.channelpromote.page, this.channelpromote.limit)
        this.channelpromote.busy = false
        if (channels.length < this.channelpromote.limit) {
          this.channelpromote.page = 1
        } else {
          this.channelpromote.page += 1
        }
        this.channelpromote.channels = []
        this.channelpromote.channels = channels
      } catch (err) {
        this.channelpromote.busy = false

        console.error(err)

        this.showToast({
          message: err.message ? err.message : err
        })
      }
    },
    async getUsers () {
      try {
        const users = await getExploredUsers()
        this.users = users
      } catch (err) {
        console.error(err)

        this.showToast({
          message: err.message ? err.message : err
        })
      }
    },
    getTopics () {
      const limit = 10

      this.busy = true

      apiGetTopics(this.page, limit)
        .then(topics => {
          this.busy = false

          if (this.page === 1) {
            this.topics = topics
          } else {
            topics.forEach(topic => this.topics.push(topic))
            //topics.forEach(topic =>this.swiperOption.virtual.slides.push(topic))
          }

          if (!this.isOnMobile && this.$refs.mySwiper.swiper) {
            this.$refs.mySwiper.swiper.slideTo(1, 0)
          }

          

          if (topics.length === 0) {
            this.hasMore = false
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    enterTopic (topicId) {
      const topic = this.topics.filter(topic => topic._id === topicId)[0]

      if (topic.type === 'web') {
        location.href = topic.url
      } else if (topic.type === 'posts') {
        this.$router.push(`/topics/${topicId}`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.user {
  height: 21px;
  margin-bottom: 14px;
  margin-top: 14px;
  position: relative;

  p {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }

  span {
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #cfcfcf;
    right: 0px;
    top: 0px;
  }

  img {
    width: 10px;
    height: 10px;
    margin-left: 1px;

  }
}


.container {
  margin-top: 28px;
  width: 100%;
  padding-bottom: 50px;
}

.user-mobile {
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;

  .channel {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 20px;
    min-height: 120px;

    span {
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      background: #ff5555;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 15px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      margin-right: 10px;
      min-width: 40px;

      &.red {
        background-color: #ff5555;
      }
      &.orange {
        background-color: #ff9255;
      }
      &.green {
        background-color: #3cd480;
      }
      &.blue {
        background-color: #55caff;
      }
      &.zise {
        background-color: #8f3cd4;
      }



    }


  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    text-align: center;
  }

  .topicimgPhone {
    width: 100%;
    height: 174px;
    object-fit: cover;
  }
  .users {
    overflow-x: scroll;
    white-space: nowrap;
    background: white;
    padding-top: 10px;
    padding-left: 2.5px;
    padding-right: 2.5px;

    .user {
      text-align: center;
      display: inline-block;
      width: 60px;
      margin-left: 10px;
      margin-right: 10px;

      h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60px;
      }

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border: 1px solid #ff467d;
        border-radius: 25px;
      }
    }
  }

  .circle-slide {
    overflow-x: scroll;
    white-space: nowrap;
    background: white;
    padding-top: 10px;
    padding-left: 2.5px;
    padding-right: 2.5px;
    padding-bottom: 4px;



    div {
      position: relative;
      display: inline-block;
      margin-right: 16px;

      .back {
        width: 135px;
        height: 180px;
        object-fit: cover;
        border-radius: 4px;
      }

      p {
        position: absolute;
        width: 120px;
        height: 30px;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.36);
        top: 118px;
        left: 4px;
        white-space:nowrap; 
        text-overflow:ellipsis; 
        overflow:hidden;
      }

      .headimg {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: absolute;
        top: 148px;
        left: 4px;
      }

      span {
        width: 96px;
        height: 18px;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.36);
        position: absolute;
        top: 151px;
        left: 32px;
        white-space:nowrap; 
        text-overflow:ellipsis; 
        overflow:hidden;
      }
    }

  }
}

@media (min-width: 800px) { 
  .container {
    width: 816px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0px;

    .channel {
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 20px;
      min-height: 120px;

      span {
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        background: #ff5555;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 15px;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        margin-right: 10px;
        min-width: 40px;

        &.red {
          background-color: #ff5555;
        }
        &.orange {
          background-color: #ff9255;
        }
        &.green {
          background-color: #3cd480;
        }
        &.blue {
          background-color: #55caff;
        }
        &.zise {
          background-color: #8f3cd4;
        }



      }


    }

    .circle {
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 30px;
      gap: 1rem;

      div {
        position: relative;

        

        .back {
          width: 135px;
          height: 180px;
          object-fit: cover;
          border-radius: 4px;

          &:hover {
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            cursor: pointer;
          }
        }

        p {
          position: absolute;
          width: 120px;
          height: 30px;
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.36);
          top: 118px;
          left: 4px;
          white-space:nowrap; 
          text-overflow:ellipsis; 
          overflow:hidden;
        }

        .headimg {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          position: absolute;
          top: 148px;
          left: 4px;
        }

        span {
          width: 96px;
          height: 18px;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.36);
          position: absolute;
          top: 151px;
          left: 32px;
          white-space:nowrap; 
          text-overflow:ellipsis; 
          overflow:hidden;
        }
      }

    }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
      background-image: none;
      left: 10px;
      right: auto;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
      background-image: none;
      right: 10px;
      left: auto;
    }

    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      top: 56%;
      width: 20px;
      height: 20px;
      margin-top: -22px;
      z-index: 10;
      cursor: pointer;
      
      border-radius: 50%;

      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
    }



    .topicimg {
      width: 255px;
      height: 129.5px;
      object-fit: cover;
      border-radius: 4px;

      &:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;
      }
    }

    .user {
      height: 21px;
      margin-bottom: 4px;
      position: relative;

      p {
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #333333;
      }

      span {
        position: absolute;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #cfcfcf;
        right: 0px;
        top: 0px;
      }

      img {
        width: 10px;
        height: 10px;
        margin-left: 1px;

      }
    }

    .usertopic {
      height: 25px;
      margin-top: 20px;
      margin-bottom: 12px;

      p {
        font-size: 17px;
      }

      span {
        font-size: 14px;
      }
    }



  }
  
}


.topics {
  margin-top: 28px;

  .topic {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 90%;
    height: 10em;
    border-radius: 4px;
    border: solid 1px @border;
    margin: 8px auto;

    &:hover {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;
    }
  }
  @media (min-width: 800px) { 
    .topic {
      width: 512px;
      height: 260px;


      &:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;
      }
    }
  }

}
</style>
