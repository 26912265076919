<template>
  <div>
    <header>
      <pr-button
        size="small"
        @click="showAdder = true">{{ $t('add') }}</pr-button>
    </header>

    <pr-cell
      v-for="tag in tags"
      :key="tag._id"
      class="touchable hoverable"
      :title="tag.title"
      is-link
      @click="$router.push(`/i/publisher/tags/${tag._id}`)">
    </pr-cell>
    
    <p v-if="getting">
      {{ $t('loading') }}
    </p>

    <pr-empty
      v-else-if="tags.length === 0"
      :button-text="$t('addCategory').toString()"
      @trigger="showAdder = true">
    </pr-empty>

    <drawer
      v-model:visible="showAdder"
      position="bottom">
      <div class="form-container">
        <float-label>
          <input-text
            id="title"
            v-model="title"
            type="text"></input-text>
          <label for="title">{{ $t('categoryTitle') }}</label>
        </float-label>

        <pr-button
          type="primary"
          :loading="adding"
          @click="addTag">{{ $t('addCategory') }}</pr-button>
      </div>
    </drawer>
  </div>
</template>

<script>
//@ts-check
import { useCurrentUser } from '@/composables/user_data'
import { useSelfTagList, useSelfTagAdd } from '@/composables/selftag'
import { computed } from 'vue'
import PrEmpty from '@/components/PREmpty.vue'
import InputText from 'primevue/inputtext'
import PrButton from '@/components/PRButton.vue'
import PrCell from '@/components/PRCell.vue'
import FloatLabel from 'primevue/floatlabel'
import Drawer from 'primevue/drawer'


export default {
  setup () {
    const { currentUser, authenticated } = useCurrentUser()

    const userId = computed(() => {
      if (authenticated.value) {
        // @ts-ignore
        return currentUser.value._id
      }

      return null
    })

    return {
      ...useSelfTagList(userId),
      ...useSelfTagAdd()
    }
  },
  components: {
    PrEmpty,
    Drawer,
    InputText,
    PrButton,
    PrCell,
    FloatLabel
  }
}
</script>

<style scoped>
header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.form-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input {
  width: 100%;
}
</style>