//@ts-check
import { defineStore } from "pinia"
import { ref } from "vue"
import { useAuthenticator } from "./authenticator"
import { useToast } from "@/composables/toast"
import { getOne, getUserByUsername, follow as apiFollow, unfollow as apiUnfollow } from "@/api/user"


export const useUserStore = defineStore('user', () => {
  const users = ref({})
  const { Toast } = useToast()

  const getUserById = async function (userId, forceUpdate = false) {
    try {
      const authenticator = useAuthenticator()

      if (users.value[userId] && !forceUpdate) {
        return
      }

      await authenticator.checkAuth()
      const user = await getOne(userId)
      if (user) {
        cacheUser(user)        
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const getUserByName = async function (username, forceUpdate = false) {
    try {
      const authenticator = useAuthenticator()
      await authenticator.checkAuth()
      const user = await getUserByUsername(username)
      if (user) {
        cacheUser(user)        
      }
      return user._id
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const cacheUser = function (user) {
    if (!user) {
      return
    }

    users.value[user._id] = user
  }


  const follow = async function (userId) {
    await apiFollow(userId)
    patchUser(userId, 'followed', true)
    patchUser(userId, 'followerCount', users.value[userId].followerCount + 1)
  }


  const unfollow = async function (userId) {
    await apiUnfollow(userId)
    patchUser(userId, 'followed', false)
    patchUser(userId, 'followerCount', users.value[userId].followerCount - 1)
  }


  const patchUser = function (userId, key, value) {
    if (users.value[userId]) {
      users.value[userId][key] = value      
    }
  }

  const patchSelf = function (key, value) {
    const authenticator = useAuthenticator()
    authenticator.$patch({
      currentUser: {
        ...authenticator.currentUser,
        [key]: value
      }
    })
  }


  return {
    users,
    cacheUser,
    getUserById,
    patchUser,
    follow,
    unfollow,
    patchSelf,
    getUserByName,
  }
})