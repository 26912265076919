<template lang="html">
  <div>
    <pr-radio
      v-model="type"
      :options="options">
    </pr-radio>

    <div class="container">
      <line-chart
        v-if="ready"
        :data="data">
      </line-chart>
    </div>
  </div>
</template>


<script>
import LineChart from '@/components/Bill.Chart.Line.vue'
import api from '@/api/bill'
import { useToast } from '@/composables/toast'
import PrRadio from '@/components/PrRadio.vue'

export default {
  setup () {
    return {
      ...useToast(),
    }
  },
  data () {
    return {
      ready: false,
      type: 'week',
      options: [{
        label: 'Last 7 Days',
        value: 'week'
      }, {
        label: 'Last 30 Days',
        value: 'month'
      }, {
        label: 'Last 60 Days',
        value: '2month'
      }, {
        label: 'This Year',
        value: 'year'
      }],
      data: {}
    }
  },
  watch: {
    type (value, oldValue) {
      this.fetchIncome()
    }
  },
  mounted () {
    this.fetchIncome()
  },
  methods: {
    fetchIncome () {
      let timeNodes = ''
      let nodeType = ''
      this.ready = false
      

      if (this.type === 'week') {
        nodeType = 'day'
        const nana = Array.apply(null, Array(7)).map(Number.prototype.valueOf,0)
        timeNodes = nana.map((_, index) => {
          const date = new Date()
          return date.getTime() - (index * 24 * 60 * 60 * 1000)
        })
        .map(timestamp => {
          const date = new Date(timestamp)
          const mm = date.getMonth() + 1
          const dd = date.getDate()

          return [
            date.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd
          ].join('-')
        })
        .reverse()
        .join(',')
      }

      if (this.type === 'month') {
        nodeType = 'day'
        const thirty = Array.apply(null, Array(30)).map(Number.prototype.valueOf,0)

        timeNodes = thirty.map((_, index) => {
          const date = new Date()
          return date.getTime() - (index * 24 * 60 * 60 * 1000)
        })
        .map(timestamp => {
          const date = new Date(timestamp)
          const mm = date.getMonth() + 1
          const dd = date.getDate()

          return [
            date.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd
          ].join('-')
        })
        .reverse()
        .join(',')
      }

      if (this.type === '2month') {
        nodeType = 'day'
        const sixty = Array.apply(null, Array(60)).map(Number.prototype.valueOf,0)

        timeNodes = sixty.map((_, index) => {
          const date = new Date()
          return date.getTime() - (index * 24 * 60 * 60 * 1000)
        })
        .map(timestamp => {
          const date = new Date(timestamp)
          const mm = date.getMonth() + 1
          const dd = date.getDate()

          return [
            date.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd
          ].join('-')
        })
        .reverse()
        .join(',')
      }

      if (this.type === 'year') {
        nodeType = 'month'
        const twelve = Array.apply(null, Array(12)).map(Number.prototype.valueOf,0)

        timeNodes = twelve.map((timestamp, index) => {
          const date = new Date()
          const mm = index + 1

          return `${date.getFullYear()}-${(mm > 9 ? '' : '0') + mm}-01`
        })
        .join(',')
      }

      api
        .fetchIncome(timeNodes, nodeType)
        .then(data => {
          this.data = data
          this.ready = true
        })
        .catch(err => {

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    }
  },
  components: {
    LineChart,
    PrRadio
  }
}
</script>


<style scoped>
.container {
  padding: 1em 0.5em 0 1em;
}
</style>
