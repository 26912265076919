<template>
  <div style="padding: 1rem;">
    <div class="phone-filed">
      <intl-tel-input
        v-model="phone"
        country-code="jp"
        :to-front="regionCodes"></intl-tel-input>
      <span
        :class="{ touchable: seconds === 60, hoverable: seconds === 60 }"
        @click="requestCaptcha"
      >{{ buttonText }}</span>
    </div>

    <pr-field
      v-model="captcha"
      :placeholder="$t('enterCaptcha').toString()"
      :borderless="true"></pr-field>

    <pr-button
      type="primary"
      @click="save">
      {{ confirmButtonText }}
    </pr-button>
  </div>
</template>


<script>
import IntlTelInput from '@/components/IntlTelInput.vue'
import { requestPhoneReplacementCaptcha, updatePhoneNumber } from '@/api/user'
import { regionCodes } from '@/config'
import PrButton from '@/components/PRButton.vue'
import { useCurrentUser } from '@/composables/user_data'
import { useToast } from '@/composables/toast'
import PrField from '@/components/PrField.vue'

export default {
  setup () {
    return {
      ...useCurrentUser(),
      ...useToast(),
    }
  },
  data () {
    return {
      regionCodes,
      phone: {
        phoneNumber: '',
        rawPhoneNumber: '',
      },
      captcha: '',
      waiting: false,
      seconds: 60,
    }
  },
  computed: {
    buttonText () {
      if (this.waiting) {
        return `${this.seconds} 秒`
      } else {
        return this.$t('getCaptcha')
      }
    },
    confirmButtonText () {
      if (!this.authenticated) {
        return '...'
      }

      if (this.currentUser.phoneNumber) {
        return this.$t('modify')
      } else {
        return this.$t('bind')
      }
    }
  },
  methods: {
    requestCaptcha () {
      if (this.waiting) {
        return
      }

      requestPhoneReplacementCaptcha(`+${this.phone.phoneNumber}`)
        .then(() => {
          this.seconds = 60
          this.waiting = true
          this.countdown()
        })
        .catch(err => {
          if (err.message) {
            this.showToast({
              message: err.message,
            })
          } else {
            this.showToast({
              message: err,
            })
          }
        })
    },
    save () {
      if (!this.captcha) {
        return
      }
      updatePhoneNumber(`+${this.phone.phoneNumber}`, this.captcha)
        .then(() => {
          this.showToast({
            message: 'ok',
          }, 'ok')
        })
        .catch(err => {
          if (err.message) {
            this.showToast({
              message: err.message,
            })
          } else {
            this.showToast({
              message: err,
            })
          }
        })
    },
    countdown () {
      // const vm = this

      setInterval(() => {
        this.seconds -= 1

        if (this.seconds <= 0) {
          this.waiting = false
        }
      }, 1000)
    }
  },
  components: {
    IntlTelInput,
    PrButton,
    PrField,
  }
}
</script>


<style scoped lang="less">
@import "@/assets/base.less";

.phone-filed {
  position: relative;

  span {
    color: @blue;
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

button {
  display: block;
  margin: 40px auto;
  width: 90%;
}
</style>