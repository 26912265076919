<template>
  <div class="container">
    <div
      v-if="!isOnMobile"
      class="inputer"
    >
      <img
        v-if="authenticated"
        :src="currentUser.avatarURL"
        :alt="currentUser.nickname"
      >
      <input
        v-model="commentContent"
        type="text"
        :placeholder="authenticated ? $t('enterComment') : $t('pleaseLogin')"
        :disabled="!authenticated"
      >
      <pr-button
        type="primary"
        :disabled="!authenticated"
        @click="postComment"
      >
        {{ $t('send') }}
      </pr-button>
    </div>

    <comment-cell
      v-for="comment in comments"
      v-show="comments.length > 0"
      :key="comment._id"
      :comment="comment"
    />

    <load-more-button
      v-show="comments.length > 0"
      :class="{'margin-load': isOnMobile}"
      :busy="busy"
      :has-more="hasMore"
      @trigger="getComments($route.params.postId)"
    />

    <p v-show="comments.length === 0">
      {{ $t('nomoreComment') }}
    </p>

    <div v-show="showReplies">
      <comment-replies
        ref="repliesModal"
      />
    </div>

    <div
      v-if="isOnMobile"
      class="inputerphone"
    >
      <input
        v-model="commentContent"
        type="text"
        :placeholder="authenticated ? $t('enterComment') : $t('pleaseLogin')"
        :disabled="!authenticated"
      >
      <pr-button
        type="primary"
        :disabled="!authenticated"
        @click="postComment"
      >
        {{ $t('send') }}
      </pr-button>
    </div>
  </div>
</template>

<script>
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PrButton from '@/components/PRButton.vue'
import CommentCell from '@/components/CommentCell.vue'
import CommentReplies from '@/components/CommentReplies.vue'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useToast } from '@/composables/toast'
import { onMounted, ref } from 'vue'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'
import { getComments as apiGetComments, postComment as apiPostComment } from '@/api/comment'

const limit = 10

export default {
  setup () {
    const comments = ref([])

    onMounted(() => {
      eventEmitter.on(events.CommentDeleted, commentId => {
        comments.value = comments.value.filter(comment => {
          if (comment._id === commentId) {
            return false
          }

          return true
        })
      })
    })

    return {
      comments,
      ...useResponsive(),
      ...useCurrentUser(),
      ...useToast(),
    }
  },
  data () {
    return {
      page: 1,
      hasMore: true,
      busy: false,
      commentContent: ''
    }
  },
  mounted () {
    this.getComments(this.$route.params.postId)
  },
  methods: {
    async getComments (postId) {
      this.busy = true

      try {
        const comments = await apiGetComments(postId, this.page, limit)
        if (comments.length > 0) {
          comments.forEach(comment => this.comments.push(comment))
        } else {
          this.hasMore = false
        }

        this.page = this.page + 1
      } catch (err) {
        this.showToast(err)
      } finally {
        this.busy = false
      }
    },
    async postComment () {
      const postId = this.$route.params.postId
      const content = this.commentContent

      try {
        await apiPostComment(postId, content)
        this.commentContent = ''
        this.page = 1
        this.comments = []
        this.getComments(postId)
      } catch (err) {
        this.showToast(err)
      } finally {
        this.busy = false
      }
    },
    showReplies (comment) {
      this.$refs.repliesModal.show(comment)
    },
  },
  components: {
    LoadMoreButton,
    PrButton,
    CommentCell,
    CommentReplies
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;

  > p {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 120px;
  }

  .inputer {
    text-align: center;
    margin-bottom: 20px;
    padding-left: 3px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      display: inline;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      vertical-align: middle;
    }

    input {
      height: 30px;  
      max-width: 440px;
      border-radius: 2px;
      border: solid 1px #cfcfcf;
      outline: 0;
      padding-left: 10px;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      width: 60%;
      left: 4px;

      &[disabled] {
        background: #eee;
        color: @light-grey;
      }
    }

    @media (min-width: 800px) {
      input {
        left: 10px;
        width: 100%;
      }
    }
    button {
      width: 64px;
      height: 30px;
      font-size: 13px;
      border-radius: 2px;
      vertical-align: middle;
      position: relative;
      right: -6px;
    }

    @media (min-width: 800px) {
      button {
        right: -10px;
      }
    }

  }
  @media (min-width: 800px) {
    .inputer {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.inputerphone {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  padding: 6px 8px 6px 8px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: inset 0 0.5px 0 0 #f1f1f1;
  text-align: center;
  margin-top: 40px;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    height: 30px;
    border-radius: 4px;
    background-color: #f1f1f1;
    box-sizing: border-box;
    outline: 0;
    border: 0px;
    position: relative;
    padding-left: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  button {
    max-width: 60px;
    width: 23%;
    height: 30px;
    border-radius: 4px;
    background-color: #ff5579;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    margin-left: 4px;
  }


}

.margin-load {
  margin-bottom: 40px;
}

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}
</style>
