export const en = {
  follow: 'Follow',
  followed: 'Followed',
  followers: 'Followers',
  following: 'Following',
  works: 'Artworks',
  getInTouch: 'Get in Touch',
  latest: 'Latest',
  hot: 'Hot',
  free: 'Free',
  searchbarPlaceholder: 'Search works, users and channels',
  users: 'Users',
  channels: 'Channels',
  wallet: 'Wallet',
  logout: 'Log Out',
  add: 'Add',
  added: 'Added',
  report: 'Report',
  coins: 'Coins',
  loadmore: 'Load More',
  loading: 'Loading...',
  nomore: 'No More',
  login: 'Login',
  getCaptcha: 'Get Captcha',
  enterCaptcha: 'Enter Captcha',
  timeleft: '{time} seconds',
  loginToPR: 'Login to Perohub',
  check: 'Check for latest',
  charge: 'Buy Coins',
  mycoins: 'My Coins',
  purchased: 'Purchased',
  mywallet: 'My Wallet',
  updateProfile: 'Profile',
  updatePhone: 'Update Phone',
  verification: 'Verified Creator Application',
  postDetail: 'Content',
  comment: 'Comments',
  moreOfHer: 'More works by {username}',
  syncWithApp: 'Sync to App',
  back: 'Back',
  success: ' Succeed',
  fail: ' Failed',
  paymentResult: 'Payment Result',
  payment: 'Payment',
  banMessage: '{username} is banned due to "{reason}"',
  products: 'Products',
  uploader: 'Uploader',
  downloadApp: 'Download App',
  about: 'About',
  rules: 'Community Rules',
  tos: 'Terms and Conditions',
  links: 'Links',
  reportCenter: 'Report Center',
  reply: 'Reply',
  send: 'Send',
  repliesTotal: '{total} replies',
  commentOf: '{username}\'s comment',
  enterComment: 'Enter Comment',
  pleaseLogin: 'Please Login',
  nomoreComment: 'No Comments',
  discountQuote: '{price} after discount',
  starWithCount: 'Star({starCount})',
  star: 'Star',
  payout: 'Payout',
  confirm: 'Confirm',
  payoutApplied: 'Payout applied',
  manga: 'Mangas',
  photo: 'Photograhies',
  joinedCircles: 'Joined Circles',
  circles: 'Circles',
  viewAll: 'More',
  joinCircle: 'Join {title}',
  month: 'month',
  week: 'week',
  day: 'day',
  circleFrequency: '1 update/{period} | {count} updates/{period}',
  preparing: 'Preparing',
  updating: 'Serializing',
  completed: 'Finished',
  join: 'Join',
  dm: 'Chat',
  overview: 'Overview',
  joined: 'Joined',
  mypage: 'Profile',
  myworks: 'My Artworks',
  share: 'Share',
  collectInto: 'Collect To Channel',
  addToCircle: 'Add To Circle',
  applyVerify: 'Apply For Creator',
  publish: 'Publish Artworks',
  notifications: 'Notifications',
  modify: 'Modify',
  bind: 'Bind',
  nickname: 'Nickname',
  inputNickname: 'Input nickname',
  save: 'Save',
  sendOK: 'Sent Successfully',
  viewMore: 'View More',
  edit: 'Edit',
  cancel: 'Cancel',
  delete: 'Delete',
  recommendedSth: 'Featured {item}',
  circle: 'Circles',
  topic: 'Topics',
  allTopics: 'All Topics',
  allRecommendedUsers: 'All Recommended Users',
  latestPicks: 'Latest Picks',
  channelOwner: 'Owner',
  subscribe: 'Subscribe',
  best: 'Best',
  adds: '{count} Adds',
  chooseStarItem: 'Choose Star Items',
  blacklist: 'Blocklist',
  settings: 'Settings',
  creator: 'Creator',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  secret: 'Unknown',
  brief: 'Self Introduction',
  setAutoreply: 'Set Contact',
  chatPrice: 'Contact Price',
  resetChat: 'Remove Contact',
  buyers: 'Buyers',
  collect: 'Collect',
  createChannel: 'Create Channel',
  channelManagement: 'Manage Channel',
  channelTitle: 'Channel Title',
  inputChannelTitle: 'Input Channel Title',
  needVerify: 'Submission Review',
  setRoyaltyRate: 'Sales Share',
  channelDescription: 'Channel Description',
  pleaseInputChannelDescription: 'Please introduce your channel',
  submit: 'Submit',
  contactBuyers: 'Contact Buyers',
  openCircle: 'Circle Application',
  circleTitle: 'Circle Name',
  inputCircleTitle: 'Input Circle Name',
  circleDescription: 'Circle Motto',
  circleIntroduction: 'Circle Introduction',
  inputCircleDescription: 'Input Circle Motto',
  inputCircleIntroduction: 'Input Circle Introduction',
  clickBelowToUploadCircleCover: 'Click Button Below to Upload Cover',
  uploadCover: 'Upload Cover',
  recurringPrice: 'Recurring Price Per Month',
  upfrontPrice: 'Upfront Price After Serialization Completed',
  keizaiTimeLength: 'Duration(only upfront payment is allowed after expiration)',
  year: 'One Year',
  halfYear: 'Half Year',
  quarter: 'One Season',
  month: 'Month',
  oneMonth: 'One Month',
  forever: 'Long Term',
  cannotComplete: 'Cannot be marked as completed',
  circleType: 'Circle Type',
  shuffle: 'Shuffle',
  promote: 'Promote',
  circleAdInPost: '{price} for all artworks in one month',
  circleAdInPostFreeComplement: '{price} per month, unlock artworks for {duration}',
  circleAdInPostUpfront: '{price}, unlock artworks for {duration}',
  artworkChannel: 'Collected into following channels',
  contribute: 'Submit',
  submittedBy: 'Submitted by',
  approve: 'Approve',
  reject: 'Reject',
  removeFromChannel: 'Remove From Channel',
  review: 'Review Submissions',
  artworkDetail: 'Artwork Detail',
  block: 'Block',
  unblock: 'Unblock',
  clearCache: 'Clear Media Cache',
  payToUnlock: 'Pay {price} to Unlock',
  willPublishR18: 'I may publish R18 contents',
  communityGuideline: 'Community Guidelines',
  topArtwork: 'Pin Artwork',
  untopArtwork: 'Unpin Artwork',
  editMemberTag: 'Edit Member Tag',
  cancelNextMonth: 'Cancel Subscription',
  circleMembers: 'Circle Members',
  completeCircle: 'Mark as Completed',
  channelDetail: 'Channel Detail',
  search: 'Search',
  noUsersFound: 'No Related Users Found',
  noChannelsFound: 'No Related Channels Found',
  noPostsFound: 'No Related Artworks Found',
  viewBangou: 'No.{no}',
  artworkTitle: 'Artwork Title',
  artworkContent: 'Artwork Text Content',
  freeCover: 'Free',
  artworkType: 'Artwork Type',
  canBeCollected: 'Collectible into Channels',
  referencePlaceholder: 'Source(required)',
  referenceTip: 'Reposted content needs to indicate the source and obtain relevant authorization',
  reference: 'Citation',
  original: 'Original',
  pleaseChoose: 'Please Select',
  dontAddToCircle: 'Not in Circle',
  automaticEpisode: 'Incremental Episode',
  extraEpisode: 'Extra Episode',
  autoDelete: 'Self Destruct',
  autoDeleteString: 'Destruct in {timeString} Click to Edit',
  clickToSelect: 'Click to Pick',
  autoSetRest: 'Exchange Rest From {currency}',
  setFree: 'Set as Free',
  overview: 'Overview',
  detail: 'Detail',
  whyRejectPayout: 'Why My Payout Got Rejected?',
  yesiam18: 'Yes, I\'m 18+',
  no: 'No',
  bindPayout: 'Bind Payout',
  copyLink: 'Copy Link',
  allReports: 'All Reports',
  myReports: 'My Reports',
  reportsToMe: 'Reports to Me',
  privacyTerms: 'Privacy Terms',
  disclosure: 'Disclosure',
  submitToChannels: 'Submit To Channels',
  discount: 'Discount',
  collectRequest: 'Collection Requests',
  coworkRequest: 'Cowork Requests',
  changeTitle: 'Change Artworks Title',
  submitToTopics: 'Submit to Topics',
  shareChannel: 'Share Channels',
  buyGiftCode: 'Buy Gift Code',
  useGiftCode: 'Use Gift Code',
  joinThisCircle: 'Join The Circle: ',
  payoutBindTip: 'Please choose your bank location. Unreal information will cause payout failure.',
  notPurchasedYet: 'Not Purchased Yet',
  mangaDetail: 'Manga Detail',
  username: 'Username',
  inputUsername: 'Input Username',
  artworkCategories: 'Artwork Categories',
  nothingHere: 'Nothing Here',
  categoryTitle: 'Category Title',
  addCategory: 'Add Category',
  orders: 'Orders',
  totalAmount: 'Total',
  giftcode: 'Gift Code',
  contentInPast: 'Content in the Past',
  contentSinceNow: 'Content Since Now',
  tillSerializationOver: 'till Serialization Completed',
  artworkOnDate: 'Artwork on {date}',
  stillProcessing: 'Artwork is still being processed, you can close the page and check later',
  verifyNotice: 'According to regulations of USA, business like Perohub needs to do KYC(Know Your Customer) verification. <b>Perohub does NOT store your personal information.</b> Verification is securely processed by Veriff.',
  verifyBonus: '<b>部分地区用户如果仍然担心个人安全，可以推特大号联系 <a class="outline" href="https://twitter.com/perohubcom" target="_blank">@perohubcom</a> 跳过认证</b>',
  notifySetting: 'Notification Settings',
  authorFollowMe: 'Creator follows me',
  authorNewPost: 'Followed creator publishes new artwork',
  commentNew: 'New comment',
  authorSubmitMyChannel: 'Creator submit to my channel',
  channelNoPosts: 'This channel hasn\'t collected any artwork yet',
  searchChannels: 'Search channels you want to subscribe',
  addedChannels: 'Added Channels',
  pastContentPerMonth: 'Monthly Price for Past Content',
  ifCustomerJoinsAtMonth: 'If a backer joins at month No.{index}',
  theCustomerNeedsToPay: 'The backer needs to pay',
  customerPricePreview: 'Price Preview For Backers',
  circleHasCompleted: 'Circle has been completed',
  illustration: 'illustration',
  outofservice: 'Content Unavailable in Your Region {countryCode}',
  selectChapters: 'Chapters',
  nextChapter: 'Next Chapter',
  prevChapter: 'Previous Chapter',
  alreadyFirstPage: 'This is first page',
  useWatermark: 'Dynamic Watermark (Display Viewer ID)',
  useAutoTranslation: 'Use Auto Translation',
  useManualTranslation: 'Modify Localizations',
  aisatuTokenTitle: 'Your aqquiantence token is {code}',
  aisatuUnlockText: 'Spend {price} to unlock {nickname} \'s Contact?',
  searchAisatuToken: 'Search Aqquiantence Token',
  givenName: 'Given Name',
  lastName: 'Last Name',
  forceComplement: 'Force Complement For All Past Contents When Join',
  complementPrice: 'Complemetary Price Per Month',
  whenUserJoins: 'When a member joins',
  circlePriceText: 'The member needs to pay {priceText} for the month and for every month in the future',
  circleComplementPriceText: 'The member needs to pay for all the past contents at the price of {priceText} per month',
  circleZeroComplementPriceText: 'The member can access to all the past contents for free',
  circleNonForceComplementPriceText: 'If the member wants to access past contents, the member can pay for selected past months at the price of {priceText} per month in the future',
  circleUpfrontPriceText: 'If {title} completed at that time, the member has to pay {priceText} to access all contents',
  selectFreeCoverTip: 'Click Image to Toggle Free Cover',
  zh: '汉语',
  en: 'English',
  ja: '日本語',
  ko: '한국인',
  requiresCircleRight: 'Only Circle Members Can Access (and for free)',
  onlyForMembers: 'Circle Members Only',
  siteLang: 'Site Language',
  complement: 'Purchase Past Contents',
  fromChannel: 'From Channel {title} of {owner}',
  enablePushNotification: 'Push Notification',
  notifcationDeniedWarning: 'You cannot receive push notifications as you\'ve blocked browser permission for Perohub',
  oneArtistMaxPresentsInLatest: 'Max Artworks Display of Each Artist in Timeline',
  limitArtistDisplay: 'Limit Artworks Presents for Each Artist',
  allChapters: 'Chapters ({count})',
  reversedOrder: 'Latest',
  normalOrder: 'Oldest',
  article: 'Articles',
  addTextBlock: 'Add payable text block',
  unfree: 'Not Free',
  createOnPerohub: 'Create on Perohub',
  promoteLineOne: 'Hey artist, this is the best platform to earn passive revenue!',
  globalPaymentsSupport: 'Global Payment Support',
  displayOnWebsite: 'Display digital content right in the website',
  getPaidMonthly: 'Get paid monthly by subscription',
  sellSingleItems: 'Sell single digital items',
  getPaidForPast: 'Get paid for past content when subscribe',
  alipaySupport: 'Payments through Alipay & Wechat Pay',
  watermarkSupport: 'Watermark that prevents infringement',
  realtimePayout: 'Real time Payouts',
  usdtPayout: 'USDT Payouts that keeps you anonymous',
  directSupport: 'Direct support by CEO',
  bankTransfer: 'Bank Transfer',
  threedasset: '3D Asset',
  canDownload: 'Downloadable',
  downloads: 'Downloads',
  downloadCreated: 'Zip Download Task Created',
  goToDownloads: 'Go To My Downloads',
  refresh: 'Refresh',
  unlockCirclePrompt: 'Unlock more artworks by joining {title} or just purchase this one?',
  unlockCirclePromptAccept: 'Join Circle',
  unlockCirclePromptReject: 'Just This One',
  visitorMode: 'Visitor Mode',
  inVisitorMode: 'You are previewing as visitor',
  visitorModeExplain: 'Images with lower resolution instead of blurred images are displayed, blurred images will be displayed in real mode',
  paymentComplete: 'Click Here After Payment Complete',
  zipTaskAutoDeleteTip: 'Your download tasks will be deleted after a period of time',
  r18tip: 'This content may contain adult content, please confirm you are 18+, otherwise you should leave this page',
  forYou: 'Random',
  useAlgoTimeline: 'Display Recommendation',
  removeFromCircle: 'Remove From Circle',
  grantCircleRight: 'Grant Circle Access',
  grantUser: 'Grant User',
  grantAccessTo: 'Grant Access to {title}',
  hideStars: 'Hide Stars',
  showStars: 'Show Stars',
  alipay: 'Alipay',
  wechatPay: 'Wechat Pay',
  tryRefreshPage: 'Try Refresh Page',
  addMedia: 'Add Media',
  loginAutoCreate: 'Account is created automatically when you login for the first time',
  chooseDeletion: 'Choose contents you want to delete',
  unstarOK: 'Unstarred',
  assignment: 'Commision',
  createAssignment: 'Create Commision',
  assignmentUnavailable: 'Commision not accepted',
  acceptAssignment: 'Accept Commision',
  assignmentTemplate: 'Commision Submit Template',
  minAssignmentPrice: 'Commision Bargain Price',
  assignmentPublic: 'Public Commision',
  assignmentPublicDesc: 'The final artwork can be purchased by anyone for the price the artist set',
  assignmentNotPublicDesc: 'Only you can acecss the final artwork',
  assignmentPrice: 'Commision Price',
  currency: 'Currency',
  sendAssignment: 'Send Commision',
  deliverCommission: 'Deliver Commision',
  ['waiting-for-payment']: 'Waiting For Payment',
  ['waiting-for-resolve']: 'Waiting For Resolve',
  open: 'Opened',
  working: 'Working',
  reviewing: 'Reviewing',
  assignerRejected: 'Rejected by Assigner',
  assigneeRejected: 'Rejected by Assignee',
  resolved: 'Completed',
  closed: 'Closed by Assignee',
  issueOpened: 'Assigner has completed payment',
  issueAssignerRejected: 'Assigner is not satisfied',
  issueWorking: 'Assignee started working',
  issueSubmitted: 'Assignee submitted the work',
  issueAssigneeRejected: 'Assignee rejected this commision',
  issueResolved: 'Commision completed',
  issueClosed: 'Assignee gave up on this commision',
  issueCommentPlaceholder: 'This will only be visible between you and {nickname}',
  email: 'Email',
  password: 'Password',
  register: 'Register',
  bindPhoneNumberPrompt: 'According to the watermark settings by the artist, for copyright protection and abuse prevention, please bind your phone number on Perohub to proceed the purchase',
  sending: 'Sending...',
  updatePassword: 'Update Password',
  updateEmail: 'Update Email',
  unlimited: 'Unlimited',
  never: 'Never',
  create: 'Create',
  discountCodes: 'Discount Codes',
  useDiscountCode: 'Use Discount Code (Optional)',
  enterDiscountCode: 'Enter Discount Code',
  checkout: 'Checkout',
  issuePriceNotEnough: 'Price must be greater than artist\'s bargain fee {currency} {price}',
  acceptCommission: 'Accept Commision',
  rejectCommission: 'Reject Commision',
  continueCommission: 'Continue Commission',
  giveUpCommission: 'Give Up Commission',
  makePublicSale: 'Make Artwork Public For Sale',
  pickCocreators: 'Pick Co-creators',
  setCocreators: 'Set Co-creators',
  searchByNickname: 'Search by Nickname',
  wantsToCollaborate: 'wants to publish together with you',
  youCanGetRevenue: 'You can get {shareRate}% of the revenue',
  revenueShares: 'Revenue Shares',
  andOthers: '{nickname} and others',
}
