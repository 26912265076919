<template>
  <div class="page">
    <div class="container">
      <div>
        <div class="avatar-container">
          <div class="avatar-wrap">
            <img
              v-show="!uploading"
              :src="currentUser.avatarURL"
              class="avatar"
              alt="avatar">
            <i
              v-show="uploading"
              class="pi pi-spin pi-spinner"
              style="font-size: 2rem;">
            </i>
            <img
              class="upload touchable hoverable "
              src="@/assets/Add_HeadImage.png"
              alt="upload"
              @click="pickAvatarImage">
            
            <input
              v-show="false"
              ref="avatarInputRef"
              type="file"
              accept="image/*"
              @change="onFilePicked">
          </div>

          <progress-bar
            v-show="uploadProgress !== 0 && uploadProgress !== 100"
            :value="uploadProgress">
          </progress-bar>
        </div>

        <pr-field
          v-model="nickname"
          :label="$t('nickname').toString()"
          :placeholder="$t('inputNickname').toString()">
        </pr-field>
        
        <pr-field
          v-if="currentUser.authed"
          v-model="username"
          :label="$t('username').toString()"
          placeholder="username">
          <span>.pero.app</span>
        </pr-field>
        
        <pr-cell
          :title="$t('gender').toString()">
          <select v-model="gender">
            <option :value="0">{{ $t('secret') }}</option>
            <option :value="1">{{ $t('female') }}</option>
            <option :value="2">{{ $t('male') }}</option>
          </select>
        </pr-cell>
        
        <pr-field
          v-model="motto"
          :label="$t('brief').toString()"
          type="textarea"
          :rows="3">
        </pr-field>

        <pr-cell
          v-show="currentUser.authed"
          :title="$t('acceptAssignment').toString()">
          <toggle-switch v-model="availableForIssue"></toggle-switch>
        </pr-cell>

        <pr-cell
          v-show="availableForIssue"
          :title="$t('minAssignmentPrice').toString()">
          <prices-setter v-model="minIssuePrices"></prices-setter>
        </pr-cell>

        <pr-field
          v-show="availableForIssue"
          v-model="issueTemplate"
          :label="$t('assignmentTemplate').toString()"
          type="textarea"
          :rows="3">
        </pr-field>

        <pr-button
          type="primary"
          :loading="loading"
          @click="saveProfile">
          {{ $t('save') }}
        </pr-button>
      </div>

      <hr>
      
      <div>
        <pr-cell
          is-link
          :title="$t('setAutoreply').toString()"
          to="/i/edit/autoreply">
        </pr-cell>
        <pr-cell
          is-link
          :title="$t('updatePhone').toString()"
          to="/i/edit/phone">
        </pr-cell>
        <pr-cell
          is-link
          :title="$t('updateEmail').toString()"
          to="/i/edit/email">
        </pr-cell>
        <pr-cell
          is-link
          :title="$t('updatePassword').toString()"
          to="/i/edit/password">
        </pr-cell>
      </div>
    </div>
  </div>
</template>

<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useUserProfile } from '@/composables/user_edit'
import { useCurrentUser } from '@/composables/user_data'
import { useUploadAvatar } from '@/composables/upload_avatar'
import ToggleSwitch from 'primevue/toggleswitch'
import PricesSetter from '@/components/PricesSetter.vue'
import PrCell from '@/components/PRCell.vue'
import PrField from '@/components/PrField.vue'
import ProgressBar from 'primevue/progressbar'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

export default {
  setup () {
    const { t } = useI18n()

    useHead({
      title: t('updateProfile')
    })

    return {
      ...useResponsive(),
      ...useUserProfile(),
      ...useCurrentUser(),
      ...useUploadAvatar(),
    }
  },
  components: {
    PrButton,
    ToggleSwitch,
    PricesSetter,
    PrCell,
    PrField,
    ProgressBar
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.page {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 2em;
}

.container {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;
    margin-right: 2em;
    max-width: 200em;
  }
}

.avatar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2em;

  .avatar-wrap {
    width: 6em;
    height: 6em;
    border-radius: 3em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      width: 100%;
      height: 100%;
    }

    img.upload {
      width: 2em;
      height: 2em;
      position: absolute;
      bottom: -0.5em;
      right: -0.5em;
    }
  }
}

@media @phone {
  .page {
    padding: 1em;
    box-sizing: border-box;
  }

  .container {
    flex-wrap: wrap;

    > div {
      margin-right: 0;
    }
  }
}

button {
  display: block;
  margin: 40px auto;
  width: 90%;
}
</style>
