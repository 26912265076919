<template>
  <div
    v-if="report.suspect"
    class="reportdetail-container"
  >
    <div class="report">
      <div class="title">
        <span>
          针对
          <router-link
            class="touchable outline"
            :to="`/users/${report.suspect._id}`"
          >@{{ report.suspect.nickname }}</router-link>
          的举报
        </span>
        <span class="time">{{ new Date(report.createdAt).toLocaleString() }}</span>

        <span
          v-if="report.status === 'reviewing'"
          class="status"
          :class="report.status"
        >待处理</span>

        <span
          v-if="report.status === 'settled' && report.verified"
          class="status reportdetail-verified"
        >
          属实
        </span>

        <span
          v-if="report.status === 'settled' && !report.verified"
          class="status"
          :class="report.status"
        >
          不属实
        </span>
      </div>
      <div class="content">
        <span
          v-linkify:options="{ className: 'reportdetail-commoncelllink' }"
          v-html="sanitize(formatereason)"
        />

        <div
          class="ref touchable hoverable"
          @click="jumpdetail"
        >
          <div class="postwrap">
            <div class="left">
              <img :src="titleimg">
            </div>
            <div class="right">
              <span class="title-span">{{ report.post.title }} </span>
              <span class="content-span">{{ report.post.content }}</span>
              <div>
                <p>
                  <img src="@/assets/icon_web_h5_ImageNumber_grey.png">
                  <span>{{ report.post.pictureCount }}</span>
                </p>
                <p>
                  <img src="@/assets/icon_web_h5_VideoNumber_grey.png">
                  <span>{{ report.post.videoCount }}</span>
                </p>
                <span class="price-span">{{ report.post.price }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="report.contents.length > 0"
            class="evidence"
          >
            <div
              v-for="[content, index] in report.contents"
              :key="index"
              class="item"
            >
              <img :src="content.thum">
              <img
                v-if="content.type === MediaType.VIDEO"
                class="playTag"
                src="@/assets/video_tag.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="report.status === 'settled'"
        class="result"
      >
        <p
          v-for="(item, index) in formatereply"
          :key="index"
        >
          {{ item }}
        </p>
      </div>
    </div>

    <div
      v-if="comments.length > 0"
      class="reportdetail-comments"
    >
      <div
        v-for="commentInstance in comments"
        :key="commentInstance._id"
        class="comment"
      >
        <div class="user">
          <img
            loading="lazy"
            :src="commentInstance.user.avatarURL"
            :alt="commentInstance.user.nickname"
          >
        </div>

        <div class="content">
          <h5>{{ commentInstance.user.nickname }}</h5>
          <p>{{ commentInstance.content }}</p>
        </div>
      </div>

      <load-more-button
        :has-more="hasMore"
        :busy="busy"
        :trigger="getComments"
      />
    </div>

    <div class="reportdetail-reply">
      <input
        v-model="comment"
        type="text"
      >
      <pr-button
        size="small"
        @click="send"
      >
        发送
      </pr-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/report'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PrButton from '@/components/PRButton.vue'
import { sanitize } from 'dompurify'
import { useToast } from '@/composables/toast'
import { MediaType } from '@/utils/defines'
import { useResponsive } from '@/composables/responsive'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { whenever } from '@vueuse/core'
import { ref } from 'vue'

const limit = 10

export default {
  setup () {
    const { t } = useI18n()
    const report = ref({})

    whenever(report, () => {
      if (report.value.suspect) {
        useHead({
          title: `针对${report.value.suspect.nickname}的举报`,
        })
      } else {
        useHead({
          title: t('reportCenter'),
        })
      }
    })

    return {
      report,
      ...useResponsive(),
      ...useToast(),
      MediaType,
      sanitize,
    }
  },
  data () {
    return {
      comments: [],
      hasMore: true,
      busy: false,
      comment: '',
      page: 1,
    }
  },
  computed: {
    formatereason () {
      return '举报原因：' + this.report.reason
    },
    formatereply () {
      var replymsg = this.report.resultMsg.replace(/\n/g,'replymsg')
      var reply = replymsg.split("replymsg");
      return reply
    },
    titleimg () {
      let imgs = this.report.post.pictures
      let imglength = this.report.post.pictures.length 
      let postimg = 0
      let imgurl = ''
      for (let i = 0; i < imglength; i++) {
        if (imgs[i].canBrowse) {
          postimg = i
          break
        }
      }
      if (postimg) {
        imgurl = imgs[postimg].url
      } else {
        imgurl = imgs[postimg].thum
      }
      return imgurl
    }
  },
  mounted () {
    this.fetch()
    this.getComments()

    //console.info(this.report.resultMsg)
    document.addEventListener("keydown", () =>{
      if(event.keyCode == 13) {
        
        if(this.comment != '') {
          this.send()
        }
      }
    })
  },
  methods: {
    jumpdetail () {
      this.$router.push(`/posts/${this.report.post._id}`)
    },
    fetch () {
      const reportId = this.$route.params.reportId

      api
        .getOne(reportId)
        .then(report => {
          this.report = report
          //document.title = `针对${this.report.suspect.nickname}的举报`
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    },
    getComments () {
      const reportId = this.$route.params.reportId
      this.busy = true

      api
        .getComments(self.page, limit, reportId)
        .then(comments => {
          this.busy = false
          self.page += 1

          comments.forEach(comment => this.comments.push(comment))

          if (comments.length < limit) {
            this.hasMore = false
          }
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    },
    send () {
      const reportId = this.$route.params.reportId
      const content = this.comment

      if (!content) {
        return
      }

      

      api
        .postComment(reportId, content)
        .then(comment => {
          
          this.comment = ''
          this.comments.push(comment)
        })
        .catch(err => {
          this.busy = false

          let message = ''

          if (err.message) {
            message = err.message
          } else {
            message = err
          }

          this.showToast({
            message,
          })
        })
    },
  },
  components: {
    LoadMoreButton,
    PrButton
  },
}
</script>

<style lang="less">
@import "@/assets/base.less";

.reportdetail-container {
  background: white;
  padding-bottom: 0px;
  box-sizing: border-box;

  a {
    margin: 0;
  }

  .report {
    padding: 1em;
    box-sizing: border-box;

    > div {
      margin-bottom: 1em;
      box-sizing: border-box;
    }

    .title {
      border-bottom: 1px solid @border;
      box-sizing: border-box;
      position: relative;
      > span {
        display: block;
        font-weight: bold;
        font-size: 17px;
        a {
          font-size: 17px;
        }
      }

      .time {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        position: relative;
        top: -6px;
      }
      .status {
        position: absolute;
        right: 0px;
        font-size: 0.75em;
        height: 24px;
        width: 5em;
        line-height: 24px;
        display: block;
        text-align: center;
        border-radius: 12px;
        color: white;
        top: 10px;
        font-weight: 200;
        box-sizing: border-box;

        &.reviewing {
        background: rgb(49, 202, 252);
        }

        &.settled {
          background: rgb(204, 204, 204);
        }
       
      }
      .reportdetail-verified {
        background: #ff5555;
      }

    }

    .content {
      > span {
        display: block;
        margin-bottom: 1em;
        font-weight: bold;
        box-sizing: border-box;

        .reportdetail-commoncelllink {
          color: @blue;
          cursor: pointer;
          opacity: 0.65;
          -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
        }
      }

      .ref {
        color: #9b9b9b;
        font-size: 14px;
        box-sizing: border-box;

        .postwrap {
          display: flex;

          .left {
            margin-right: 6px;
            width: 60px;
            img {
              width: 60px;
              height: 60px;
              object-fit: cover;
            }
          }
          .right {
            float: right;
            width: 100%;
            box-sizing: border-box;

            span {
              display: block;
              line-height: 21px;
            }
            .title-span {
              font-size: 17px;
              font-weight: bold;
              color: #333333;
            }
            .content-span {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 260px;
              font-size: 14px;
              color: #9b9b9b;
              box-sizing: border-box;
            }
            @media (min-width: 800px) { 
              .content-span {
                width: 400px;
              }
            }
            div {
              position: relative;

              p{
                display: inline-block;
                position: relative;
                margin-right: 6px;

                img {
                  width: 13px;
                  height: 13px;
                }

                span {
                  font-size: 14px;
                  color: #cfcfcf;
                  display: inline;
                  position: relative;
                  top: -1px;
                }
              }
              .price-span {
                position: absolute;
                font-size: 14px;
                color: #ffda55;
                right: 0px;
                top: 2px;
              }
            }
          }
        }

        .evidence {
          overflow-x: scroll;
          white-space: nowrap;
          margin-top: 0.5em;

          .item {
            width: 32px;
            height: 32px;
            position: relative;
            display: inline-block;
            margin-right: 5px;

            img {
              width: 32px;
              height: 32px;
              object-fit: cover;
              position: absolute;

              &.playTag {
                width: 28px;
                height: 28px;
                top: 2px;
                left: 2px;
              }
            }
          }
        }
      }
    }

    .result {
      background: #55caff;
      color: white;
      padding: 0.6em 1em;
      font-size: 1em;
      border-radius: 8px;
    }
  }
}

.reportdetail-comments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: white;
  margin-bottom: 10px;

  .comment {
    margin-bottom: 2em;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .user {
    max-width: 50px;
    display: inline-block;
    text-align: center;
    flex: 1;

    img {
      display: inline;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin-left: 1em;
    }
  }

  .content {
    display: inline-block;
    margin-left: 2em;
    flex: 1;

    h5 {
      margin-bottom: 10px;
    }

    p {
      margin-right: 1em;
    }
  }
}

.reportdetail-reply {
  position: fixed;
  background: #ffffff;
  bottom: 0;
  width: 100%;
  padding: 3px 0;
  border-top: 1px solid #f1f1f1;
  height: 55px;
  box-sizing: border-box;

  form {
    display: inline-block;
    width: 100%
  }

  input {
    margin-top: 2px;
    width: 70%;
    margin-left: 2%;
    border: none;
    box-shadow: none;
    height: 43px;
    background: #f1f1f1;
    padding-left: 6px;
    &:focus {
      outline: none;
    }
  }

  button {
    float: right;
    margin-right: 2%;
    background: @main;
    color: @white;
    width: 20%;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 1px;
    min-width: 72px;
  }
}
@media (min-width: 800px) { 
  .reportdetail-container {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
  }
  .content-span {
    width: 400px;
  }
}
</style>
