<template>
  <div
    v-if="!isOnMobile"
    class="container">
    <ul class="left">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: index === selected }"
        @click="select(index)">
        {{ tab }}
      </li>
    </ul>

    <div class="right">
      <keep-alive>
        <search-result-posts
          v-show="selected === 0"
          ref="searchPostsRef"
          :keyword="q"></search-result-posts>
      </keep-alive>

      <keep-alive>
        <search-result-users
          v-show="selected === 1"
          ref="searchUsersRef"
          :keyword="q"></search-result-users>
      </keep-alive>

      <keep-alive>
        <search-result-channels
          v-show="selected === 2"
          ref="searchChannelsRef"
          :keyword="q"></search-result-channels>
      </keep-alive>
    </div>
  </div>

  <div
    v-else
    class="search-mobile-container">
    <input
      ref="inputRef"
      v-model="q"
      type="text"
      :placeholder="$t('search').toString()"
      @keyup.enter="doSearch">

    <pr-tabs
      v-model="selected"
      as="static-tabs"
      :tab-count="3"
      :fixed="true"
      :top="51"
      :evenly="true">

      <template
        v-for="(tab, index) in tabs"
        #[`header-${index}`]>
        <span :key="index">
          {{ tab }}
        </span>
      </template>

      <template #tab-0>
        <search-result-posts
          ref="searchPostsRef"
          :keyword="q">
        </search-result-posts>
      </template>
      <template #tab-1>
        <search-result-users
          ref="searchUsersRef"
          :keyword="q">
        </search-result-users>
      </template>
      <template #tab-2>
        <search-result-channels
          ref="searchChannelsRef"
          :keyword="q">
        </search-result-channels>
      </template>
    </pr-tabs>
  </div>
</template>

<script>
//@ts-check
import SearchResultChannels from '@/components/SearchResultChannels.vue'
import SearchResultPosts from '@/components/SearchResultPosts.vue'
import SearchResultUsers from '@/components/SearchResultUsers.vue'
import { useResponsive } from '@/composables/responsive'
import { addParamsToRoute } from '@/utils'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { until, useFocus, whenever } from '@vueuse/core'
import PrTabs from '@/components/PrTabs.vue'
import { useHead } from '@unhead/vue'

export default {
  setup () {
    const selected = ref(0)
    const q = ref('')
    const { t } = useI18n()
    const tabs = [t('works'), t('users'), t('channels')]
    const route = useRoute()
    const searchPostsRef = ref(null)
    const searchUsersRef = ref(null)
    const searchChannelsRef = ref(null)
    const inputRef = ref(null)
    const { focused: isFocus } = useFocus(inputRef, { initialValue: true })

    useHead({
      title: t('search'),
    })

    whenever(inputRef, () => {
      inputRef.value.focus()
    })

    watch(() => route.query.q, () => {
      q.value = String(route.query.q)
    })

    watch(q, () => {
      if (searchUsersRef.value) {
        searchUsersRef.value.page = 1        
      }
      if (searchChannelsRef.value) {
        searchChannelsRef.value.page = 1        
      }
      if (searchPostsRef.value) {
        searchPostsRef.value.page = 1        
      }
    })


    const select = function (value) {
      selected.value = value
      addParamsToRoute(route, {
        type: selected.value,
        q: q.value,
      })
    }

    const doSearch = async function () {
      addParamsToRoute(route, {
        q: q.value,
        type: selected.value,
      })

      await Promise.all([
        (async () => {
          await until(searchUsersRef).toMatch(dom => dom !== null)
          searchUsersRef.value.search()
        })(),
        (async () => {
          await until(searchChannelsRef).toMatch(dom => dom !== null)
          searchChannelsRef.value.search()
        })(),
        (async () => {
          await until(searchPostsRef).toMatch(dom => dom !== null)
          searchPostsRef.value.search()
        })(),
      ])
    }


    onMounted(async () => {
      if (typeof route.query.type !== 'undefined') {
        selected.value = Number(route.query.type)
      }

      if (route.query.q) {
        q.value = String(route.query.q)
      }

      if (!q.value) {
        isFocus.value = true        
      }

      if (q.value) {
        await doSearch()
      }
    })


    return {
      selected,
      q,
      tabs,
      select,
      searchPostsRef,
      searchUsersRef,
      searchChannelsRef,
      doSearch,
      isFocus,
      inputRef,
      ...useResponsive()
    }
  },
  components: {
    SearchResultUsers,
    SearchResultPosts,
    SearchResultChannels,
    PrTabs,
  },
}
</script>


<style lang="less" scoped>
.container {
  text-align: center;
  //margin-right: 8%;
  //margin-top: 12px;
  width: 1000px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  .left {
    display: inline-block;
    width: 120px;
    margin-right: 40px;
    vertical-align: top;

    li {
      display: block;
      height: 30px;
      width: 120px;
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 10px;

      &.active {
        color: #9b9b9b;
        border-radius: 15px;
        background-color: #f1f1f1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .right {
    display: inline-block;
    width: 816px;
    text-align: left;
  }
}
</style>


<style lang="less" scoped>
@import "@/assets/base.less";

.search-mobile-container {
  input {
    border: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3em;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    caret-color: @main;
    font-size: 17px;
    z-index: 2;
  }
}
</style>