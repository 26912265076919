<template>
  <article
    class="article"
    v-html="content">
  </article>
</template>


<script>
import { parse } from 'marked'
import { sanitize } from 'dompurify'
import '@/assets/doc.css'
import md from '@/docs/disclosure.md'
import { useHead } from '@unhead/vue';


export default {
  setup () {
    const content = sanitize(parse(md))

    useHead({
      title: 'Commerce Disclosure | Perohub',
    })

    return {
      content,
    }
  }
}
</script>

<style scoped>
.article {
  max-width: 20em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>