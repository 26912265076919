import { useI18n } from 'vue-i18n'

export const useFormattedString = function () {
  const { d } = useI18n()

  const formatTimestamp = function (timestamp) {
    const date = new Date(timestamp)

    return d(date)
  }

  const ellipseString = function (content, cutLength = 16) {
    if (!content) {
      return '\u00a0'
    }

    if (content.length > cutLength) {
      return content.slice(0, cutLength) + '...'
    }

    return content
  }

  return {
    formatTimestamp,
    ellipseString,
  }
}