import { api } from './base'
import { reusePendingPromise } from 'reuse-pending-promise'


const _getSelf = async function (token) {
  try {
    const res = await api.get('/users/self')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getSelf = reusePendingPromise(_getSelf)


export const setAutoreply = async function (contactInfo, prices) {
  try {
    const res = await api.post('/users/vips/set', {
      contactInfo,
      prices,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const clearAutoreply = async function () {
  try {
    const res = await api.post('/users/vips/unset')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const intentChatPurchase = async function (userId, currency, {
  processor,
  returnUrl,
  subProcessor,
}) {
  try {
    const res = await api.post(`/users/${userId}/vipRequest`, {
      currency,
      processor,
      returnUrl,
      subProcessor,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const bindBankAccount = async function (countryCode, { account, holder, cnId, } = {}) {
  try {
    const res = await api.post('/users/bindbank', {
      countryCode,
      account,
      holder,
      cnId,
    })

    if (res.data.code === 200) {
      return res.data.result.url
    } else {
      throw new Error(res.data.message)
    }
  } catch (err) {
    console.error(err)
    if (err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err)
    }
  }
}

export const applyCreatorStripe = async function (countryCode, isR18 = false) {
  try {
    const res = await api.post('/users/authorize', {
      isR18,
      countryCode,
    })
    return res.data.result.tmpSecret
  } catch (err) {
    console.error(err)
    if (err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err)
    }
  }
}

export const applyCreator = async function (countryCode, firstName, lastName, isR18 = false) {
  try {
    const res = await api.post('/users/authorize', {
      isR18,
      countryCode,
      firstName,
      lastName
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    if (err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err)
    }
  }
}

export const checkVerification = async function (userId) {
  try {
    const res = await api.get(`/users/${userId}/verify_result`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const checkStripeVerification = async function (userId) {
  try {
    const res = await api.get(`/users/${userId}/stripe_verify_result`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const blockUser = async function (userId) {
  try {
    const res = await api.post(`/users/${userId}/block`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const unblockUser = async function (userId) {
  try {
    const res = await api.delete(`/users/${userId}/block`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getBlockedUsers = async function (page = 1, limit = 10) {
  try {
    const res = await api.get('/users/blacklist/all', {
      params: {
        page,
        limit,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getMyMemberTags = async function () {
  try {
    const res = await api.get('/users/tags/my')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const updateMyMemberTag = async function (authorId, visible) {
  try {
    const res = await api.post('/users/tags/select', {
      authorId,
      visible
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const setMemberTagForCircle = async function ({ title, style, color }) {
  try {
    const res = await api.post('/users/tags/update', {
      title,
      style,
      color,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const bindPaypal = async function (code) {
  try {
    const res = await api.post('/users/bindPaypal', {
      code
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const getUserByUsername = async function (username) {
  try {
    const res = await api.get('/users', {
      params: {
        username
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const searchUsers = async function (keywords, page = 1, limit = 5, includeUnverified = false) {
  try {
    const res = await api.get('/users/search', {
      params: {
        limit,
        page,
        keywords,
        includeUnverified,
      }
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const requestEmailReplacementCaptcha = async function (email) {
  try {
    const res = await api.post('/users/bindEmailCode', {
      email
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateEmail = async function (email, captcha, merge = false) {
  try {
    const res = await api.post('/users/bindEmail', {
      email,
      captcha,
      merge,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const requestPhoneReplacementCaptcha = async function (phoneNumber) {
  try {
    const res = await api.post('/users/bindSms', {
      phoneNumber
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updatePhoneNumber = async function (phoneNumber, captcha, merge = false) {
  try {
    const res = await api.post('/users/bindPhoneNumber', {
      phoneNumber,
      captcha,
      merge,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}


export const update = async function (userId, doc) {
  try {
    const res = await api.put(`/users/${userId}`, doc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getOne = async function (userId) {
  try {
    const res = await api.get(`/users/${userId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getRelatives = async function (userId, isFollowers, page) {
  try {
    const res = await api.get(`/users/${userId}/${ isFollowers ? 'followers' : 'followings' }`, {
      params: { page }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const withdraw = async function (userId, withdraws) {
  try {
    const res = await api.post(`/users/${userId}/withdraw`, {
      withdraws
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateNotificationSettings = async function ({ authorFollow, authorPost, comment, postContribute }) {
  try {
    const res = await api.post('/users/notifyFlags/update', {
      authorFollow,
      authorPost,
      comment,
      postContribute
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUntrustRecords = async function (page, limit) {
  try {
    const res = await api.get('/users/untrustRecord/list', {
      params: {
        page,
        limit
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const follow = async function (userId) {
  try {
    const res = await api.post(`/users/follow/${userId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const unfollow = async function (userId) {
  try {
    const res = await api.post(`/users/unfollow/${userId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getChargeStatus = async function (chargeId) {
  try {
    const res = await api.get(`/users/charge/status?chargeNo=${chargeId}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getStripeLoginUrl = async function () {
  try {
    const res = await api.get(`/users/${userId}/stripe_login_url`)
    return res.data.result.url
  } catch (err) {
    console.error(err)
    throw err
  }
}
