<template>
  <div class="container">
    <h2>{{ $t('recommendedSth', { item: $t('users') }) }}</h2>
    <router-link
      v-for="user in users"
      :key="user._id"
      class="circle"
      :to="`/users/${user._id}`">
      <user-list-item 
        class="touchable hoverable"
        :user="user"></user-list-item>
    </router-link>

    <load-more-button
      :busy="busy"
      :has-more="hasMore"
      :trigger="getUsers"></load-more-button>
  </div>
</template>

<script>
import UserListItem from '@/components/UserListItem.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import api from '@/api/channel'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


const limit = 10

export default {
  setup () {
    useHead({
      title: 'Channel Artists | Perohub',
    })

    return {
      ...useToast(),
    }
  },
  components: {
    UserListItem,
    LoadMoreButton
  },
  data () {
    return {
      users: [],
      busy: false,
      hasMore: true,
      page: 1
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.busy = true

      api
        .fetchUsers(this.$route.params.channelId, this.page, limit)
        .then(users => {
          this.busy = false

          if (this.page === 1) {
            this.users = users
          } else {
            users.forEach(user => this.users.push(user))
          }

          if (users.length === 0) {
            this.hasMore = false
          } else {
            this.page += 1
          }
        })
        .catch(err => {
          this.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  max-width: 512px;
  min-width: 245px;
  margin: auto;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
  color: @black;
}
</style>