<template>
  <div class="container">
    <pr-tabs
      v-model="selected"
      :tab-count="chapters.length"
      as="dyn-tabs">
      <template
        v-for="index in indexes"
        #[`header-${index}`]>
        <span :key="index">
          {{ titles[index] }}
        </span>
      </template>

      <template
        v-for="(chapter, index) in chapters"
        #[`tab-${index}`]>
        <article
          :key="index"
          class="article"
          v-html="sanitize(chapter)">
        </article>
      </template>
    </pr-tabs>
  </div>
</template>

<script>
import { parse } from 'marked'
import { sanitize } from 'dompurify'
import '@/assets/doc.css'
import PrTabs from '@/components/PrTabs.vue'

import slogan from '@/docs/slogan.md'
import piracy from '@/docs/piracy.md'
import censorship from '@/docs/censorship.md'
import compliants from '@/docs/compliants.md'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Community Guidelines | Perohub',
    })
  },
  data () {
    return {
      scripts: [ slogan, piracy, compliants, censorship ],
      selected: 0
    }
  },
  computed: {
    chapters () {
      return this.scripts.map(script => sanitize(parse(script)))
    },
    titles () {
      return ['Vision', 'Termination', 'Complaints', 'Censorship']
    },
    indexes () {
      return this.chapters.map((_, index) => index)
    }
  },
  watch: {
    selected (selected) {
      this.$router.replace({
        path: '/rules',
        query: {
          chapter: this.titles[selected]
        }
      }).catch(err => {

      })
    }
  },
  methods: {
    sanitize,
  },
  mounted () {
    const chapterTitle = this.$route.query.chapter
    if (chapterTitle) {
      this.titles.forEach((title, index) => {
        if (title === chapterTitle) {
          this.selected = index
        }
      })
    }
  },
  components: {
    PrTabs
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.container {
  height: 100%;
  background: #fcfcfc;
}
</style>
