<template>
  <component
    :is="StarsMobile"
    v-if="isOnMobile"></component>
  <component
    :is="Stars"
    v-else></component>
</template>


<script>
import { useResponsive } from '@/composables/responsive'
import StarsMobile from '@/components/StarsMobile.vue'
import Stars from '@/components/Stars.vue'
import { useHead } from '@unhead/vue';


export default {
  setup () {
    useHead({
      title: 'My Stars | Perohub',
    })

    return {
      ...useResponsive(),
      StarsMobile,
      Stars,
    }
  },
}
</script>


<style lang="less" scoped>
</style>
