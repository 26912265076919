<template>
  <div
    class="editor-container"
    @click="editor.commands.focus()">
    <pr-button
      size="small"
      class="close-button"
      @click="closeIconClicked">
      <i class="pi pi-times"></i>
    </pr-button>
    <span
      v-if="textMedia.canBrowse"
      class="canbrowse-tag"
      @click="toggleCanBrowse">{{ $t('free') }}</span>
    <span
      v-else
      class="canbrowse-tag"
      @click="toggleCanBrowse">{{ $t('unfree') }}</span>
    <div
      v-if="editor"
      class="tool-bar">
      <toggle-button
        :value="editor.isActive('heading', { level: 1 })"
        on-label="H1"
        off-label="H1"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
      </toggle-button>
      <toggle-button
        :value="editor.isActive('heading', { level: 2 })"
        on-label="H2"
        off-label="H2"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
      </toggle-button>
      <toggle-button
        :value="editor.isActive('heading', { level: 3 })"
        on-label="H3"
        off-label="H3"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
      </toggle-button>
      <toggle-button
        :value="editor.isActive('bold')"
        on-label="Bold"
        off-label="Bold"
        @click="editor.chain().focus().toggleBold().run()">
      </toggle-button>
      <toggle-button
        :value="editor.isActive('italic')"
        on-label="Italic"
        off-label="Italic"
        @click="editor.chain().focus().toggleItalic().run()">
      </toggle-button>
      <toggle-button
        :value="editor.isActive('strike')"
        on-label="Strike"
        off-label="Strike"
        @click="editor.chain().focus().toggleStrike().run()">
      </toggle-button>
    </div>
    <editor-content :editor="editor"></editor-content>
  </div>
</template>


<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { onBeforeUnmount, onMounted, watch, ref } from 'vue'
import PrButton from '@/components/PRButton.vue'
import ToggleButton from 'primevue/togglebutton'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    canToggleBrowse: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    const editor = ref(null)
    const textMedia = ref(props.modelValue)

    watch(textMedia, () => {
      emit('update:modelValue', textMedia.value)
    })

    watch(() => props.modelValue, () => {
      if (editor.value === null) {
        return
      }

      const isSame = editor.value.getHTML() === props.modelValue.textContent
      if (isSame) {
        return
      }

      editor.value.commands.setContent(props.modelValue.textContent, false)
    })

    watch(() => props.canToggleBrowse, () => {
      if (!props.canToggleBrowse) {
        textMedia.value.canBrowse = true
      }
    })

    const closeIconClicked = function () {
      emit('close')
    }

    const toggleCanBrowse = function () {
      if (!props.canToggleBrowse) {
        return
      }
      textMedia.value.canBrowse = !textMedia.value.canBrowse
    }

    onMounted(() => {
      editor.value = new Editor({
        content: props.modelValue.textContent,
        extensions: [
          StarterKit,
        ],
        onUpdate({ editor }) {
          textMedia.value.textContent = editor.getHTML()
        },
      })
    })

    onBeforeUnmount(() => {
      editor.value.destroy()
    })

    return {
      editor,
      closeIconClicked,
      textMedia,
      toggleCanBrowse,
    }
  },
  components: {
    EditorContent,
    PrButton,
    ToggleButton,
  },
}
</script>

<style>
.ProseMirror:focus {
  outline: none;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.editor-container {
  border: 0.1rem solid @light-grey;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  padding-top: 3rem;

  &:hover {
    cursor: text;
  }
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.canbrowse-tag {
  background-color: @blue;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 54px;
  border-radius: 8px;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: white;
  font-weight: bolder;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
}

.tool-bar {
  margin-bottom: 1rem;
}

.tool-bar button {
  margin-right: 0.5rem;
}
</style>