<template>
  <div class="tag-updater-container">
    <div
      v-for="(tag, index) in tags"
      :key="tag.authorId"
      class="item hoverable touchable"
      @click="selectedIndex = index">

      <span>{{ tag.authorName }}</span>

      <circle-badge :member-tag="tag.memberTag"></circle-badge>

      <div
        class="selection">
        <img
          v-if="selectedIndex === index"
          src="@/assets/ic-page-switch-selected@2x.png">
        <img
          v-else
          src="@/assets/ic-page-switch-normal@2x.png">
      </div>
    </div>

    <div
      class="item hoverable touchable"
      @click="selectedIndex = -1">
      <span>Do not show</span>

      <div
        class="selection">
        <img
          v-if="selectedIndex === -1"
          src="@/assets/ic-page-switch-selected@2x.png">
        <img
          v-else
          src="@/assets/ic-page-switch-normal@2x.png">
      </div>
    </div>
  </div>
</template>


<script>
import { getMyMemberTags, updateMyMemberTag } from '@/api/user'
import { onMounted, watch, ref } from 'vue'
import CircleBadge from '@/components/CircleBadge.vue'
import { useAuthenticator } from '@/pinia/authenticator'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    const selectedIndex = ref(-1)
    const tags = ref([])
    const authenticator = useAuthenticator()
    const { Toast } = useToast()

    useHead({
      title: 'Change My Member Tag | Perohub',
    })

    let mounted = false


    watch(selectedIndex, async () => {
      if (!mounted) {
        return
      }

      try {
        if (selectedIndex.value === -1) {
          return await select(null, false)
        }

        const tag = tags.value[selectedIndex.value]
        await select(tag.authorId) 
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    })

    const select = async function (authorId, visible = true) {
      try {
        await updateMyMemberTag(authorId, visible)
        await authenticator.refreshUser()
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    onMounted(async () => {
      try {
        
        await authenticator.checkAuth()
        tags.value = await getMyMemberTags()
        for (const [index, tag] of tags.value.entries()) {
          if (tag.isSelected) {
            selectedIndex.value = index
            break
          }
        }
        mounted = true
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        
      }
    })

    return {
      selectedIndex,
      tags,
    }
  },
  components: {
    CircleBadge,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.tag-updater-container {
  width: 100%;
  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @border;
    height: 3em;
    padding: 0.5em;
    box-sizing: border-box;
    color: @black;
    font-weight: bolder;

    span {
      margin-right: 0.5em;
    }

    .selection {
      margin-left: auto;
      flex-shrink: 0;
      flex-grow: 0;
      height: 1.6em;

      img {
        width: 1.6em;
        height: 1.6em;
      }
    }
  }
}
</style>