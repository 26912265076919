//@ts-check
import { getIssues } from "@/api/issue"
import { onMounted, ref } from "vue"
import { useToast } from "./toast"
import { useCurrentUser } from "./user_data"


export const useAssigneeIssues = function (userId) {
  const issues = ref([])
  const issuePage = ref(1)
  const issueHasMore = ref(true)
  const issueLoading = ref(false)
  const { currentUserId } = useCurrentUser()
  const { showToast } = useToast()

  const get = async function () {
    try {
      const status = [
        'open',
        'working',
        'reviewing',
        'assigner-rejected',
        'assignee-rejected',
        'resolved',
        'closed',
        'waiting-for-resolve',
      ]

      if (currentUserId.value === userId) {
        status.push('waiting-for-payment')
      }

      issueLoading.value = true
      const newIssues = await getIssues({
        assigneeId: userId,
        status,
      }, {
        page: issuePage.value,
        limit: 10,
      })

      issues.value.push(...newIssues)
      if (newIssues.length === 0) {
        issueHasMore.value = false
      } else {
        issuePage.value += 1
      }
    } catch (err) {
      console.error(err)
      showToast(err)
    } finally {
      issueLoading.value = false
    }
  }

  onMounted(() => {
    get()
  })

  return {
    issuePage,
    issueHasMore,
    issueLoading,
    issues,
    fetchIssues: get,
  }
}