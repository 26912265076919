<template>
  <div
    v-if="channel"
    ref="channelVisibleTrackerDom"
    class="container">
    <pr-empty
      v-if="channel.users.length === 0"
      :text="placeholderText"></pr-empty>

    <div v-else>
      <div
        v-if="channel.users && !$route.params.channelId"
        class="users">
        <router-link
          v-for="user in channel.users"
          :key="user._id"
          class="user"
          :to="`/users/${user._id}`">
          <img
            loading="lazy"
            :src="user.avatarURL"
            :alt="user.nickname">
          <h6>{{ user.nickname }}</h6>
        </router-link>
      </div>

      <channel-info
        v-else-if="$route.params.channelId"
        :channel-id="$route.params.channelId"></channel-info>

      <post-list
        :channel-id="channel._id"
        :posts="channel.latestPosts"></post-list>

      <load-more-button
        :busy="initializing || latestLoading"
        :has-more="latestHasmore"
        :trigger="getLatestPosts"></load-more-button>
    </div>
  </div>
</template>

<script>
import PostList from '@/components/PostList.vue'
import ChannelInfo from '@/components/ChannelInfo.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useChannelPresent } from '@/composables/channel_present'
import PrEmpty from '@/components/PREmpty.vue'


export default {
  props: {
    channelId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    return {
      ...useChannelPresent(props, { immediateLoadPosts: false }),
    }
  },
  components: {
    PostList,
    ChannelInfo,
    LoadMoreButton,
    PrEmpty,
  },
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.users {
  overflow-x: scroll;
  white-space: nowrap;
  background: white;
  padding-top: 10px;
  padding-left: 2.5px;
  padding-right: 2.5px;

  .user {
    text-align: center;
    display: inline-block;
    width: 60px;
    margin-left: 2.5px;
    margin-right: 2.5px;

    h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 60px;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border: 1px solid #ff467d;
      border-radius: 25px;
    }
  }
}
</style>
