<template>
  <div class="tag-designer-container">
    <pr-field
      v-model="title"
      type="text"
      label="Tag Content"
      placeholder="Please input tag content"></pr-field>
    
    <div class="preview">
      <hr>
      <span>Member Tag Preview</span>

      <div
        v-if="user"
        class="fake-user">
        <img
          loading="lazy"
          :src="user.avatarURL">
        <div>
          <span class="black">{{ user.nickname }}
            <circle-badge
              v-show="title !== ''"
              :member-tag="{
                style,
                color,
                title,
              }"></circle-badge>
          </span>
          <span>{{ ellipseString(user.motto) }}</span>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="style-picker">
        <span>Pick Style</span>

        <div
          class="block"
          @click="style = 'block'">
          Text Content
          <img
            v-show="style === 'block'"
            src="@/assets/ic-page-switch-selected@2x.png">
        </div>
        <div
          class="outline"
          @click="style = 'outline'">
          Text Content
          <img
            v-show="style === 'outline'"
            src="@/assets/ic-page-switch-selected@2x.png">
        </div>
      </div>

      <div class="color-picker">
        <span>Pick Color</span>

        <div
          v-show="showColorBlocks"
          class="color-blocks">
          <div
            v-for="bgColor in presetColors"
            :key="bgColor"
            :style="{ backgroundColor: bgColor }"
            class="color-block"
            @click="color = bgColor">
            <img
              v-show="color === bgColor"
              src="@/assets/ic-page-switch-selected@2x.png">
          </div>

          <vue-feather
            class="touchable hoverable"
            type="chevron-right"
            @click="showColorBlocks = false"></vue-feather>
        </div>

        <div
          v-show="!showColorBlocks"
          class="color-sliders">
          <vue-feather
            class="touchable hoverable"
            type="chevron-left"
            @click="showColorBlocks = true"></vue-feather>

          <div class="sliders">
            <slider
              v-model="sliderColor"></slider>
          </div>
        </div>
      </div>

      <pr-button
        type="primary"
        @click="set">{{ $t('save') }}</pr-button>
    </div>
  </div>
</template>


<script>
import { setMemberTagForCircle, getOne } from '@/api/user'
import { useFormattedString } from '@/composables/formatted_string'
import { onMounted, ref, computed, watch } from 'vue'
import { officialUserId } from '@/config'
import CircleBadge from '@/components/CircleBadge.vue'
import PrButton from '@/components/PRButton.vue'
import { Slider } from 'vue-color'
import VueFeather from 'vue-feather'
import { useI18n } from 'vue-i18n'
import { useCurrentUser } from '@/composables/user_data'
import { useToast } from '@/composables/toast'
import PrField from '@/components/PrField.vue'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    const user = ref(null)
    const style = ref('block')
    const title = ref('')
    const showColorBlocks = ref(true)
    const presetColors = ['#ff8e55', '#ffda55', '#3cd480', '#55caff', '#4d51fd', '#333333']
    const color = ref(presetColors[0])
    const { t } = useI18n()
    const { authenticated, currentUser } = useCurrentUser()
    const { Toast } = useToast()

    useHead({
      title: 'Design Member Tag | Perohub',
    })

    const sliderColor = computed({
      set (obj) {
        color.value = obj.hex.toLowerCase()
      },
      get () {
        return color.value
      }
    })


    watch(authenticated, () => {
      if (authenticated.value && currentUser.value.memberTag) {
        color.value = currentUser.value.memberTag.color
        style.value = currentUser.value.memberTag.style
        title.value = currentUser.value.memberTag.title
      }
    })


    const set = async function () {
      try {
        await setMemberTagForCircle({
          style: style.value,
          color: color.value,
          title: title.value,
        })
        Toast({ message: t('success') }, 'ok')
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      }
    }

    onMounted(async () => {
      try {
        if (authenticated.value && currentUser.value.memberTag) {
          color.value = currentUser.value.memberTag.color
          style.value = currentUser.value.memberTag.style
          title.value = currentUser.value.memberTag.title
        }
        user.value = await getOne(officialUserId)
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      }
    })

    return {
      user,
      ...useFormattedString(),
      style,
      color,
      title,
      set,
      showColorBlocks,
      presetColors,
      sliderColor,
    }
  },
  components: {
    CircleBadge,
    PrButton,
    VueFeather,
    Slider,
    PrField,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.tag-designer-container {
  width: 100%;
  position: relative;
  height: 100vh;
  height: 100dvh;

  .preview {
    padding: 1em;

    hr {
      border-color: @border;
    }

    span {
      color: @grey;
      font-size: 14px;
    }
  }

  .panel {
    position: absolute;
    bottom: 0;
    height: 60vh;
    left: 0;
    right: 0;

    .color-picker, .style-picker {
      display: flex;
      width: 100%;
      border-top: 1px solid @border;
      justify-content: center;
      position: relative;

      > span {
        position: absolute;
        top: -13px;
        font-weight: bolder;
        font-size: 13px;
        color: @black;
        display: block;
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
      }
    }

    .color-picker {
      height: 10em;

      .vue-feather {
        color: @grey;
        width: 30px;
      }

      .color-sliders {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;

        .sliders {
          width: 78%;

          .vc-slider {
            width: 100%;
          }
        }

        .vue-feather {
          position: absolute;
          left: 10px;
          margin-right: auto;
        }
      }

      .color-blocks {
        width: 100%;
        overflow-x: scroll;
        display: flex;
        flex-wrap: nowrap;
        column-gap: 10px;
        padding: 10px;
        align-items: center;

        .vue-feather {
          margin-left: auto;
        }

        .color-block {
          width: 60px;
          height: 60px;
          border-radius: 6px;
          position: relative;

          &:hover {
            cursor: pointer;
          }

          &:active {
            filter: brightness(0.8);
          }

          img {
            position: absolute;
            bottom: -12px;
            right: -12px;
            height: 24px;
            width: 24px;
          }
        }
      }
    }

    .style-picker {
      height: 8em;
      display: flex;
      column-gap: 12px;
      justify-self: center;

      .block, .outline {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: bolder;
        width: 40%;
        max-width: 200px;
        height: 50px;
        margin-top: 20px;
        border-radius: 4px;
        position: relative;

        &:hover {
          cursor: pointer;
        }

        &:active {
          filter: brightness(0.8);
        }

        img {
          position: absolute;
          bottom: -12px;
          right: -12px;
          height: 24px;
          width: 24px;
        }
      }

      .block {
        background-color: @main;
        color: white;
      }

      .outline {
        border: 2px solid @main;
        box-sizing: border-box;
        color: @main;
      }
    }

    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  }

  .fake-user {
    padding: 8px 10px;
    position: relative;
    border: 1.5px solid @border;

    img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      vertical-align: -1px;
      margin-right: 1em;
    }

    div {
      display: inline-block;

      span {
        display: block;
        font-size: 14px;
        color: @grey;
      }
      .black {
        color: @black;
      }
    }
  }
}
</style>