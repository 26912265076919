<template>
  <div class="container">
    <div
      v-for="(post,index) in collect.collects"
      :key="index"
      class="wrap"
    >
      <div class="author">
        <span
          class="blue"
          @click="$router.push(`/users/${post.post.user._id}`)"
        > @{{ post.post.user.nickname }} </span> {{ $t('wantsToCollaborate') }}
        <span class="time">{{ post.post.time }}</span>
      </div>
      <article-list-combine
        :gold="post.jointUser.sharedRate"
        :post="post.post"
        class="wrap-border"
      />
      <div class="bottom">
        <pr-button
          class="pass"
          @click="jointUpdate(post,'ok',index)"
        >
          <img src="@/assets/Audit_Pass.png"> {{ $t('approve') }}
        </pr-button>
        <pr-button
          class="cancel"
          @click="jointUpdate(post,'rejected',index)"
        >
          <img
            class=""
            src="@/assets/Audit_Refuse.png"
          >{{ $t('reject') }}
        </pr-button>
      </div>
    </div>

    <load-more-button
      :busy="collect.busy"
      :has-more="collect.hasMore"
      :trigger="jointRequestPosts"
    />
  </div>
</template>

<script>
import ArticleListCombine from '@/components/ArticleListCombine.vue'
import PrButton from '@/components/PRButton.vue'
import api from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import Time from '@/utils/time'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  setup () {
    useHead({
      title: 'Collaboration Requests | Perohub',
    })

    return {
      ...useToast(),
    }
  },
  data () {
    return {
      collect: {
        page: 1,
        hasMore: true,
        busy: false,
        collects: [],
      },
    }
  },
  mounted () {
    this.jointRequestPosts()
  },
  methods: {
    jointUpdate (post, flag, num) {
     
      api
        .jointUpdate(post.post._id, flag)
        .then(() => {

          this.collect.collects.splice(num, 1)

          this.showToast({
            message: this.$t('success'),
          }, 'ok')
        })
        .catch(err => {
          this.collect.busy = false

          console.error(err)

          this.showToast({
            message: err.response.data.message ? err.response.data.message : err,
          })
        })
    },
    getTime (time) {
      return Time.timestamptoyear(time)
    },
    jointRequestPosts () {
      this.collect.busy = true

      api
        .jointRequestPosts(10,this.collect.page)
        .then(request => {
          this.collect.busy = false

          if (request.length === 0) {
            this.collect.hasMore = false
          } else {
            this.collect.hasMore = true
            request.forEach(chapter => {
              chapter.post.time = this.getTime(chapter.post.createdAt)
              this.collect.collects.push(chapter)

            })
            this.collect.page += 1
          }

        })
        .catch(err => {
          this.collect.busy = false

          console.error(err)

          this.showToast({
            message: err.response.data.message ? err.response.data.message : err,
          })
        })
    },
  },
  components: {
    ArticleListCombine,
    PrButton,
    LoadMoreButton
  }
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';
.container {
  background-color: #fafafa;
  min-height: 100%;
  padding-top: 10px;
  margin-bottom: 0px !important;
  padding-bottom: 60px;

  .wrap {
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-top: 6px;
    margin-bottom: 10px;

    .author {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: justify;
      color: #9b9b9b;
      position: relative;
      line-height: 2.2;

      .time {
        position: absolute;
        right: 0px;
      }

      .blue {
        color: #55caff;
      }
    }

    .wrap-border {
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
    }

    .propotion {
      font-size: 17px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
      line-height: 2.4;
      border-bottom: 1px solid #f1f1f1;

      span {
        font-weight: 800;
      }
    }

    .bottom {
      margin-top: 10px;
      padding-bottom: 10px;
      text-align: center;

      button {
        border-radius: 4px;
        background-color: #3cd480;
        border: solid 1px #3cd480;
        width: 46%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        outline: none;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        object-fit: cover;
      }
      .pass {
        margin-right: 4%;
      }
      .cancel {
        background-color: #f1f1f1;
        color: #9b9b9b;
        border: solid 1px #f1f1f1;

        img {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}


</style>
