<template>
  <div class="issue-create-container">
    <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 2rem;">
      <user-avatar
        :user="user"
        style="width: 4rem; height: 4rem;">
      </user-avatar>
    </div>

    <pr-cell
      :label="$t('assignmentPublic').toString()">
      <toggle-switch v-model="publicIssue"></toggle-switch>
    </pr-cell>

    <p v-show="publicIssue">{{ $t('assignmentPublicDesc') }}</p>
    <p v-show="!publicIssue">{{ $t('assignmentNotPublicDesc') }}</p>

    <pr-cell
      :label="$t('currency').toString()">
      <select v-model="currency">
        <option
          v-for="currency in currencies"
          :key="currency.value"
          :value="currency.value">
          {{ currency.label }}
        </option>
      </select>
    </pr-cell>

    <pr-cell
      :label="$t('assignmentPrice').toString()">
      <input-number
        v-model="price"
        mode="currency"
        :currency="currency"
        :min="minPrice">
      </input-number>
    </pr-cell>


    <Textarea
      v-model="content"
      :placeholder="$t('issueCommentPlaceholder', { nickname: user.nickname })"
      style="width: 100%; margin-top: 2rem;">
    </Textarea>

    <p>You need to pay {{ bargainPriceText }} as bargain fee for creating this commission.</p>
    <p>During the commision you can reject at most {{ maxRejectionTimes }} times.</p>

    <div
      v-if="user.issue"
      v-show="price > user.issue.minPrices[currency]">
      <p>If you are satisfied with the result, you need to pay more {{ resolvePriceText }}.</p>
      <p>
        If you are not satisfied with the result
      </p>
      <ul>
        <li>- Bargain fee is not refundable</li>
        <li>- You don't need to pay the rest of the price</li>
        <li>- Artist can make the artwork public (can be purchased by anyone) even if it is set to be private</li>
      </ul>
    </div>

    <div style="margin-top: 2rem;">
      <pr-button
        v-if="currentUserId !== user._id"
        type="primary"
        style="width: 100%;"
        @click="assign">
        {{ $t('sendAssignment') }} {{ bargainPriceText }}
      </pr-button>
      <pr-button
        v-else
        type="primary"
        style="width: 100%;"
        disabled>
        You cannot send commission to yourself
      </pr-button>
    </div>
  </div>
</template>


<script>
//@ts-check
import { useIssueCreate } from '@/composables/issue_create'
import { useUserPresent } from '@/composables/user_present'
import ToggleSwitch from 'primevue/toggleswitch'
import InputNumber from 'primevue/inputnumber'
import PrButton from '@/components/PRButton.vue'
import Textarea from 'primevue/textarea'
import UserAvatar from '@/components/UserAvatar.vue'
import { maxRejectionTimes } from '@/config'
import { useCurrentUser } from '@/composables/user_data'
import PrCell from '@/components/PRCell.vue'

export default {
  setup () {
    const userPresentComposition = useUserPresent()
    return {
      ...useCurrentUser(),
      ...userPresentComposition,
      ...useIssueCreate(userPresentComposition.user),
      maxRejectionTimes,
    }
  },
  components: {
    ToggleSwitch,
    InputNumber,
    PrButton,
    Textarea,
    UserAvatar,
    PrCell,
  }
}
</script>

<style scoped lang="less">
@import "@/assets/base.less";

.issue-create-container {
  padding-top: 2rem;
  width: 30rem;
  margin-left: auto;
  margin-right: auto;

  @media @phone {
    padding: 1rem;
    width: 100%;
  }

  p {
    font-size: 0.9rem;
    color: @minor;
  }
}
</style>