<template>
  <div>
    <div v-if="ok">
      <p>Verification Completed</p>
      <pr-button
        type="primary"
        size="small"
        @click="$router.push('/i/publisher/publish')">Publish Artwork</pr-button>
    </div>
    <div v-else-if="failed">
      <p>Sorry, we cannot verify your identity</p>
      <p>{{ reason }}</p>
      <!-- <p>You can retry at most 3 times</p> -->
      <pr-button
        type="primary"
        size="small"
        @click="retry">Retry</pr-button>
    </div>
    <div v-else-if="currentUser.external.stripeVerificationId">
      <p>We're processing your verification</p>
      <pr-button
        type="primary"
        suze="small"
        @click="check">Check Again</pr-button>
    </div>
    <div v-else>
      <p>Please verify your identity</p>
      <pr-button
        type="primary"
        size="small"
        @click="$router.push('/i/publisher/verify')">Go to Verify</pr-button>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue'
import PrButton from '@/components/PRButton.vue'

import { checkStripeVerification } from '@/api/user'
import { useCurrentUser } from '@/composables/user_data'
import { getStripe } from '@/libs/stripe'
import { useAuthenticator } from '@/pinia/authenticator'
import { useToast } from '@/composables/toast'


export default {
  setup () {
    const ok = ref(false)
    const reason = ref('')
    const { currentUser } = useCurrentUser()
    const clientSecret = ref('')
    const authenticator = useAuthenticator()
    const { Toast } = useToast()


    const failed = computed(() => {
      if (currentUser.value.external.stripeVerificationId && !ok.value && clientSecret.value) {
        return true
      }

      return false
    })


    watch(ok, () => {
      if (ok.value) {
        authenticator.refreshUser()
      }
    })


    const check = async function () {
      try {
        
        const result = await checkStripeVerification(currentUser.value._id)
        ok.value = result.verified
        reason.value = result.reason
        clientSecret.value = result.tmpSecret
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        
      }
    }


    const retry = async function () {
      try {
        
        const stripe = await getStripe()
        const result = await stripe.verifyIdentity(clientSecret.value)
        
        if (result.error) {
          throw new Error(result.error.message)
        }
        await check()
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        
      }
    }


    onMounted(() => {
      authenticator.refreshUser()
      check()
    })


    return {
      ok,
      check,
      reason,
      failed,
      retry,
      currentUser,
    }
  },
  components: {
    PrButton,
  }
}
</script>


<style>

</style>
