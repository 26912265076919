<template>
  <div class="layout-box">
    <div
      v-if="titleUrl"
      class="mod-media">
      <div class="media-main">
        <img
          loading="lazy"
          :src="titleUrl">
      </div>
    </div>
    <div class="box-col">
      <div class="txt-cut title">
        {{ localeTitle }}
      </div>
      <div class="txt-cut content">
        {{ post.content }}
      </div>
      <div class="picture">
        <img src="@/assets/icon_web_h5_ImageNumber_grey.png">
        <span>{{ post.pictureCount }}</span>
        <img
          class="imgleft"
          src="@/assets/icon_web_h5_VideoNumber_grey.png">
        <span>{{ post.videoCount }}</span>

        <span class="yellow">{{ priceText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
//@ts-check
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'
import { ACCESSTOKEN_KEY } from '@/pinia/authenticator'
import { MediaType } from '@/utils/defines'
import { until } from '@vueuse/core'
import localforage from 'localforage'
import { onMounted, ref } from 'vue'

export default {
  props: {
    post: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  setup (props) {
    const { currency } = useCurrentCurrency()
    const { priceText, localeTitle } = useArtworkPresent(props.post._id, currency, props.post, false)
    const { showToast } = useToast()
    const { authenticated } = useCurrentUser()
    const titleUrl = ref(null)
  
    const assembleUrl = async function () {
      try {
        await until(authenticated).toBeTruthy()
        const images = props.post.pictures.filter(media => media.type === MediaType.IMAGE)
        const token = await localforage.getItem(ACCESSTOKEN_KEY)
        const image = images.find(media => media.canBrowse)
        if (image) {
          const urlRaw = image.canBrowse ? image.url : image.thum
          const url = new URL(urlRaw)
          url.searchParams.append('token', token)
          titleUrl.value = url.toString()
          console.log(titleUrl.value)
        }
      } catch (err) {
        showToast(err.message)
      }
    }

    onMounted(() => {
      assembleUrl()
    })

    return {
      titleUrl,
      priceText,
      localeTitle
    }
  },
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.layout-box {
  display: flex;
  vertical-align: baseline;

  .mod-media {
    position: relative;
    display: block;
    text-align: center;
    margin: .5rem 0 .5rem 0rem;

    .media-main {
      position: relative;

      img {
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: top;
        object-fit: cover;
      }
    }
  }
  .box-col {
    padding: .5rem 0rem .5em .6em;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex: 1;
    width: 100%;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;

    .title {
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
    }
    .content {
      line-height: 1.7;
    }
    .picture {
      position: relative;
      line-height: 1em;
      margin-top: 1em;

      img {
        width: 13px;
        height: 13px;
        object-fit: cover;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.93;
        letter-spacing: normal;
        text-align: left;
        color: #cfcfcf;
        margin-left: 3px;
        position: relative;
        top: -1px;
      }
      .imgleft {
        margin-left: 12px;
      }

      .yellow {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #ffda55;
        position: absolute;
        right: 0px;
        top: 1px;
      }
    }
  }
} 

.txt-cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
}
</style>
