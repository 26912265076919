<template>
  <div class="container">
    <div class="group">
      <pr-cell
        v-if="!currentUser.authed"
        :title="$t('applyVerify').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/verify')"></pr-cell>
      <pr-cell
        v-if="currentUser.authed"
        :title="$t('publish').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/publish')"></pr-cell>
      <pr-cell
        v-if="currentUser.authed"
        :title="$t('contactBuyers').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/requesters')"></pr-cell>
      <pr-cell
        v-if="currentUser.authed"
        :title="$t('artworkCategories').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/tags')"></pr-cell>
    </div>
    <div class="group">
      <pr-cell
        v-if="currentUser.authed"
        :title="$t('openCircle').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/circles/apply')"></pr-cell>
      <pr-cell
        :title="$t('discountCodes').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/discountCodes')"></pr-cell>
      <pr-cell
        :title="$t('promote').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/promote')"></pr-cell>
      <pr-cell
        :title="$t('rules').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/rules')"></pr-cell>
      <pr-cell
        :title="$t('reportCenter').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/report-center')">
      </pr-cell>
    </div>

    <div class="group">
      <pr-cell
        :title="$t('channelManagement').toString()"
        is-link
        class="hoverable"
        @click="$router.push('/i/publisher/channels')"></pr-cell>
    </div>
  </div>
</template>

<script>
//@ts-check
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import PrCell from '@/components/PRCell.vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

export default {
  setup () {
    const { t } = useI18n()

    useHead({
      title: t('creator')
    })

    return {
      ...useResponsive(),
      ...useCurrentUser(),
    }
  },
  components: {
    PrCell,
  },
}
</script>

<style scoped lang="less">
.container {
  padding-top: 20px;

  .group {
    margin-bottom: 20px;
  }
}

.icon {
  margin-top: 12px;
  fill: #fff;
}
</style>
