<template>
  <div
    v-if="type === 'text'"
    class="pr-field">
    <div
      v-if="label"
      class="pr-field-label">
      {{ label }} {{ required ? '*' : '' }}
    </div>
    <div class="pr-field-input">
      <input-text
        v-if="type == 'text'"
        :id="label"
        v-model="fallthroughValue"
        :placeholder="placeholder"
        style="width: 100%;"
        :class="{
          borderless
        }">
      </input-text>
    </div>
  </div>
  <div
    v-else-if="type === 'textarea'"
    class="pr-field"
    style="flex-direction: column; gap: 0.5rem;">
    <div class="pr-field-label">
      {{ label }} {{ required ? '*' : '' }}
    </div>
    <div class="pr-field-input">
      <Textarea
        :id="label"
        v-model="fallthroughValue"
        :placeholder="placeholder"
        :rows="rows"
        style="width: 100%;">
      </Textarea>
    </div>
  </div>
</template>

<script>
//@ts-check
import { computed } from 'vue'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 4
    },
    borderless: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const fallthroughValue = computed({
      get () {
        return props.modelValue
      },
      set (val) {
        emit('update:modelValue', val)
      }
    })

    return {
      fallthroughValue
    }
  },
  components: {
    InputText,
    Textarea,
  }
}
</script>


<style lang="less" scoped>
@import "@/assets/base.less";

.pr-field {
  display: flex;
  padding: 1rem 0;
  margin-left: 1rem;
  margin-right: 1rem;
  border-top: 1px solid @border;
}

.pr-field-label {
  width: 10rem;
}

.pr-field-input {
  flex: 1;
}

.borderless {
  border: none;

  &:active {
    border: none;
  }

  &:focus {
    border: none;
  }
}
</style>
