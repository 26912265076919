<template>
  <article
    class="article"
    v-html="content"></article>
</template>


<script>
import { sanitize } from 'dompurify'
import '@/assets/doc.css'
import html from '@/docs/privacy.md'
import { useHead } from '@unhead/vue';


export default {
  setup () {
    const content = sanitize(html)

    useHead({
      title: 'Privacy Policy | Perohub',
    })

    return {
      content,
    }
  }
}
</script>

<style scoped>
.article {
  max-width: 40rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>