export const events = {
  ViewerEntered: 'viewer-entered',
  ViewerQuitOnIndex: 'viewer-quit-on-index',
  ArtworkRemovedFromChannel: 'artwork-removed-from-channel',
  ArtworkDeleted: 'artwork-deleted',
  LoginCompleted: 'login-completed',
  LogoutCompleted: 'logout-completed',
  CommentDeleted: 'comment-deleted',
  ChannelLatestVisible: 'channel-latest-visible',
  ChannelHotVisible: 'channel-hot-visible',
  ChannelBestVisible: 'channel-best-visible',
  ChannelAlgoVisible: 'channel-algo-visible',
}
