<template>
  <div class="container">
    <pr-field
      v-model="title"
      :label="$t('channelTitle').toString()"
      :placeholder="$t('inputChannelTitle').toString()">
    </pr-field>
    <pr-cell
      :title="$t('needVerify').toString()">
      <toggle-switch v-model="needVerify"></toggle-switch>
    </pr-cell>
    <pr-cell
      :title="$t('setRoyaltyRate').toString()">
    </pr-cell>
    <pr-cell
      :title="`${$t('revenueShares').toString()} (${royaltyRate}%)`">
      <slider
        v-model="royaltyRate"
        :min="0"
        :max="100"
        :step="1"
        style="width: 14rem; margin-left: 1rem;">
      </slider>
    </pr-cell>
    <ul
      style="user-select: none;">
      <li>Only applied when artist submits to your channel</li>
      <li>Perohub takes 0% of your earn</li>
    </ul>
    <pr-cell
      :title="$t('limitArtistDisplay').toString()">
      <toggle-switch
        v-model="limitArtistDisplay"></toggle-switch>
    </pr-cell>
    <pr-cell
      :title="$t('useAlgoTimeline').toString()">
      <toggle-switch v-model="useAlgoTimeline"></toggle-switch>
    </pr-cell>
    <pr-cell
      v-show="limitArtistDisplay"
      :title="$t('oneArtistMaxPresentsInLatest').toString()">
      <input-number v-model="oneArtistMaxPresentsInLatest"></input-number>
    </pr-cell>
    <pr-field
      v-model="description"
      :label="$t('channelDescription').toString()"
      required
      type="textarea"
      :rows="4"
      :placeholder="$t('pleaseInputChannelDescription').toString()">
    </pr-field>
  
    <pr-button
      type="primary"
      :loading="loading"
      @click="submit">
      {{ shouldCreate ? $t('submit') : $t('edit') }}
    </pr-button>
  </div>
</template>

<script>
//@ts-check
import { onMounted, ref, watch } from 'vue'
import { createChannel, updateChannel } from '@/api/channel'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import PrButton from '@/components/PRButton.vue'
import { useChannelStore } from '@/pinia/channel'
import { useAuthenticator } from '@/pinia/authenticator'
import { useToast } from '@/composables/toast'
import ToggleSwitch from 'primevue/toggleswitch'
import InputNumber from 'primevue/inputnumber'
import PrCell from '@/components/PRCell.vue'
import PrField from '@/components/PrField.vue'
import Slider from 'primevue/slider'

export default {
  setup () {
    const title = ref('')
    const description = ref('')
    const needVerify = ref(false)
    const royaltyRate = ref(0.0)
    const titleLocales = ref({})
    const descriptionLocales = ref({})
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const shouldCreate = ref(true)
    const channeStore = useChannelStore()
    const authenticator = useAuthenticator()
    const { Toast } = useToast()
    const limitArtistDisplay = ref(false)
    const oneArtistMaxPresentsInLatest = ref(0)
    const useAlgoTimeline = ref(false)
    const loading = ref(false)


    watch(limitArtistDisplay, () => {
      if (!limitArtistDisplay.value) {
        oneArtistMaxPresentsInLatest.value = 0
      }
    })


    watch(oneArtistMaxPresentsInLatest, () => {
      oneArtistMaxPresentsInLatest.value = Math.round(oneArtistMaxPresentsInLatest.value)
    })


    const submit = async function () {
      try {
        if (!title.value) {
          throw new Error('please enter title')
        }

        if (!description.value) {
          throw new Error('please enter channel description')
        }

        const postData = {
          title: title.value,
          description: description.value,
          needVerify: needVerify.value,
          royaltyRate: Number((royaltyRate.value / 100).toFixed(2)),
          titleLocales: titleLocales.value,
          descriptionLocales: descriptionLocales.value,
          oneArtistMaxPresentsInLatest: oneArtistMaxPresentsInLatest.value, 
          useAlgoTimeline: useAlgoTimeline.value,
        }

        loading.value = true

        if (shouldCreate.value) {
          await createChannel({
            ...postData
          })
        } else {
          await updateChannel(
            route.params.channelId,
            {
              ...postData
            }
          )
        }

        await channeStore.fetchManageableChannels()

        if (shouldCreate.value) {
          router.push({
            path: '/i/publisher/channels',
            replace: true,
          }) 
        } else {
          router.push({
            path: `/channels/${route.params.channelId}`,
            replace: true,
          })
        }
        Toast({ message: t('success') }, 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }


    onMounted(async () => {
      const channelId = route.params.channelId
      if (!channelId) {
        return
      }

      shouldCreate.value = false
      if (channeStore.manageableChannels.length === 0) {
        await authenticator.checkAuth()
        await channeStore.fetchManageableChannels()
      }
      const channel = channeStore.manageableChannels.filter(channel => channel._id === channelId)[0]

      if (channel) {
        title.value = channel.title
        description.value = channel.description
        needVerify.value = channel.needVerify
        royaltyRate.value = Math.round(channel.royaltyRate * 100)
        titleLocales.value = channel.titleLocales
        descriptionLocales.value = channel.descriptionLocales
        oneArtistMaxPresentsInLatest.value = channel.oneArtistMaxPresentsInLatest
        limitArtistDisplay.value = !!oneArtistMaxPresentsInLatest.value
        useAlgoTimeline.value = channel.useAlgoTimeline
      }
    })


    return {
      title,
      needVerify,
      description,
      royaltyRate,
      titleLocales,
      descriptionLocales,
      submit,
      shouldCreate,
      oneArtistMaxPresentsInLatest,
      limitArtistDisplay,
      useAlgoTimeline,
      loading,
    }
  },
  components: {
    PrButton,
    ToggleSwitch,
    InputNumber,
    PrCell,
    PrField,
    Slider,
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @media @desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }

  ul {
    font-size: 15px;
    color: @grey;
    font-weight: bolder;
    margin-bottom: 2em;
  }

  button {
    width: 100%;
    margin-top: 1em;
  }
}
</style>